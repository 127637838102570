import React, { useState, useEffect } from 'react';
import "../Compare.css";
import { useNavigate } from 'react-router-dom';
import RemovePopup from './RemovePopup';
import secureLocalStorage from "react-secure-storage";
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { checkFeaturePermissions } from '../../../utils/common';


const BoxView = (props) => {
  const [items, setItems] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [fundRemovedID, setFundRemovedID] = useState(0);
  const navigate = useNavigate();
  const [Funddetails, setFunddetails] = useState();
  const [removeItemId, setRemoveItemId] = useState(null);
  useEffect(() => {
    setFunddetails(props?.FundsDetails);
  }, []);

  useEffect(() => {
    if (fundRemovedID !== 0) {
      setFunddetails((prevItems) => {
        
        return Funddetails?.filter(item => item.fundID !== fundRemovedID);
      });
    }
  }, [fundRemovedID]);

  const [AllInvestors, setAllInvestors] = useState([]);
  const fetchDataforAllFunds = async () => {
   
  };

  fetchDataforAllFunds();

  const handleViewDetail = (item,itemIdObj, fundname,fundStatusName, disableSubscriptionButton,event ) => {
    if (event) {
      event.stopPropagation();
    }

   

    let parsedFundId;
    try {
      parsedFundId = JSON.parse(itemIdObj);
    } catch (error) {
      console.error("Error parsing fundDetail:", error);
    }
    console.clear()

    console.log(parsedFundId)

    secureLocalStorage.setItem("FundId", parsedFundId);

    secureLocalStorage.setItem("FundName", fundname);

    const havepermissiontoread =  checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);

    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: parsedFundId,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton:disableSubscriptionButton
        }
      });
      
    } else if (havepermissiontoread) {
      console.log(item)
       
      
      const SelectedFund = {
        "FundMaturity": item.isFundMatured,
        "FundTermination": item.fundTerminated,
        BGImage: item.bgImage,
        IconImage: item.iconImage
      }
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', { parsedFundId });
    }
    
    secureLocalStorage.setItem("FundId", JSON.stringify(parsedFundId));
    secureLocalStorage.setItem('fundStatusName',fundStatusName);
    window.scrollTo(0, 0);
  };
  
  const handlesubscribe = (item,itemId, fundname, event) => {
    if (event) {
      event.stopPropagation();
    }
    secureLocalStorage.setItem("FundName", fundname);
    secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem("FundSPIcon", item.iconImage);
    secureLocalStorage.setItem("fundDescription", item.fundDescription);

   /*  console.log(item); */
    /*   */
    navigate('/investmentProfile', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  const handleRemoveitems = (itemId) => {
        setShowPopup(true);
    setRemoveItemId(itemId);
  };

  const favApi = async (fundID, favorite) => {
    
  };

  const handleConfirmRemove = () => {
    
    setShowPopup(false);
    favApi(removeItemId, false); 
    setFundRemovedID(removeItemId);
  
  };

  const handleCancelRemove = () => {
    setShowPopup(false);
  };

  return (
    <>
      {
        Funddetails?.map((item, index) => (
          item.fundTerminated ?
              null
             : 
          <div className="cardcontainer" key={index}>
            <div className="mediumCardBgParent1">
              <div className="mediumCardBg">
                <div className="bg" />
                <div className="component21294">
                  <div className="mediumCardBg">
                    <div className="buttonNormalItem" />
                    {
                        checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION,FeatureAccess.READ  ) 
                        && 
                    <button className="subscribe4"
                    onClick={() => {handlesubscribe(item,item.fundID, item.fundName)}}

                    disabled={
                      secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
                       (item?.isFundMatured  
                        || secureLocalStorage.getItem("InvestorFundIDs")?.includes(item?.fundID)
                        || item.fundStatusName === 'Closed to New Investors'||item.fundStatusName === 'Launching Soon' || item.fundStatusName === 'Closed' || item.fundStatusName === 'Terminated'
                        || AllInvestors.some(u=>u.fundID === item?.fundID)
                        ) :
                       true
                      //  (item?.isFundMatured )
                   } 
                 
                  >
                        {AllInvestors.some(u=>u.fundID === item?.fundID) ? "Subscribed" : "Subscribe"}
                  </button>
}
                  </div>
                </div>
                <div className="privateEquityParent2">
                  <div className="privateEquity4">Private Equity</div>
                  <b className="consumerGoodsRetailEnhancem4">
                    {item.fundName}
                  </b>
                  <div className="loremIpsumDolor4">
                    {item.fundDescription}
                  </div>
                  <div className="targetReturn-compare">TARGET RETURN</div>
                  <b className="target-returndata">{item.targettedIRR}%</b>
                  <div className="minimum4">MINIMUM</div>
                  <b className="b9">${item.minimumInvestmentAmount?.toLocaleString()}</b>
                  <div className="liquidity4">LIQUIDITY</div>
                  <b className="quarterly4">{item.liquidityProvision}</b>
                  <img
                    className="favoriteButtonIcon-compare"
                    alt=""
                    src={item.favourite ? '/MarketPlaceImages/favouriteicon1.svg' : '/favorite-button.svg'}
                    onClick={() => handleRemoveitems(item.fundID)}
                  />
                  <img
                    className="addToCompare-compare"
                    alt=""
                    src="/add-to-compare.svg"

                  />
                </div>

                <div className="component212131">
                  <div className="mediumCardBg">
                    <div className="buttonNormalItem" />
                    <button
                      className="viewDetail"
                      onClick={() => handleViewDetail(item,item.fundID, item.fundName , item.fundStatusName, AllInvestors.some(u=>u.fundID === item?.fundID))}>
                      
                      View Detail
                    </button>
                  </div>
                </div>

              </div>
              <img
                className="maskGroup36"
                alt=""
                src={item?.bgImage === null || !item?.bgImage.includes('https') ? '/DefaultBackgroundImage.png' : item?.bgImage}
              />
              <div className="image4" />
              <div className="label4">
                <b className="labelcontent4">{item.fundStatusName}</b>
              </div>
              <img
                className="maskGroup38"
                alt=""
                
                src={item?.iconImage === null || !item?.iconImage.includes('https') ? "/DefaultLogo.png": item?.iconImage}
              />
              <div className='funddetails-compare'>
                <div className='protfoliobyasset-cp'>Portfolio By Asset Type</div>
             
                <div className='Equity-cp'>
                  <span className='fund-cp'>Equity</span>
                  <span className='content-cp'>{item?.equityInvestment}%</span>
                </div>
                <div className="line-cp"></div>
                <div className='Credit-cp'>
                  <span className='fund-cp'>Credit</span>
                  <span className='content-cp'>{item?.creditInvestment}%</span>
                </div>
                <div className="line-cp"></div>
                <div className='fundmetrics-cp'>
                  <span className='fund-cp'>Fund Metrics</span>
                </div>
                <br />
                <div className='vintage-cp'>
                  <span className='fund-cp'>Vintage Year</span>
                  <span className='content-cp'>{item?.vintage}</span>
                </div>
                <div className="line-cp"></div>
                <div className='fundsize-cp'>
                  <span className='fund-cp'>Target Raise</span>
                  <span className='content-cp'>${item?.fundSize / 1000000}M</span>
                </div>
                <div className="line-cp"></div>
                <div className='capitalcalled-cp'>
                  <span className='fund-cp'>Capital Called</span>
                  <span className='content-cp'>{item?.capitalCalled}</span>
                </div>
                <div className="line-cp"></div>
                <div className='noofinvestments-cp'>
                  <span className='fund-cp'>Number of Investments</span>
                  <span className='content-cp'>{item.numberofPortfolioCompanies}</span>
                </div>
                <div className="line-cp"></div>
              </div>

            </div>
          </div>
            ))}
              {showPopup && (
                <RemovePopup
                  onConfirm={handleConfirmRemove}
                  onCancel={handleCancelRemove}
                  message={"Are you sure you would like to remove this fund from your Favorite Funds list?"}
                  Buttonmsg={"Remove"}
                  leftValue={"84px"}
                />
              )}
            </>
          )
        }

export default BoxView;
