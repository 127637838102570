import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, Input, TextField, Divider, Tooltip, Hidden } from '@mui/material';
import ProgressBar from '../../../common/ProgressBar/ProgressBar';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fundTypesEnum } from '../../../marketplace-dashboard/jsonData';
import FundType from './FundType';
import ManagementTeam from './ManagementTeam';
import InvestmentStrategy from './InvestmentStrategy';
import FeesandExpenses from './FeesandExpenses';
import { initialFundDataTemplate } from '../../services/apiDataDemplate';
import PrimaryDetails from './FundPrimaryDetails';
import { ToastContainer, toast } from 'react-toastify';
import { createFund, getFundFilterData } from '../../../marketplace/services/MarketplaceService';
import ActionButton from '../../../common/ActionButton/ActionButton';
import secureLocalStorage from 'react-secure-storage';
import SaveIcon from '@mui/icons-material/Save';
import { AppDashboards } from '../../../../utils/enum';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { convertArrayToString } from '../../../common/Functions/ConvertStringtoArray';
import { getFolders } from '../../../marketplace/components/CreateFundModal/Services/DropDownData';
import FundPrimaryDetails from './FundPrimaryDetails';
import { getFirmDetails } from '../../../user-profile/FirmUserProfile/services/firmdetaailsservices';

const CreateFundModel = (props) => {

  //#region props
  const { open, onClose, fundTypes, fetchFundDetails } = props;

  //#region variables
  const [fundDetails, setFundDetails] = useState(initialFundDataTemplate);
  const [currStep, setCurrStep] = useState(1);
  const [currStepDetails, setCurrStepDetails] = useState(initialFundDataTemplate);
  const [fundFilterData, setFundFilterData] = useState();
  const [loading, setLoading] = useState(false);
  const [folderNames, setFolderNames] = useState(null);
  const [managementStaffDetails, setManagementStaffDetails] = useState(null);
  const [firmDetails, setFirmDetails] = useState(null);

  //#region ref for validations
  const fundTypeRef = useRef(null);
  const primaryDetailsRef = useRef(null);
  const managementTeamRef = useRef(null);
  const investmentStrategyRef = useRef(null);
  const feesandExpensesRef = useRef(null);

  //#region click events
  const onNextClick = async () => {
    const isValid = await validateFundTypeFields();
    if (isValid) {
      if (currStep === steps?.length) {
        createNewFund();
      }
      else {
        setCurrStep(currStep + 1);
      }
    } else {
      toast.warning("Please fill in mandatory fields",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  };

  const onPrevClick = () => {
    updateFields();
    setCurrStep(currStep - 1);
  }

  //#region api get calls
  const getFundFilters = async () => {
    const data = await getFundFilterData();
    if (data.responseCode === 200) {
      setFundFilterData(data.responseData)
    }
    else {
    }
  }

  const getManagementTeam = async () => {
    const data = await getRoles("");
    if (data?.responseCode === 200) {
      const fundStaffOptions = data?.responseData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)
        .map(user => ({
          label: user.userFullName,
          value: user.userId,
          disabled: false,
          title: user.userRoleName,
          briefDescription: user.briefDescription,
          roleDashboardName: user.roleDashboardName
        }));
      setManagementStaffDetails(fundStaffOptions);
    } else {
      console.log("Error fetching user details");
    }
  }

  const getAllFolderNames = async () => {
    const data = await getFolders();
    if (data.responseCode === 200) {
      console.log(data.responseData);
      const NamesAndIds = data?.responseData?.filter(d => d.parentType != "Investor Reporting" && d.title != "Investor Reporting")?.map(item => ({
        label: item.title,
        value: `${item.defaultFolderID}_${item.documentTypeID}`
      }));
      setFolderNames(NamesAndIds);
    } else {

    }
  }

  const getTenantDetails = async () => {
    const data = await getFirmDetails();
    if (data?.responseCode == 200) {
        setFirmDetails(data?.responseData);
    } else {
        setFirmDetails({});
    }
}

  //#region api post calls
  const createNewFund = async () => {
    setLoading(true);
    const requestedData = fundDetails;
    requestedData.userID = requestedData.userID ?? secureLocalStorage.getItem("userId");
    requestedData.fundManager = secureLocalStorage.getItem("fullName");
    requestedData.fundStaff = convertArrayToString(requestedData.fundStaff?.filter((item) => item !== -1)) || "";
    requestedData.fundManagementTeams = requestedData.managementDetails?.map((memberId, index) => {
      return {
        id: 0,
        managementTeamStaff: memberId,
        description: managementStaffDetails?.find((item) => item?.value === memberId)?.briefDescription,
        title: managementStaffDetails?.find((item) => item?.value === memberId)?.title,
        authorizedSignatory: requestedData.isFirmAsGP
          ? requestedData.authorizedSignatory === memberId
          : false
      }
    }) || [];

    const requestedBody = Object.fromEntries(
      Object.entries(requestedData).filter(([key, value]) => key !== "managementDetails")
    );

    const formDataBody = new FormData();
    formDataBody?.append("fundDetails", JSON.stringify(requestedBody));

    if (requestedData?.iconImage) {
      formDataBody.append('iconImage', requestedData?.iconImage, requestedData.iconImage?.name);
    }
    if (requestedData?.bgImage) {
        formDataBody.append('bgImage', requestedData.bgImage, requestedData.bgImage?.name);
    }
    if (requestedData?.strategyDocument) {
      formDataBody.append('strategyDocument', requestedData?.strategyDocument, requestedData?.startegyfilepath + "/" + requestedData?.strategyDocument?.name);
  }

    const response = await createFund(formDataBody);
    if (response?.responseCode === 200) {
      setLoading(false);
      fetchFundDetails();
      onClose();
      toast.success("Fund Created Successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else {
      setLoading(false);
      toast.error("Unable to create the fund,please try again after some time", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
  }

  //#region step validation checks
  const updateFields = () => {
    return currStepDetails?.componentRef.current?.updateFields();
  }
  const validateFundTypeFields = () => {
    return currStepDetails?.componentRef.current?.validateFields();
  };

  //#region steps 
  const steps = [
    {
      stepNo: 1,
      stepName: 'Fund Type',
      component: <FundType
        ref={fundTypeRef}
        fundDetails={fundDetails}
        setFundDetails={setFundDetails}
        fundTypes={fundTypes} />,
        componentRef: fundTypeRef,
        fundTypes: fundTypesEnum.ALL /* use this in case need to show step based on fundtypes in future */
    },
    {
      stepNo: 2,
      stepName: 'Fund Details',
      component: <FundPrimaryDetails
        ref={primaryDetailsRef}
        fundDetails={fundDetails}
        setFundDetails={setFundDetails}
        fundFilterData={fundFilterData}
        managementStaffDetails={managementStaffDetails} />,
      componentRef: primaryDetailsRef,
      fundTypes: fundTypesEnum.ALL
    },
    {
      stepNo: 3,
      stepName: 'Management Teams',
      component: <ManagementTeam 
                       ref={managementTeamRef} 
                       managementStaffDetails={managementStaffDetails} 
                       fundDetails={fundDetails} 
                       setFundDetails={setFundDetails}
                       firmDetails={firmDetails} />,
      componentRef: managementTeamRef,
      fundTypes: fundTypesEnum.ALL
    },
    {
      stepNo: 4,
      stepName: 'Investment Strategy',
      component: <InvestmentStrategy ref={investmentStrategyRef} folderNames={folderNames} fundTypes={fundTypes} fundDetails={fundDetails} setFundDetails={setFundDetails} />,
      componentRef: investmentStrategyRef,
      fundTypes: fundTypesEnum.ALL
    },
    {
      stepNo: 5,
      stepName: 'Fees and Expenses',
      component: <FeesandExpenses ref={feesandExpensesRef}
        fundDetails={fundDetails}
        setFundDetails={setFundDetails}
        fundTypes={fundTypes} />,
      componentRef: feesandExpensesRef,
      fundTypes: fundTypesEnum.ALL
    },
  ];

  //#region useeffect
  useEffect(() => {
    getFundFilters();
    getManagementTeam();
    getAllFolderNames();
    getTenantDetails();
  }, [])

  useEffect(() => {
    const selectedStep = steps.find(step => step.stepNo === currStep);
    setCurrStepDetails(selectedStep);
  }, [currStep])

  //#region return
  return (
    <Dialog open={open} maxWidth={'md'} fullWidth scroll={'paper'}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }
      }}>
      <div className='mui-step-popup'>
        <div className=''>
          <div className='subscription-step-btn float-left' onClick={onPrevClick}>
            {
              currStep > 1 &&
              <ArrowBackIcon sx={{ width: 32, height: 32, color: 'gray' }} />
            }
          </div>
        </div>
        <div className='create-fund-white-card'>
          <DialogTitle className='child-margin-5'>
            <div className='steps-popclose'>
              <Tooltip title='Close'>
                <Button onClick={onClose} className='steps-close-icon' >
                  <CloseIcon onClick={onClose} />
                </Button>
              </Tooltip>
            </div>
            <div className='fundHeaderWrapper'>
              <div className="fundHeader">Create a Fund</div>
              <div className="fundProgress"><ProgressBar progressValue={(currStep / steps?.length) * 100} /></div>
              <div className="fundStep">{`${currStep} of 5`}</div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ overflowY: 'auto',maxHeight: '600px',overflowX: 'hidden' }}>
              {currStepDetails?.component}
            </div>
            {
              currStep === steps?.length &&
              <div className='align-center'>
                <ActionButton
                  label="SAVE"
                  startIconName="SaveOutlined"
                  loading={loading}
                  icon={<SaveIcon />}
                  onClick={onNextClick} />
              </div>
            }
          </DialogContent>
        </div>
        <div className=''>
          <div className='subscription-step-btn float-right'>
            {
              currStep < steps.length &&
              <ArrowForwardIcon sx={{ width: 32, height: 32, color: 'gray' }} onClick={onNextClick} />
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default CreateFundModel