import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MarkAsRead, getNotificationsbyUserID } from '../../Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { Grid, LinearProgress } from '@mui/material';
import ActionButton from '../../../common/ActionButton/ActionButton';
import { ConverttoIST } from '../../../common/Functions/TimeConvestion';
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import EastIcon from '@mui/icons-material/East';
import NotificationDetailPopup from './NotificationDetailPopup';
import { AppDashboards } from '../../../../utils/enum';

const NotificationsInbox = () => {
  const [notifications, setNotifications] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const HasAction = location.state?.HasAction;
  const navigatedItemWithNoAction = location.state?.menuItem;
  const notificationMaxLength = 150;

  const getNotifications = async () => {
    const data = await getNotificationsbyUserID(secureLocalStorage.getItem('userId'), 'Received');
    secureLocalStorage.setItem('showNotifLength', false);
    if (data.responseCode === 200) {
      setNotifications(data.responseData);
      secureLocalStorage.setItem('notificationsLength', data.responseData.filter(u => !u.isRead).length);
      secureLocalStorage.setItem('showNotifLength', true);
    } else {
      setNotifications([]);
      console.error('Error fetching notifications');
    }
  };

  const onMarkAllAsReadClick = async () => {
    const data = await MarkAsRead(0, secureLocalStorage.getItem('userId'));
    if (data.responseCode === 200) {
      getNotifications();
      const newUrl = `${window.location.origin}${window.location.pathname}?MarkAllRead=true`;
      window.history.pushState({}, '', newUrl);
    }
  };

  const convertHtmlToTextAndTruncate = (notificationBody) => {
    let plainText;
    const hasHtmlTags = /<\/?[a-z][\s\S]*>/i.test(notificationBody);
    if (hasHtmlTags) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = notificationBody || '';
      plainText = tempElement.textContent || tempElement.innerText || '';
    } else {
      plainText = notificationBody || '';
    }
    return plainText.length > notificationMaxLength
      ? plainText.slice(0, notificationMaxLength) + '...'
      : plainText;
  };

  const handleNotificationClick = async (notification) => {
    const { notificationDetailsID, notificationHasAction, notificationURL, notificationType } = notification;
    const userId = secureLocalStorage.getItem("userId");
    const response = await MarkAsRead(notificationDetailsID, userId);
    if (response?.responseCode === 200) {
      secureLocalStorage.setItem('notificationsLength', false)
      const userRole = secureLocalStorage.getItem('userrole');
      if (userRole === AppDashboards.INV_DASHBOARD) {
        let selectedTabIndex = 0;
        if (notificationHasAction === true) {
          switch (notificationType) {
            case "Personal information":
              selectedTabIndex = 0;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Banking information":
              selectedTabIndex = 1;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Investment profile":
              selectedTabIndex = 2;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Subscription details":
              selectedTabIndex = 3;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Marketplace":
              navigate('/marketplace');
              break;
            case "Notify to Investor":
              navigate(notificationURL);
              break;
            case "All funds from marketplace":
              secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
              navigate('/fund-details', {
                state: {
                  FundID: parseInt(notificationURL.match(/\d+$/)[0]),
                  InvestorID: secureLocalStorage.getItem("userId"),
                  SubscriptionID: 0,
                  navigatefrommarketplace: true,
                  disableSubscriptionButton: false
                }
              });
              break;
            case "Fund Subscribed":
            case "Request Additional Information":
            case "Info Provided":
            case "FM Review":
            case "Investor Review":
            case "Subscription Cancelled":
              var fundId = notificationURL.match(/FundId=(\d+)/)[1];
              var subscriptionId = notificationURL.match(/SubscribtionId=(\d+)/)[1];
              secureLocalStorage.setItem('InvestorAndFMNotificationsRow', { subscriptionsID: parseInt(subscriptionId), fundID: parseInt(fundId), fromNotifications: true });
              navigate('/subscription-details');
              break;
            case "K1Reporting":
              secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
              navigate(`/${notificationURL.split('/')[1]}?goto=2`);
              break;
            default:
              selectedTabIndex = 0;
              navigate('/all-notifications');
              break;
          }
        }
        else {
          setOpenPopup(true)
          setSelectedNotification(notification)
        }
      }
      else if (userRole === AppDashboards.CO_DASHBOARD ||
        userRole === AppDashboards.FM_DASHBOARD) {
        // debugger;
        switch (notificationType) {
          case "Notify User on Investor Comments":
          case "Notify User on KYCAML Update":
            navigate(notificationURL);
            break;
          case "Fund Termination":
            secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/FundId=(\d+)/)[1]));
            navigate(notificationURL);
            break;
          case "Fund Subscribed":
          case "Request Additional Information":
          case "Info Provided":
          case "FM Review":
          case "Investor Review":
          case "Subscription Cancelled":
            var fundId = notificationURL.match(/FundId=(\d+)/)[1];
            var subscriptionId = notificationURL.match(/SubscribtionId=(\d+)/)[1];
            // secureLocalStorage.setItem('fromNotifications', true)
            secureLocalStorage.setItem('InvestorAndFMNotificationsRow', { subscriptionsID: parseInt(subscriptionId), fundID: parseInt(fundId), fromNotifications: true });
            navigate('/subscription-details');
            break;
        }
      } else {
        console.log("User does not have the required role for navigation.");
      }
    } else {
      console.log("Error in marking as read");
    }
  };


  const onMarkAsReadClick = async (notification) => {
    const userId = secureLocalStorage.getItem("userId");
    const data = await MarkAsRead(notification.notificationDetailsID, userId);
    if (data.responseCode === 200) {
      getNotifications();
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedNotification(null);
  };

  useEffect(() => {
    getNotifications();
    if (HasAction === false) {
      setOpenPopup(true);
    }
  }, [HasAction]);

  const disableMarkallAsReadButton = () => {
    if (notifications.filter(u => u.isRead === false).length === 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      {notifications ? (
        <div className='child-margin-10'>
          <div className='space-between'>
            <div></div>
            <div>
              <ActionButton label="Mark all as read" onClick={onMarkAllAsReadClick} disabled={disableMarkallAsReadButton()} />
            </div>
          </div>
          <div >
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <div key={index} className="whiteCard" style={{ marginTop: '16px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="notifiy-Section">
                        <div onClick={() => handleNotificationClick(notification)}>
                          {notification.isRead ? notification.notificationSubject : <b>{notification.notificationSubject}</b>}
                        </div>
                        <div className="space-between displaycenter">
                          <div>{ConverttoIST(notification.updatedDate)}</div>
                          <div>
                            {!notification.isRead && (
                              <ContextMenu
                                MenuItemsTop={[
                                  { id: 1, label: 'Mark as read', onClick: () => onMarkAsReadClick(notification) },
                                ]}
                                ItemDetails={notification}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '3',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                      }}>
                        {convertHtmlToTextAndTruncate(notification.notificationBody)}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="notifiy-Section">
                        <div>
                          {notification.fileNames?.split(',').map((file, index) => (
                            <React.Fragment key={index}>
                              <a href={`${notification.containerPath}/${notification.notificationDetailsID}/${file.trim()}`} target="_blank" rel="noopener noreferrer">
                                {file}
                              </a>
                              <br />
                            </React.Fragment>
                          ))}
                        </div>
                        {notification.notificationBody?.length > notificationMaxLength && (
                          <div className="seemore" onClick={() => handleNotificationClick(notification)}>
                            See More <EastIcon />
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))
            ) : (
              <div className="whiteCard">No Data Available</div>
            )}
          </div>
        </div>
      ) : (
        <LinearProgress />
      )}
      {openPopup && (
        <NotificationDetailPopup
          open={openPopup}
          onClose={handleClosePopup}
          notificationDetails={HasAction === false ? navigatedItemWithNoAction : selectedNotification}
          setOpen={setOpenPopup}
        />
      )}
    </div>
  );
};

export default NotificationsInbox;
