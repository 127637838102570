import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import { FundCreationModes, percentageDropdownValues } from '../../services/apiDataDemplate';
import { FieldVariants, textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ActionButton from '../../../common/ActionButton/ActionButton';

const FeesandExpenses = forwardRef((props, ref) => {

  //#region props
  const { type,fundDetails, setFundDetails, fundTypes } = props;

  //#region intialData
  const initialData = {
    ...fundDetails?.feesExpenses[0]
  };

  //#region varibles
  const [loading,setLoading] = useState(false);
  const [isSaveEnabled,setIsSaveEnabled] = useState(type === FundCreationModes.EDIT ? false : true);

  //#region change events
  const handleChange = (field, value) => {
    feesExpenses.setFieldValue(field, value);
  }

  //#region click events
  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onSaveClick = async() => {
    const errors = await feesExpenses.validateForm();
    if (Object.keys(errors).length === 0) {
      /* updateFundDetails(); */
      alert("ok");
    }
    else {
      feesExpenses?.handleSubmit()
    }
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    feesExpenses?.resetForm();
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    managementFees: yup
      .string()
      .required(validationsConstants.REQUIRED),
    performanceFeesOrCarriedInterest: yup
      .string()
      .required(validationsConstants.REQUIRED),
    additionalFeesAndExpenses: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const feesExpenses = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await feesExpenses.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        const details = fundDetails;
        details["feesExpenses"] = [feesExpenses?.values];
        setFundDetails(details);
        return true;
      }
      else {
        feesExpenses?.handleSubmit()
      }
    },
    updateFields: async () => {
      //need to update main set of values
      const details = fundDetails;
      details["feesExpenses"] = [feesExpenses?.values];
      debugger
      setFundDetails(details);
    }
  }));

  //#region return
  return (
    <>
    {
      type === FundCreationModes.EDIT &&
      <div className='space-between '>
          <div></div>
          <div className='edit-save-sec child-row-margin-5 margin-right-10'>
              {isSaveEnabled ?
                <>
                  <div>
                    <ActionButton 
                        label="Cancel"
                        variant={FieldVariants.OUTLINED}
                        disabled={loading}
                        icon={<CancelIcon />}
                        onClick={() => onCancelClick()}/>
                  </div>
                  <div>
                    <ActionButton
                      label="SAVE"
                      icon={<SaveIcon />}
                      loading={loading}
                      onClick={() => onSaveClick()} />
                  </div>
                </> 
                :
                <div>
                  <ActionButton 
                      label="Edit"
                      icon={<EditIcon />}
                      onClick={() => onEditClick()}/>
                </div>}
            
            <div></div>
          </div>
      </div>
      }
      <div className='display-row-items-flex'>
        <div className='width-48'>
          <ALTTextField
            textFormatType={textFormatTypeEnum.PERCENTAGE}
            name="managementFees"
            label="Management Fees"
            value={feesExpenses?.values?.managementFees}
            onChange={(name, value) => handleChange(name, value)}
            required={true}
            onBlur={feesExpenses.handleBlur}
            error={feesExpenses.touched.managementFees && Boolean(feesExpenses.errors.managementFees)}
            errorMessage={feesExpenses.touched.managementFees && feesExpenses.errors.managementFees} />
        </div>
        <div className='width-48'>
          <SelectField
            name="performanceFeesOrCarriedInterest"
            label="Performance Fees / Carried Interest"
            value={feesExpenses?.values?.performanceFeesOrCarriedInterest}
            onChange={(name, value) => handleChange(name, value)}
            required={true}
            options={percentageDropdownValues}
            onBlur={feesExpenses.handleBlur}
            error={feesExpenses.touched.performanceFeesOrCarriedInterest && Boolean(feesExpenses.errors.performanceFeesOrCarriedInterest)}
            errorMessage={feesExpenses.touched.performanceFeesOrCarriedInterest && feesExpenses.errors.performanceFeesOrCarriedInterest} />
        </div>
        <div className='width-48'>
          <ALTTextField
            textFormatType={textFormatTypeEnum.PERCENTAGE}
            name="additionalFeesAndExpenses"
            label="Additional Fees and Expenses"
            value={feesExpenses?.values?.additionalFeesAndExpenses}
            onChange={(name, value) => handleChange(name, value)}
            required={true}
            onBlur={feesExpenses.handleBlur}
            error={feesExpenses.touched.additionalFeesAndExpenses && Boolean(feesExpenses.errors.additionalFeesAndExpenses)}
            errorMessage={feesExpenses.touched.additionalFeesAndExpenses && feesExpenses.errors.additionalFeesAndExpenses} />
        </div>
      </div>
    </>
  );
});

export default FeesandExpenses;