import { React, useRef, useState } from "react";
import { Box, } from "@mui/system";
import { useSelector } from "react-redux";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { BadgeDisplay, BoxbackgroundWhite } from "../../CommonCss";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useCallback, useEffect } from "react";
import { GetFundSubscriptions } from "../../InvestorFundSubscription/Services/Services";
import { CurrencyFormatter } from "../../../utils/Formater";
import VDRoom from "../../common/VirtualDataRoom/VDRoom";
import { GetDocuSignURL, getStatesandCitieslist } from "../../InvestorFundSubscription/Services/Statecountry";
import { toast, ToastContainer } from "react-toastify";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SaveIcon from '@mui/icons-material/Save';
import StepContainer from "../../investor-fund-subscription/components/StepContainer";
import { questionPatterns, stepsToShowWhile, subscriptionStatusEnum } from "../../investor-fund-subscription/services/apiDataTemplate";
import { SubscribeFund, getFundSubscriptionDetails, getFundbyId } from "../../investor-fund-subscription/services/services";
import secureLocalStorage from "react-secure-storage";
import { LoadingButton } from "@mui/lab";
import { AppDashboards } from "../../../utils/enum";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AppDiscussionComponent from "../../common/AppDiscussion/AppDiscussionComponent";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { investorResponse, updateFundSubscriptionStatus } from "../Services/Services";
import SubscriptionRejectionPopup from "../popups/SubscriptionRejectionPopup";
import { fundSubscriptionStatues, updateSubscriptionDetails } from "../Services/apiDataTempate";
import { Tooltip } from "react-bootstrap";
import ActionButton from "../../common/ActionButton/ActionButton";
import FMSubscriptionConfirmationPopup from "../popups/FMSubscriptionConfirmationPopup";
import { useLocation } from "react-router-dom";
import { getFundSubscriptions } from "../../dashboards/services/DashboardService";
import { ConvertToUSCurrency } from "../../common/Functions/CommonConvertions";
import { getSubscriptionComments } from "../../common/AppDiscussion/Services/AppDiscussions";
import { convertArrayToString } from "../../common/Functions/ConvertStringtoArray";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FundRaisePopup from "../../fund-overview/popups/FundRaisePopup";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

//#this page is to review subscripton for fund manager ,compliance officer and investor
const FundSubscriptionReview = (props) => {

    //#region props
    const { subscriptionID, subscriptionDetails } = props;


    //#region variables
    const [crntSubscriptionDetails, setcrntSubscriptionDetails] = useState(subscriptionDetails);
    //console.log(subscriptionDetails, 'crntSubscriptionDetails')
    const [DropdownsData, setDropdownsData] = useState(null);
    const [count, setCount] = useState(0)
    const [investorSubscriptionDetails, setInvestorSubscriptionDetails] = useState();
    const [sbtLoading, setSbtLoading] = useState(false);
    const [showComments, setShowsComments] = useState(true)
    const [appDiscussions, setAppDiscussions] = useState(null);
    const [rejectionPopup, setRejectionPopup] = useState();
    const [approveBtnLoading, setApproveBtnLoading] = useState(false);
    const [rejectBtnLoading, setRejectBtnLoading] = useState(false);
    const discussionRef = useRef(null);
    const fromNotifications = secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fromNotifications;
    const [fmConfirmatioPopup, setFMConfirmationPopup] = useState(false);
    const [investorEsignLoading, setInvetsorEsignLoading] = useState(false);
    const [refreshComponents, setRefreshComponents] = useState(false);
    const [comments, setComments] = useState(null)
    const location = useLocation();
    const [openReduceAmountPopup,setOpenReduceAmountPopup] = useState(false);
    const [fundDetails,setFundDetails] = useState(null);
    const [reductionBtnLoading,setReductionBtnLoading] = useState(false);

    //#region functions
    const resetInitialValues = () => {
        getSubscriptionDetails();
        setApproveBtnLoading(false);
        setRejectBtnLoading(false);
        onCloseRejectionPopup();
        setFMConfirmationPopup();
    }

    //#region click events
    const onOpenReductionPopup = () =>{
        setOpenReduceAmountPopup(true);
    }

    const onCloseReductionPopup = () => {
        setOpenReduceAmountPopup(false);
    }

    const onRefreshComponents = () => {
        setRefreshComponents(true);
        setTimeout(() => {
            setRefreshComponents(false)
        }, 300)
    }

    const onCommnetsClickbtn = (isHide) => {
        setShowsComments(isHide);
    }

    const onOpenRejectionPopup = () => {
        setRejectionPopup(true);
    }

    const onCloseRejectionPopup = () => {
        setRejectionPopup(false);
    }

    const onCloseConfirmationPopup = () => {
        setFMConfirmationPopup(false);
    }

    const onInvestorEsignClick = () => {
        setSbtLoading(true);
    }

    const onApproveClick = () => {
        if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD) {
            if (investorSubscriptionDetails?.fundBasicDetails?.authorizedSignatory?.includes(secureLocalStorage.getItem('userId'))) {
                setFMConfirmationPopup(true);
            }
            else {
                updateSubscriptionStatus(true, " ");
            }
        }

        else if (secureLocalStorage.getItem("userrole") === AppDashboards.CO_DASHBOARD) {
            setFMConfirmationPopup(true);
        }
    }

    //#region api get calls
    const getFundDetails = async () => {
        const data = await getFundbyId(investorSubscriptionDetails?.fundID);
        if (data.responseCode === 200) {
            setFundDetails(data.responseData);
        }
        else {
    
        }
    };
      
    const getstateslist = async () => {

        const data = await getStatesandCitieslist();
        if (data.responseCode === 200) {
            setDropdownsData(data.responseData);
        } else {
            // Handle error if needed
        }
    }


    const getSubscriptionDetails = async () => {
        const investorId = crntSubscriptionDetails?.investorID ? crntSubscriptionDetails?.investorID : 0
        const fundID = (crntSubscriptionDetails?.fundID === null ? 0 : crntSubscriptionDetails?.fundID)
        const fromNotifications = secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fromNotifications;
        const notificationData = secureLocalStorage.getItem('InvestorAndFMNotificationsRow');

        const data = await (
            fromNotifications === true
                ? getFundSubscriptionDetails(
                    notificationData.fundID,
                    0,
                    notificationData.subscriptionsID
                )
                : getFundSubscriptionDetails(
                    (fundID !== null && fundID !== undefined) ? fundID : secureLocalStorage.getItem('fundID'),
                    investorId,
                    fromNotifications === true ? notificationData.subscriptionsID : subscriptionID
                )
        );

        if (data.responseCode === 200) {
            // debugger
            setInvestorSubscriptionDetails(data.responseData);
            setCount(1)
            //setdataRetrived(true)
        }
        else {
            setInvestorSubscriptionDetails(null);
            //setdataRetrived(true)
        }
    }

    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        const docuSignResponse = queryParams.get('event');
        if(docuSignResponse?.toLowerCase() == "signing_complete"){
            setCount(count+1)
        }
    },[])
    
    const getComments = async () => {
        const fromNotifications = secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fromNotifications;
        const notificationData = secureLocalStorage.getItem('InvestorAndFMNotificationsRow');

        try {
            const data = await getSubscriptionComments(investorSubscriptionDetails?.subscriptionID)
            if (data.responseCode === 200) {
                setComments(data.responseData);
            } else {
                console.log('error happened');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const getDocumentLink = async () => {
        // debugger
        secureLocalStorage.setItem('fundID', crntSubscriptionDetails?.fundID)
        setApproveBtnLoading(true);
        const data = await GetDocuSignURL(
            // crntSubscriptionDetails?.fundID
            subscriptionID
            // , secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD
            // , secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD ? '0' : investorSubscriptionDetails?.fmEnvelopID
        );
        var inputUrl = data?.responseData?.toString();
        var startIndex = inputUrl.indexOf("https://");
        var result = inputUrl.substring(startIndex);
        if (data.responseCode === 200) {
            window.location.href = result;
        }
    };

    //#region post calls
    const submitSubscriptionAnswers = async (questionsDetails) => {
        setInvetsorEsignLoading(true);

        const subscriptionID = investorSubscriptionDetails?.subscriptionID;
        const investorId = crntSubscriptionDetails?.investorID
        const fundID = crntSubscriptionDetails?.fundID

        const requestedData = questionsDetails
            /* ?.filter((question) =>
              (question.userProvidedAnswer !== null && question.userProvidedAnswer !== "") ||
              (question.multiChoiceQuestionAnswerIDs !== null && question.multiChoiceQuestionAnswerIDs.length > 0) ||
              (question.questionAnswerID !== null && question.questionAnswerID !== 0)) */
            ?.map((item) => ({
                "UserProfileID": item?.subscriptionID === 0 ? 0 : item?.userProfileID,
                "QuestionBankID": item?.questionBankID,
                "QuestionName": item?.questionName,
                "UserID": investorId,
                "fundID": fundID,
                "SubscriptionID": item?.subscriptionID || 0,
                "QuestionPatternTypeID": item?.questionPatternTypeID,
                "QuestionPatternType": item?.questionPatternType,
                "QuestionAnswerID": item?.questionAnswerID || 0,
                "UserProvidedAnswerinText": item.questionPatternType === questionPatterns.FILE_UPLOAD
                    ? (item?.userProvidedAnswer?.name || "")
                    : item.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION ? convertArrayToString(item?.multiChoiceQuestionAnswerIDs)
                        : item.questionPatternType === questionPatterns.GRID
                            ? (JSON.stringify(item?.userProvidedAnswer) || "")
                            : item?.userProvidedAnswer || "",
                "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
                /* "UploadedFile": item?.uploadedFile || '', */
            })) || [];

        const formData = new FormData();

        const filteredFileQuestions = questionsDetails
            ?.filter((question) => {
                return question.questionPatternType === questionPatterns.FILE_UPLOAD;
            });

        for (const question of filteredFileQuestions) {
            const userProvidedAnswer = question?.userProvidedAnswer;
            if (userProvidedAnswer instanceof Blob || userProvidedAnswer instanceof File) {
                formData.append("fileList", userProvidedAnswer, `${question.questionBankID}/${userProvidedAnswer.name}`);
            }
        }

        const fundTypeId = investorSubscriptionDetails?.fundBasicDetails?.fundTypeId
        const investorTypeID = investorSubscriptionDetails?.investorTypeID

        formData.append("userProvidedAnswers", JSON.stringify(requestedData))
        formData.append("questionType", "Subscription")
        const data = await SubscribeFund(fundID, fundTypeId, investorTypeID, investorId, subscriptionID, formData);
        if (data.responseCode === 200) {
            setSbtLoading(false);
            if (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING
                || investorSubscriptionDetails?.canInvestorEdit) {
                var inputUrl = data.responseData?.investorDocuSignURL?.toString();
                var startIndex = inputUrl?.indexOf("https://");
                var result = inputUrl?.substring(startIndex);
                if (result) {
                    window.location.href = result;
                }
                else {
                    toast.error("unable to navigate to e-sign document",
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                }
            }
            getSubscriptionDetails();
            if (investorSubscriptionDetails?.subscriptionStatusName !== fundSubscriptionStatues.INVESTOR_SIGN_PENDING
                || !investorSubscriptionDetails?.canInvestorEdit) {
                toast.success("Fund Subscribed Successfully",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
        else {
            setSbtLoading(false);
            getSubscriptionDetails();
            if (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING) {
                toast.error("unable to navigate to e-sign document",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
            else {
                toast.error("unable to subscribe the fund",
                    { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            }
        }
    }

    //#region api update calls
    const updateSubscriptionStatus = async (isApproved, rejectionReason) => {
        const isFundManager = investorSubscriptionDetails?.fundBasicDetails?.authorizedSignatory.includes(secureLocalStorage.getItem('userId')) ? true : false;
        if (isApproved) {
            setApproveBtnLoading(true);
        }
        else {
            setRejectBtnLoading(true);
        }

        const requestedData = updateSubscriptionDetails;

        requestedData.FundSubscriptionID = subscriptionID;
        requestedData.FundSubscriptionStatus = isApproved ? "Approved" : "Rejected"
        requestedData.IsFundManager = isFundManager
        requestedData.RejectionComments = rejectionReason

        const data = await updateFundSubscriptionStatus(requestedData)
        if (data.responseCode === 200) {
            resetInitialValues();
            toast.success("Status Updated Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
        } else {
            resetInitialValues();
            toast.warning("Something Went Wrong , Please Try Again",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" })
        }
    }

    const updateReductionRequestStatus = async(isAccepted) => {
        setReductionBtnLoading(true);
        const data = await investorResponse(investorSubscriptionDetails?.subscriptionID,isAccepted);
        if (data.responseCode === 200) {
            resetInitialValues();
            toast.success("Reduction Request Approved Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                setReductionBtnLoading(false);
        }
        else {
            setReductionBtnLoading(false);
            toast.error("Unable to update reduction request",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    }

    //#region buttons hide and show
    const showApproveRejectButtons = () => {
        if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
            return false;
        }
        else if ((secureLocalStorage.getItem('userrole') === AppDashboards.FM_DASHBOARD || secureLocalStorage.getItem('userrole') === AppDashboards.CO_DASHBOARD) && investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.COMPLIANCE_REVIEW) {
            return true;
        }
        else if (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.FM_REVIEW
            && investorSubscriptionDetails?.fundBasicDetails?.authorizedSignatory.includes(secureLocalStorage.getItem('userId'))) {
            return true;
        }
        else if (investorSubscriptionDetails?.subscriptionStatusName === subscriptionStatusEnum.ON_HOLD) {
            return true;
        }
        return false;
        // (secureLocalStorage.getItem('isFirmUser') === true &&
        //                     investorSubscriptionDetails?.subscriptionStatusName !== fundSubscriptionStatues.COMPLIANCE_REVIEW) ||
        //                     (investorSubscriptionDetails?.subscriptionStatusName !== fundSubscriptionStatues.FM_REVIEW &&
        //                         investorSubscriptionDetails?.fundBasicDetails?.authorizedSignatory.includes(secureLocalStorage.getItem('userId')))
    }
    useEffect(() => {
        const fetchData = async () => {
            if (secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fromNotifications) {
                const data = await getFundSubscriptions(secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fundID);
                if (data.responseCode === 200) {
                    const investorverified = data.responseData.find(
                        u => u.subscriptionsID === secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.subscriptionsID
                    )?.isInvestorVerified;

                    if (crntSubscriptionDetails) {
                        crntSubscriptionDetails.isInvestorVerified = investorverified;
                    }
                }
            }
        };
        fetchData();
    }, []);

    //#region condition for rendering comments 

    const renderComments = () => {
        if (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD) {
            if (comments?.length > 0) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    //#region useeffect
    useEffect(() => {
        getstateslist()
        getSubscriptionDetails();
    }, [])

    useEffect(() => {
       if(investorSubscriptionDetails){
        getFundDetails();
        getComments();
       }
    }, [investorSubscriptionDetails])

    useEffect(() => {
        if (refreshComponents) {
            getSubscriptionDetails();
        }
    }, [refreshComponents])

    useEffect(() => {
        if (fromNotifications && discussionRef.current) {
            discussionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [fromNotifications]);

    //#region return
    return (
        <>
            <Grid container xs={12} marginTop={'20px'}>
                <Grid item container xs={12} className='whiteCard' sx={{ justifyContent: 'space-between', padding: '10px' }} >
                    <Grid item xs={12} sm={5.75} md={5.75} lg={5.75} sx={BadgeDisplay}>
                        <div>
                            <img className="defaultLogo"
                                alt=""
                                src={investorSubscriptionDetails?.fundBasicDetails?.fundLogo
                                    ? investorSubscriptionDetails?.fundBasicDetails?.fundLogo
                                    : "/DefaultLogo.png"}
                            />
                        </div>
                        <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h4 style={{ marginTop: '8px' }}>
                                {
                                    investorSubscriptionDetails?.fundBasicDetails?.fundName
                                }
                            </h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5.75} md={5.75} lg={5.75} sx={BadgeDisplay}>
                        <div>
                            <img
                                src={investorSubscriptionDetails?.fundBasicDetails?.userProfileIcon
                                    ? investorSubscriptionDetails?.fundBasicDetails?.userProfileIcon
                                    : "/icons/defaultpropic.jpg"}
                                alt="" style={{ width: '60px', height: '60px' }} />
                        </div>
                        <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                            <p>
                                {investorSubscriptionDetails?.investorType === 0 || investorSubscriptionDetails?.investorType === "0" ? "--" : investorSubscriptionDetails?.investorType}
                            </p>
                            <h4>
                                {investorSubscriptionDetails?.investor}
                            </h4>
                        </div>
                    </Grid>


                    <Grid item xs={12} sm={5.75} md={5.75} lg={5.75} sx={BadgeDisplay}>
                        <div>
                            <div>Subscription Amount</div>
                            <h4 style={{ marginTop: '10px' }}>
                                {ConvertToUSCurrency(investorSubscriptionDetails?.capitalCall)}
                            </h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5.75} md={5.75} lg={5.75} sx={BadgeDisplay}>
                        <div>
                            <div>Subscription Status</div>
                            <h4 style={{ marginTop: '10px' }}>
                                {
                                    secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD 
                                    ? <>
                                        {investorSubscriptionDetails?.subscriptionStatusName?.includes("Review")
                                        ? "Under Review"
                                        : investorSubscriptionDetails?.subscriptionStatusName}
                                      </>
                                    : <>
                                       {investorSubscriptionDetails?.subscriptionStatusName === "Under Compliance Review" 
                                         ? "Under Review" 
                                         : investorSubscriptionDetails?.subscriptionStatusName
                                        }
                                      </>
                                }
                            </h4>
                        </div>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sx={{ justifyContent: 'space-between' }}  >
                </Grid>
            </Grid>
            {
                (secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD
                 && parseInt(investorSubscriptionDetails?.proposedSubscriptionAmount) > 0) &&
                <div className="investor-reduction-request-sec">
                    <div className="white-card margin-top-20">
                        <Grid container xs={12}>
                            <div className="child-margin-15">
                            <div>We request you to consider reducing your subscription amount for  
                                <b> {investorSubscriptionDetails?.fundBasicDetails?.fundName}</b> to help align with the fund’s target raise. 
                            </div>
                            <div>
                                    Original Subscription Amount: <b>{ConvertToUSCurrency(investorSubscriptionDetails?.capitalCall)}</b> <br />
                                    Reduced Amount: <b>{ConvertToUSCurrency(investorSubscriptionDetails?.proposedSubscriptionAmount)}</b>  
                            </div>
                            <div>
                                Please review the request and choose one of the following actions: <br />
                                    1. Accept the Reduced Amount: Proceed with the adjusted subscription amount.<br /> 
                                    2. Reject the Request: Retain your original subscription amount
                            </div>
                            <div className="child-row-margin-5">
                                <div>
                                    <ActionButton 
                                        label="ACCEPT THE REDUCED AMOUNT"
                                        icon={<CheckCircleIcon />}
                                        onClick={() => updateReductionRequestStatus(true)}
                                        loading={reductionBtnLoading}/>
                                </div>
                                <div>
                                    <ActionButton 
                                        label="REJECT THE REQUEST"
                                        icon={<ThumbDownOffAltIcon />}
                                        onClick={() => updateReductionRequestStatus(false)}
                                        loading={reductionBtnLoading}/>
                                </div>
                            </div>
                            </div>
                        </Grid>
                    </div>
                </div>
            }
            {
                <div className="white-card margin-top-20">
                    <Grid container xs={12}>
                        <div>
                            <StepContainer
                                type={stepsToShowWhile.REVIEW}
                                investorId={crntSubscriptionDetails?.investorID}
                                fundID={crntSubscriptionDetails?.fundID}
                                subscriptionId={crntSubscriptionDetails?.subscriptionID}
                                investorSubscriptionDetails={investorSubscriptionDetails}
                                getQuestionAnswerDetails={getSubscriptionDetails}
                                submitSubscriptionAnswers={submitSubscriptionAnswers}
                                sbtLoading={sbtLoading}
                                setSbtLoading={setSbtLoading} />
                        </div>
                    </Grid>
                    <div className="space-between">
                        <div></div>
                        <div className='child-row-margin-5'>
                            {
                                secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD &&
                                <>

                                    {/* { investorSubscriptionDetails?.canInvestorEdit ?
                                    <div>
                                        <LoadingButton
                                            variant='contained'
                                            size="large"
                                            loading={sbtLoading}
                                            startIcon={<SaveIcon />}
                                            onClick={onReSubmitSubscription}
                                            loadingPosition="start">
                                            <p className={`actionButtonLabel`}>RE-SUBMIT</p>
                                        </LoadingButton>
                                    </div> :  */}
                                    {/* {
                                    (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING 
                                    || investorSubscriptionDetails?.canInvestorEdit) &&
                                        <div>
                                            <LoadingButton
                                                variant='contained'
                                                size="large"
                                                onClick={onInvestorEsignClick}
                                                startIcon={<DriveFileRenameOutlineIcon />}
                                                loading={investorEsignLoading}
                                                loadingPosition="start">
                                                <p className={`actionButtonLabel`}>E - SIGN</p>
                                            </LoadingButton>
                                        </div>
                                    
                                } */}
                                </>
                            }
                            {showApproveRejectButtons()
                                &&
                                <>
                                    {
                                        (secureLocalStorage.getItem("userrole") !== AppDashboards.INV_DASHBOARD
                                         && parseInt(investorSubscriptionDetails?.fundBasicDetails?.totalCapitalApproved) + parseInt(investorSubscriptionDetails?.fundBasicDetails?.totalCapitalUnderReview) + parseInt(investorSubscriptionDetails?.fundBasicDetails?.cCsOnHold) > parseInt(investorSubscriptionDetails?.fundBasicDetails?.fundSize)) &&
                                        <ActionButton 
                                            label="Ask to Reduce Subscription Amount"
                                            icon={<MonetizationOnIcon />}
                                            onClick={onOpenReductionPopup}/>
                                    }
                                    <div>
                                        <ActionButton
                                            variant='outlined'
                                            size="large"
                                            onClick={onOpenRejectionPopup}
                                            loading={rejectBtnLoading}
                                            disabled={approveBtnLoading}
                                            icon={<ThumbDownOffAltIcon />}
                                            label="REJECT"
                                            toolTip={!(crntSubscriptionDetails?.isInvestorVerified) && 'Investor KYC/AML is still incomplete. Kindly complete it that to proceed with subscription approval.'}
                                            loadingPosition="start">
                                        </ActionButton>
                                    </div>
                                    {
                                        investorSubscriptionDetails?.subscriptionStatusName !== subscriptionStatusEnum.ON_HOLD &&
                                        <div>
                                            <ActionButton
                                                variant='contained'
                                                size="large"
                                                loading={approveBtnLoading}
                                                disabled={rejectBtnLoading || (crntSubscriptionDetails?.isInvestorVerified === undefined ? (investorSubscriptionDetails?.isInvestorVerified === false) : (crntSubscriptionDetails?.isInvestorVerified === false))}
                                                //disabled={rejectBtnLoading || !(crntSubscriptionDetails?.isInvestorVerified)|| (investorSubscriptionDetails?.isInvestorVerified === false)}
                                                icon={<ThumbUpAltIcon />}
                                                onClick={onApproveClick}
                                                loadingPosition="start"
                                                label="APPROVE"
                                                toolTip={!(crntSubscriptionDetails?.isInvestorVerified) && 'Investor KYC/AML is still incomplete. Kindly complete it that to proceed with subscription approval.'}
                                            >
                                            </ActionButton>
                                        </div>
                                    }
                                </>
                            }
                            {/* {
                                (secureLocalStorage.getItem('userrole') === AppDashboards.CO_DASHBOARD && 
                                    (investorSubscriptionDetails?.subscriptionStatusName !== fundSubscriptionStatues.COMPLIANCE_REVIEW
                                    || investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.REJECTED)) &&
                                    !investorSubscriptionDetails?.canInvestorEdit &&
                                    <div>
                                        <ActionButton 
                                            variant='contained'
                                            label={investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.REJECTED 
                                                    ? "REJECTED" : 'APPROVED'}
                                            onClick={() => {}}
                                            styleProps={{
                                                marginRight: '10px'
                                            }}
                                            disabled={true}
                                            toolTip={
                                                investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.REJECTED ?
                                                "You have already Rejected this investor's subscription" :
                                                "You have already Approved this investor's subscription" }
                                        />
                                    </div>
                            }
                            {
                                (secureLocalStorage.getItem('userrole') === AppDashboards.FM_DASHBOARD && 
                                    (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.APPROVED
                                    || investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.REJECTED)) &&
                                    !investorSubscriptionDetails?.canInvestorEdit &&
                                    <div>
                                        <ActionButton 
                                            variant='contained'
                                            label={investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.REJECTED 
                                                    ? "REJECTED" : 'APPROVED'}
                                            onClick={() => {}}
                                            styleProps={{
                                                marginRight: '10px'
                                            }}
                                            disabled={true}
                                            toolTip={
                                                investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.REJECTED ?
                                                "You have already Rejected this investor's subscription" :
                                                "You have already Approved this investor's subscription" }
                                        />
                                    </div>
                            } */}
                        </div>
                    </div>

                    {/*  <div className='child-row-margin-5 res-mt2'>
                       {
                            renderComments() && <LoadingButton
                                variant='outlined'
                                size="large"
                                onClick={() => { onCommnetsClickbtn(!showComments) }}
                                startIcon={!showComments ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                loadingPosition="start">
                                <p className={`actionButtonLabel`}>{!showComments ? (AppDashboards.CO_DASHBOARD || AppDashboards.FM_DASHBOARD ? "REQUEST ADDITIONAL INFORMATION" : "SHOW COMMENTS") : "HIDE COMMENTS"}</p>
                            </LoadingButton>
                        } 
                    </div> */}
                </div>

            }
            {renderComments() &&
                <div className="white-card margin-top-20" ref={discussionRef}>
                    <>
                        <Grid item xs={12} spacing={2}>
                            <h6>Notes and Comments</h6>
                        </Grid>
                        <Grid item xs={12} marginTop="20px" >
                            {/* <Editor/> */}
                            <AppDiscussionComponent
                                refreshComponents={onRefreshComponents}
                                AppDiscussions={appDiscussions}
                                setAppDiscussions={setAppDiscussions}
                                subscriptionID={investorSubscriptionDetails?.subscriptionID}
                                subscriptionStatus={investorSubscriptionDetails?.subscriptionStatusName}
                            // readOnly={
                            //     secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
                            //         (investorSubscriptionDetails?.canInvestorEdit) ? true : false :
                            //         false
                            // }
                            />
                        </Grid>
                    </>
                </div>
            }

            {
                !refreshComponents &&
                <Grid container xs={12} className="investorgrid" marginTop={'20px'} sx={BoxbackgroundWhite} refreshonchange={refreshComponents}>

                    <VDRoom
                        VDRType={"Fund"}
                        isCompliance={true}
                        count={count}
                        setCount={setCount}
                        cnrtFundID={(secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fromNotifications === true) ? secureLocalStorage.getItem('InvestorAndFMNotificationsRow').fundID : crntSubscriptionDetails?.fundID}
                        crntInvestorID={crntSubscriptionDetails?.investorID}
                        crntSubscriptionID={(secureLocalStorage.getItem('InvestorAndFMNotificationsRow')?.fromNotifications === true) ? secureLocalStorage.getItem('InvestorAndFMNotificationsRow').subscriptionsID : subscriptionID}
                        refreshComponents={onRefreshComponents} />

                </Grid>
            }

            <Grid item container xs={12} color={"whilte"} marginTop={"100px"} >

            </Grid>
            {
                rejectionPopup &&
                <SubscriptionRejectionPopup
                    open={rejectionPopup}
                    onClose={onCloseRejectionPopup}
                    isApproved={false}
                    updateSubscriptionStatus={updateSubscriptionStatus}
                    CurrStatus={investorSubscriptionDetails?.subscriptionStatusName}
                    rejectBtnLoading={rejectBtnLoading} />
            }
            {
                fmConfirmatioPopup &&
                <FMSubscriptionConfirmationPopup
                    open={fmConfirmatioPopup}
                    onClose={onCloseConfirmationPopup}
                    isApproved={true}
                    CurrStatus={investorSubscriptionDetails?.subscriptionStatusName}
                    CoupdateSubscriptionStatus={updateSubscriptionStatus}
                    updateSubscriptionStatus={getDocumentLink}
                    approveBtnLoading={approveBtnLoading} />
            }
            {
                openReduceAmountPopup && fundDetails &&
                <FundRaisePopup
                    open={openReduceAmountPopup}
                    onClose={onCloseReductionPopup}
                    fundDetails={fundDetails}
                    getFundDetails={getFundDetails} />
            }
            <ToastContainer />
        </>
    )
}
export default FundSubscriptionReview;