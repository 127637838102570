import { useEffect , useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { getFundbyId } from "../../investor-fund-subscription/services/services";
import { checkFeaturePermissions } from "../../../utils/common";
import { FeatureAccess, RolesAndPermissions } from "../../../utils/enum";
import { fundTypesEnum } from "../../marketplace-dashboard/jsonData";
import OverviewTab from "./OverviewTab";
import PerformanceTab from "./PerformanceTab";
import PortfolioTab from "./PortfolioTab";
import InvestorRelationsTab from "./InvestorRelationsTab";
import ServicingTab from "./ServicingTab";
import ComplianceTab from "./ComplianceTab";
import DashboardHeader from "../../dashboards/DashboardHeader";
import AddCompany from "../../PortfolioCompanies/components/PopUps/AddCompany";
import NotifyPopupModel from "../../PortfolioCompanies/components/PopUps/NotifyPopupModel";
import OverviewHeader from "./Header";
import Header from "./Header";
export const FundOverviewMain = () => {

    //#region variables
    const navigate = useNavigate();
    const params = useParams();
    const [newUuid, setNewUuid] = useState("0");
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
    const [notifyPopupopen, setNotifyPopupopen] = useState (false);
    const [ispopupopen,setispopupopen] = useState(false);
    const fundID = secureLocalStorage.getItem("FundId");
    const [fundDetails, setFundDetails] = useState();
    const [buttonDetails, setButtonDetails] = useState({
        buttonName: "Edit Fund",
        buttonIcon: <EditIcon/>,
        buttonVisibility: true,
        navigate: "/edit-fund-page",
    })

    //#region click events
    const onBtnClick = (event) => {
        if(params.detail === "OverviewPage"){
            navigate('/edit-fund-page');
        }
        else{
            /* setNewUuid(uuid());
            setispopupopen(true); */
            secureLocalStorage.setItem("companyID",0);
            navigate('/portfolio-company-overview');
        }
    }

    const navigateBackto = () => {
        if(secureLocalStorage.getItem("navigatingFromMarketPlace")){
            navigate("/Marketplace")
        }
        else{
            navigate("/FundManager-Dashboard")
        }
    }

    //#region api get calls
    const getFundDetails = async () => {
        const data = await getFundbyId(fundID);
        if (data.responseCode === 200) {
            setFundDetails(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    //#region useeffect
    useEffect(()=>{
        getFundDetails();
    },[])

      useEffect(() => {
        if (params.detail === "OverviewPage" && checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.UPDATE)) {
            setButtonDetails({
                buttonName: "EDIT FUND",
                buttonIcon: <EditIcon/>,
                buttonVisibility: true,
                navigate: "/edit-fund-page",
            })
        }
        else if (params.detail === "portfolio-page" && checkFeaturePermissions(RolesAndPermissions.PORTFOLIO_COMPANY, FeatureAccess.CREATE)&& !(fundDetails?.isFundTerminated) && !(fundDetails?.isFundMatured)) {
            setButtonDetails({
                buttonName:  fundDetails?.fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ? "ADD ASSET" : "ADD COMPANY",
                buttonIcon: "",
                buttonVisibility: fundDetails?.fundTerminated ? false : true,
                navigate: "/portfolio-company-overview",
            })
        }
        else {
            setButtonDetails({
                buttonName: "",
                buttonIcon: "",
                buttonVisibility: false,
                navigate: ""
            })
        }
    }, [params.detail,fundDetails])


    //#region tab navigations
    const marketRouteconfig = [
        {
            path: "OverviewPage",
            component: <OverviewTab fund={fundDetails} getFundDetails={getFundDetails} />
        },
        {
            path: "PerformancePage",
            component: <PerformanceTab fundDetails={fundDetails} />
        },
        {
            path: "portfolio-page",
            component: <PortfolioTab fundDetails={fundDetails}/>
        },
        {
            path: "fund-detail-investor-relations",
            component: <InvestorRelationsTab />
        },
        {
            path: "fund-detail-services-1",
            component: <ServicingTab fund={fundDetails}/>
        },
        {
            path: "fund-detail-compliance",
            component: <ComplianceTab />
        }
    ]

    const FundDetailTag = marketRouteconfig.find(el => el.path == params.detail).component;

    useEffect(()=>{
      getFundDetails();
    },[window.location.href])

    //#region return
    return (
        <div style={{ backgroundColor: rootBackColorConfig.color }}>
            <Header
                selectedTab={params.detail}
                FundName={secureLocalStorage.getItem("FundName")}
                buttonVisibility={buttonDetails?.buttonVisibility}
                buttonIcon={buttonDetails?.buttonIcon}
                buttonName={buttonDetails?.buttonName}
                onclick={onBtnClick}
                navigateBackto={navigateBackto}
                icon={fundDetails?.iconImage}
                background={fundDetails?.bgImage ? fundDetails?.bgImage : '/DefaultBackgroundImage.png'}
                fundDetails={fundDetails}
                getFundDetails={getFundDetails} />
            <div className="wrapper">{FundDetailTag}</div>
          {/* {notifyPopupopen && <NotifyPopupModel handleCancelModel={(data) => { setNotifyPopupopen(data) }} setNotifyPopupopen={setNotifyPopupopen} newUuid={newUuid}/>} */}

          {ispopupopen && <AddCompany ispopupopen={ispopupopen} setispopupopen={setispopupopen} setNotifyPopupopen={setNotifyPopupopen}/>}
         {notifyPopupopen && <NotifyPopupModel handleCancelModel={(data) => { setNotifyPopupopen(data) }} setNotifyPopupopen={setNotifyPopupopen} newUuid={newUuid}/>}

        </div>
    )
}