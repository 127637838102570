import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ChooseInvestorType from '../Popups/ChooseInvestorType';
import { BulkUploadDataSave, downloadBulkUploadTemplate, getInvestorsList } from '../Services/IRServices';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import SaveIcon from '@mui/icons-material/Save';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useEffect } from 'react';
import { BoxbackgroundWhite } from '../../CommonCss';
import { getAllFunds } from '../Services/IRServices';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer, toast } from 'react-toastify';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Configurations from '../../../Configurations';
import AddIcon from '@mui/icons-material/Add';
import BulkUploadModal from '../Popups/BulkUploadModal';
import SelectedInvestorData from '../Popups/BulkUploadSelectedInvestor';
import ActionButton from '../../common/ActionButton/ActionButton';
import BulkUploadDownloadModal from '../Popups/BulkUploadDownloadModal';


const Investors = () => {

  //#region useState Variables //#region Popup Variables
  const [selectpopup, setselectPopup] = useState(false);
  const [loading, setLoading] = useState(false);  //#Button Loader
  const [BulkUploadSaveData, setBulkUploadSaveData] = useState([]);
  const [BulkUploadStatus, setBulkUploadStatus] = useState(null);
  const [BulkUpload, setBulkUpload] = useState(false);
  const [BulkUploadSelectedInvestor, setBulkUploadSelectedInvestor] = useState(false);
  const [downloadTemplateModal, setdownloadTemplateModal] = useState(false)
  const [investorsList, setInvestorsList] = useState(null);
  const [fundsDropdown, setfundsDropdown] = useState(null);
  const [InvestorsRowsData, setInvestorsRowsData] = useState(null);

  //#region click events
  const openSelectPopup = () => {
    setselectPopup(true);
  }

  const closePopup = () => {
    setselectPopup(false);
  }

  const openBulkUpload = () => {
    setBulkUpload(true);
  }

  const closeBulkUpload = () => {
    setBulkUpload(false);
  }

  const openBulkUploadSelectedInvestor = (params) => {
    setBulkUploadSelectedInvestor(true);
  }

  const closeBulkUploadSelectedInvestor = () => {
    setBulkUploadSelectedInvestor(false);
  }


  //#BulkUploadGridData & Service calls 
  const [appendRowOnBulkupload, setappendRowOnBulkupload] = useState([]);

  const BulkUploadColumns = [
    { field: 'userFullName', headerName: 'Name', width: 200, },
    { field: 'userEmailAddress', headerName: 'Email Address', width: 400 },
    { field: 'investorType', headerName: 'Investor Type', width: 200  },
  ]

  const downloadTemplateModalOpen = () => {
    setdownloadTemplateModal(true)
  }

  const DownloadBulkUploadSampleDocument = async (InvestorType) => {
    fetch(`${Configurations.apiBaseUri}/v1/BulkUpload/DownloadBulkUploadInvestorDocument?isNaturePerson=${InvestorType}`, {
      method: 'GET',
    })
      .then(response => {
        if (response.ok) {
          return response.blob();
        } else if (response.status === 404) {
          toast.warning("Something Went Wrong , Please Try Again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });

        } else {
          toast.warning("Something Went Wrong , Please Try Again",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = InvestorType == false ? "Bulk Upload Template for Non-Individual Investor Type.xlsx" : "Bulk Upload Template for Individual Investor type.xlsx"
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const SaveBulkUploadInvestorData = async () => {
    setLoading(true)
    const response = await BulkUploadDataSave(BulkUploadSaveData);
    if (response?.responseCode === 200) {
      setLoading(false)
      toast.success("Data Saved Successfully",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      getInvestors();
      //setInvestorsRowsData(null)
      setappendRowOnBulkupload([])
    } else {
      setLoading(false)
      //setInvestorsRowsData(null)
      setappendRowOnBulkupload([])
      toast.warning(response?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }


  const getInvestors = async () => {
    const data = await getInvestorsList();
    if (data?.responseCode === 200) {
      // debugger
      const reversedData = data.responseData?.reverse();
      setInvestorsList(reversedData);
    } else {
      // Handle error if needed
    }
  }

  const getFundNames = async () => {
    const data = await getAllFunds();

    if (data?.responseCode == 200) {
      const fundNameOptions = data?.responseData?.map(fund => ({
        label: fund.fundName,
        value: fund.fundID,
      }));
      setfundsDropdown(fundNameOptions);
    } else {
      toast.warning(data?.responseData,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region InvestorList Declarables
  const Columns = [
    { field: 'userFullName', headerName: 'Name', width: 300, },
    { field: 'investorType', headerName: 'Investor Type', width: 400, },
    { field: 'userEmailAddress', headerName: 'Email Address', width: 400, },
  ]

  //#region use-effect for both dependencies & non dependencies
  useEffect(
    () => {
      const investorData = investorsList
        ? investorsList.filter(a => a.isRegistrationCompleted == true).map((user) => ({
          id: user.userId,
          userFullName: user.userFullName,
          userEmailAddress: user.userEmailAddress,
          investorType: user.investorType,
        }))
        : [];
      setInvestorsRowsData(investorData);
    }
    , [investorsList])


  useEffect(() => {
    getFundNames();
    getInvestors();
  }, []);
  
  return (
    <Grid container xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={12} md={12}>
        <div className='space-between'>
          <h4> Investors List </h4>
          <div>
            {checkFeaturePermissions(RolesAndPermissions.INVITE_INVESTORS, FeatureAccess.CREATE) && <Button
              variant="outlined"
              color="primary"
              size="medium"
              startIcon={<AddIcon />}
              onClick={openSelectPopup}>
              INVITE INVESTORS
            </Button>}
          </div>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} height={380} className='whiteCard' marginTop={2} marginBottom={3}>
          <AIPDataGrid
            columns={Columns}
            rows={InvestorsRowsData || []}
            handleRowClick={() => { }}
            onRowsSelectionHandler={() => { }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <h6 className='alignleft'> Bulk Upload </h6>
        <div className='space-between btnsGroup'>
          <div className='twobuttonsrow'>
            <div>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                startIcon={<SimCardDownloadIcon />}
                onClick={downloadTemplateModalOpen}>
                DOWNLOAD TEMPLATE
              </Button>
            </div>
            <div className='margin-left-10'>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                startIcon={<NoteAddIcon />}
                onClick={openBulkUpload}>
                BULK UPLOAD
              </Button>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className='whiteCard' marginTop={2} marginBottom={3}>
        <Grid container xs={12}>
          <Grid item xs={12} textAlign={'right'}>
            <ActionButton
              variant="contained"
              icon={<SaveIcon />}
              label={'Save Data'}
              styleProps={{ cursor: 'pointer' }}
              loading={loading}
              disabled={appendRowOnBulkupload?.length === 0 || BulkUploadStatus !== 200}
              onClick={SaveBulkUploadInvestorData} />
          </Grid>
        </Grid>
        <Grid item xs={12} height={380}>
          <AIPDataGrid
            columns={BulkUploadColumns}
            rows={appendRowOnBulkupload || []}
            handleRowClick={openBulkUploadSelectedInvestor}
            onRowsSelectionHandler={() => { }}
          />
        </Grid>
      </Grid>
      <ToastContainer />
      {downloadTemplateModal && <BulkUploadDownloadModal DownloadBulkUploadSampleDocument={DownloadBulkUploadSampleDocument} setdownloadTemplateModal={setdownloadTemplateModal} downloadTemplateModal={downloadTemplateModal} />}
      {BulkUploadSelectedInvestor && <SelectedInvestorData open={openBulkUploadSelectedInvestor} onClose={closeBulkUploadSelectedInvestor} BulkUploadSaveData={BulkUploadSaveData} />}
      {selectpopup && <ChooseInvestorType open={selectpopup} onClose={closePopup} getInvestors={getInvestors} fundsDropdown={fundsDropdown} />}
      {BulkUpload && <BulkUploadModal open={BulkUpload} onClose={closeBulkUpload} getInvestors={getInvestors} fundsDropdown={fundsDropdown} setappendRowOnBulkupload={setappendRowOnBulkupload} setBulkUploadSaveData={setBulkUploadSaveData} setBulkUploadStatus={setBulkUploadStatus} />}
    </Grid>
  )
}

export default Investors