import React from "react";
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import secureLocalStorage from "react-secure-storage";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { BoxbackgroundWhite } from "../../CommonCss";
import { useState } from "react";
import { useEffect } from "react";
import { CurrencyFormatter } from "../../../utils/Formater";
import { DataGrid } from "@mui/x-data-grid";
import styles from "../../dashboards/FundDetailDashboards/ReportingQuarterlyReport.module.css"
import AltDataGridPro from "../../common/DataGridPro/AltDataGridPro";



function QuarterlyReports() {
    const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
    const columns = [
        { field: 'header', headerName: 'NAV Reconciliation and Summary of Fees, Expenses & Incentive Allocation', width: 550 },
        { field: 'value1', headerName: 'QTD (Oct-15 - Dec-15)', width: 180 },
        { field: 'value2', headerName: 'YTD (JAN -15 - Dec-15)', width: 180 },
        { field: 'value3', headerName: 'Since Inception( Oct-15 - Dec-15)', width: 180 },
    ];

    const [data, setData] = useState(null);
    useEffect(() => {
    }, []);
    const firstrows = data
        ? data.map(rows => ({
            id: rows.quarterlyReportID,
            header: rows.qrProperty,
            value1: CurrencyFormatter(rows.qrqtdValue),
            value2: CurrencyFormatter(rows.qrytdValue),
            value3: CurrencyFormatter(rows.qrAllTimeValue),
            isMainField: rows.isMainField,

        }))
        : [];

    return (
        <div style={{ backgroundColor: rootBackColorConfig.color }} className="page">
            <div className={styles.editSaveButton}>
                <div className={styles.editSaveButtonChild} />
                <div className={styles.print}>Print</div>
                <img
                    className={styles.iconMaterialPrint}
                    alt=""
                    src="/capitalimages/icon-materialprint.svg"
                />
            </div>
            <div className={styles.editSaveButton1}>
                <div className={styles.editSaveButtonChild} />
                <div className={styles.download}>Download</div>
                <img
                    className={styles.iconMaterialFileDownload}
                    alt=""
                    src="/capitalimages/icon-materialfiledownload.svg"
                />
            </div>
            <img
                className={styles.moreVertFill0Wght400Grad0Icon}
                alt=""
                src="/capitalimages/more-vert-fill0-wght400-grad0-opsz48.svg"
            />
            <PageHeader
                template={HeaderTemplates.Standard}
                fundName={secureLocalStorage.getItem("FundName")}
                headerImage={secureLocalStorage.getItem("selectedFund")?.IconImage}
                customBackgroundImage={secureLocalStorage.getItem("selectedFund")?.BGImage ? secureLocalStorage.getItem("selectedFund")?.BGImage : '/DefaultBackgroundImage.png'}

            />
            
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid item container xs={12} marginTop={'80px'} width={'95%'} marginLeft={'30px'} spacing={2} sx={BoxbackgroundWhite}>
                        <Grid itm xs={12}>
                            <AltDataGridPro
                                rows={firstrows}
                                columns={columns.map(column => ({
                                    ...column,
                                    headerAlign: 'center',
                                    headerClassName: params =>
                                        params.field === 'header' && params.rows?.isMainField ? 'header-cell-spaced' : '',
                                }))}
                                disableColumnMenu
                                autoHeight
                                // rowHeight={rowHeight}
                                pagination
                                pageSize={firstrows.length}
                                components={{
                                    Footer: () => null,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Box>

        </div>
    )
}

export default QuarterlyReports