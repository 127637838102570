import { useEffect, useState, useRef } from "react";
import ActionButton from "../../../common/ActionButton/ActionButton";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import '../../../marketplace/components/CreateFundModal/styles.css';
import Configurations from "../../../../Configurations";
import StepModal from "../../../common/Modal/StepModal";
import secureLocalStorage from "react-secure-storage";
import NotifyTitle from "./Title";
import NotifyVDR from "./NotifyVDR";
import NotifyPortifolio from "./NotifyPortifolio";
import { NotifyPortfolioInitialData } from "./APIDataTemplate";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { GetCompanyFitlerDetails } from "../../Services/CompaniesDetails";
import { AppDashboards, AppRoles } from "../../../../utils/enum";

const NotifyPopupModel = ({ handleCancelModel, setNotifyPopupopen, newUuid }) => {

    const [step, setStep] = useState(1);
    const livePage = useRef(step);
    livePage.current = step;
    const [open, setModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [vdrInfo, setvdrInfo] = useState(null);
    const isFundManager = secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD;
    
    
    const handleModalNavigation = (isPrev) => {
        setStep(currStep => (
            !(isPrev && (currStep === 1)) && 
            !(!isPrev && (currStep === 2))
        ) ? (isPrev ? currStep - 1 : currStep + 1) : currStep);

        if(livePage.current == 1){
          if(!vdrInfo){  
            fetch(`${Configurations.apiBaseUri}/v1/VirtualDataRooms/CreateDefaultVDR?fundID=${secureLocalStorage.getItem("FundId")}&subscriptionID=0&portfolioID=0&PortfolioGUID=${newUuid}&isFundManager=${isFundManager}&EntityName=${NotifyData?.companyname}` ,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => response.json())
            .then((responseData) => {
              setvdrInfo(responseData);
             console.log('vdrinfo',responseData)
            })
            .catch((error) => {
              // setError(error);
            });
          }
        
        }
    }
    const [FiltersData, setFiltersData] = useState([]);

    const getFiltersData = async () => {
        const data = await GetCompanyFitlerDetails();
        if (data.responseCode === 200) {
            console.log(data.responseData);
            setFiltersData(data?.data);
        } else {
            console.log("data.responseData");
        }
    }
    useEffect(() => {
      getFiltersData();
  }, []);
    
    const [NotifyData,setNotifyData] = useState(NotifyPortfolioInitialData);

    const renderFundFormByStep = () => {
    switch (step) {
        case 1:
            return <NotifyPortifolio data={NotifyData} setData={setNotifyData}  FiltersData={FiltersData} />;

        case 2:
            return <NotifyVDR newUuid={newUuid} vdrInfo={vdrInfo}/>;

        default:
                return <></>;
            }
        }   


    const onSubmit = () =>{
        console.log(NotifyData);

        const fundId = secureLocalStorage.getItem("FundId");
        const userID = secureLocalStorage.getItem("userId");

        const requestData = {
            "companyId": 0,
            "industryId": 1,
            "sectorId": NotifyData.sectorid,
            "companyTypeId": 1,
            "name": NotifyData.companyname,
            "isActive": true,
            "softDelete": false,
            "createdBy": 1,
            "createdDate": new Date(),
            "updatedBy": 1,
            "updatedDate": new Date(),
            "companyStatusId": 1,
            "companyName": NotifyData.companyname
          }


        fetch(`${Configurations.apiBaseUri}/v1/PortfolioCompany/CreatePortofolioCompanyWithBasicInfo?fundID=${fundId}&fundManagerID=${userID}&portfolioStrategyID=1&firstName=${NotifyData.firstName}&lastName=${NotifyData.lastName}&emailAddress=${NotifyData.email}&tenantGUID=${secureLocalStorage.getItem("tenantID")}&VDRGUID=${newUuid}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData),
          })
            .then(res => {
              if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
              }
              return res.json();
            })
            .then(data => {
              toast.success("Portfolio Company Created Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
              setNotifyPopupopen(false);
            })
            .catch(error => {
              toast.error("Error Creating Portfolio Company",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            });
    }

      return (
        <StepModal
            open={open}
            onPrevClick={() => handleModalNavigation(true)}
            onNextClick={() => handleModalNavigation(false)}
            currentStep={livePage.current}
            firstStep={1}>
            <DialogTitle >
                <NotifyTitle step={step} noofsteps={2} title={"Notify Portfolio Company"} onClose={() => {
                    setModalOpen(false);
                    handleCancelModel(false);
                }} />
            </DialogTitle>

                <DialogContent >
                    {renderFundFormByStep()}
                </DialogContent>

            <DialogActions>
                {step === 2 && <div className="fundActionButtonWrapper">
                    <ActionButton
                        label="SEND"
                        loading={loading}
                        /* startIconName="SaveOutlined" */
                        onClick={onSubmit}
                        styleProps={{
                            padding: '8px 56px'
                        }} />
                </div>}
            </DialogActions>
            <ToastContainer />
        </StepModal>
      );
}
export default NotifyPopupModel;