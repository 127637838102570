//## Importing whatever we need in this component

import React, { useCallback } from 'react'
import { DialogContent, Grid, TextField } from '@mui/material'
import TextInput from '../common/TextInput/TextInput';
import AIPDataGrid from '../common/DataGrid/AIPDataGrid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatAsUSACurrency } from '../common/CurrencyFormater/formatAsUSACurrency';
import FirstComponent from '../common/DatePicker/DatePicker';
import ActionButton from '../common/ActionButton/ActionButton';
import { checkFeaturePermissions } from '../../utils/common';
import { RolesAndPermissions } from '../../utils/enum';
import { FeatureAccess } from '../../utils/enum';
import { useNavigate } from "react-router-dom";
import GridCurrencyFormater from '../common/CurrencyFormater/GridCurrencyFormater';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteCapitalCall } from './Services/CapitalCall';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SendIcon from '@mui/icons-material/Send';

function CapitalCall(props) {

  //##Variables & Click events , Input change events
  const initiallySelectedRows = props?.ccgridrows?.filter((row) => row?.ccOwnership > 0)?.map((row) => row?.id);
  const ActiveStyle = props?.onDraftStatusClick?.Status === 'Active'
  const isFundMatured = secureLocalStorage.getItem("selectedFund")?.FundMaturity;;
  const isFundTerminated = secureLocalStorage.getItem("selectedFund")?.FundTermination;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleNumericInput = (fieldName, inputValue) => {
    const numericValue = inputValue?.replace(/[^0-9]/g, '');

    if (fieldName === "totalCCcalled") {
      if (numericValue === "") {
        props.setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: "This field is required",
        }));
      } else if (numericValue <= 0) {
        props.setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: "Capital called must be greater than zero",
        }));
      }
      else {
        const remainingCapital = props?.totalCommittedCapital - (parseInt(numericValue, 10) + parseInt(props?.formData?.fundsRecieved));

        if (remainingCapital < 0) {
          props.setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: "Total Capital Remaining should not be negative",
          }));
        } else {
          props.setErrors(prevErrors => ({
            ...prevErrors,
            [fieldName]: null,
          }));
        }
      }
    }

    props?.handleInputChange(fieldName, numericValue);
  };

  //## Service Call
  const handleDeleteConfirmed = async () => {
    const data = await DeleteCapitalCall(props.rowccnumber)
    if (data?.responseCode === 200) {
      closeDialog();
      toast.warning("Capital Call has been Deleted", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
      props.SetAddingCC(false)
      props.fetchData()
    } else {
      toast.warning(data?.responseData, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
    }
  }

  //##Grid Data Columns / rows Data Binding
  const ccInvestorcolumns = [

    {

      "id": 1,
      "field": "investorName",
      "headerName": "INVESTOR NAME",
      "width": 150
    },

    {
      "id": 3,
      "field": "investorType",
      "headerName": "INVESTOR TYPE",
      "width": 110

    },

    {
      "id": 2,
      "field": "capitalCallPaymentStatus",
      "headerName": "STATUS",
      "width": 110

    },



    {
      "id": 4,
      "field": "callAmount",
      "headerName": "CALL AMOUNT",
      "width": 110,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }
    },
    {
      "id": 5,
      "field": "subscribed",
      "headerName": "SUBSCRIBED",
      "width": 110,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {
      "id": 6,
      "field": "contributedAmount",
      "headerName": "CONTRIBUTED",
      "width": 110,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {
      "id": 7,
      "field": "unfundedAmount",
      "headerName": "UNFUNDED",
      "width": 110,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },

    {
      "id": 8,
      "field": "ccOwnership",
      "headerName": "OWNERSHIP",
      "width": 110,

      renderCell: (params) => {
        return (
          <div>
            {params?.row?.ccOwnership}%
          </div>
        );
      }

    },
    {
      "id": 9,
      "field": "ccReceivedAmount",
      "headerName": "RECEIVED",
      "width": 110,

      renderCell: (params) => {
        return <GridCurrencyFormater params={params} />
      }

    },
    {
      "id": 10,
      "field": "dateReceived",
      "headerName": "DATE RECEIVED",
      "width": 110,

    }
  ];

  return (
    <div>
      {
        props.formRefresh && <div className='m-lr'>
          <Grid container xs={12} sm={12} md={12} className='whiteCard'>
            <div className='height-750-scroll'>
              <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                <Grid item>
                  <div className={!props?.isEditing && ActiveStyle ? 'Capital-call-Active-Draft Capital-call-Active-Border' : 'Capital-Call-Draft Capital-call-Active-Draft'}>
                    <div className={!props?.isEditing && ActiveStyle ? 'Capital-call-Active-text' : 'Capital-call-active-text'}>
                      {props?.CapitalcallDetails?.capitalCallStatus}
                    </div>
                  </div>

                </Grid>
                <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                  <Grid item style={{ marginLeft: '30px', marginRight: '2px' }}>
                    {
                      !isFundMatured && !isFundTerminated && props?.CapitalcallDetails?.ccStatusID !== 3 && props?.CapitalcallDetails?.ccStatusID === 2 ?

                        <Button onClick={openDialog} variant='outlined' className='btn-primary mr1'>
                          <DeleteIcon />
                          Delete
                        </Button>
                        : null}
                    <Dialog open={isDialogOpen} onClose={closeDialog}>
                      <DialogTitle>
                        <h6><InfoIcon className='info-i' /> Confirmation</h6>
                      </DialogTitle>

                      <p className='capitalcallDeletePop'>  Are you sure you want to delete this Capital Call? </p>

                      <DialogActions>
                        <ActionButton variant='outlined' label='No' onClick={closeDialog} icon={<RotateLeftIcon />} />
                        <ActionButton label='Yes' onClick={handleDeleteConfirmed} icon={<CheckCircleIcon />} />
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  <Grid item>
                    {
                      !isFundMatured && !isFundTerminated && checkFeaturePermissions(RolesAndPermissions.CAPITAL_CALL, FeatureAccess.UPDATE) && props?.CapitalcallDetails?.ccStatusID !== 3 ?

                        <div className='btnsGroup'>

                          {props?.showSave &&
                            (
                              <div className='child-row-margin-10'>
                                <div>
                                  <Button
                                    id='save'
                                    onClick={() => props?.handleSaveClick(false)}
                                    variant='contained'
                                    className='btn-primary'
                                    disabled={props.savebtnDisabled || parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.totalCCcalled) + parseInt(props?.formData?.fundsRecieved)) < 0}>
                                    <SaveIcon />
                                    Save
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    id='saveandexeccute'
                                    onClick={() => props?.handleSaveClick(true)}
                                    variant='contained'
                                    className='btn-primary'
                                    disabled={props.savebtnDisabled || parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.totalCCcalled) + parseInt(props?.formData?.fundsRecieved)) < 0}>
                                    <SendIcon />
                                    Save & Execute
                                  </Button>
                                </div>
                              </div>
                            )
                          }
                          {props?.showEdit &&
                            (
                              <Button id="edit" onClick={props?.handleEditClick} variant='contained' className='btn-primary'>
                                <EditIcon />
                                Edit
                              </Button>
                            )
                          }
                        </div>
                        : null
                    }
                  </Grid>
                </Grid>
              </Grid>


              <Grid container xs={12} className='capitalPricesRow'>
                <Grid className='pricebox'
                  item
                  lg={3.8} md={3.8} sm={6} xs={12}
                  sx={{
                    border: (props?.errors?.targetRaise ? '1px solid red' : '1px solid #C0CDD9'),
                    margin: '5px'
                  }}
                >
                  <div className={props?.showEdit ? 'disablediv' : 'regulardiv'} sx={{ border: 'red' }}>
                    <Grid>
                      <span className="RequiredSymbol ValueName"> Target Raise</span>
                    </Grid>
                    <Grid className='valueInput'>
                      <input
                        type="text"
                        className="border-0 w-100 disablediv "
                        style={{ border: 'red' }}
                        name="targetRaise"
                        placeholder="targetRaise"
                        value={formatAsUSACurrency(props?.formData?.targetRaise)}
                        disabled={props?.showEdit}
                        onChange={(event) => handleNumericInput('targetRaise', event?.target?.value)}
                      />
                    </Grid>
                  </div>
                </Grid>
                <Grid className='pricebox'
                  item
                  lg={3.8} md={3.8} sm={6} xs={12}
                  sx={{
                    border: '1px solid #C0CDD9',
                    margin: '5px'
                  }}
                >
                  <div>
                    <Grid>
                      <span className="RequiredSymbol ValueName">  Total Committed Capital</span>
                    </Grid>
                    <Grid className='valueInput'>
                      {formatAsUSACurrency(props?.totalCommittedCapital)}
                    </Grid>
                  </div>
                </Grid>
                <Grid className='pricebox'
                  item
                  lg={3.8} md={3.8} sm={6} xs={12}
                  sx={{
                    border: (props?.errors?.totalCCcalled ? '1px solid red' : '1px solid #C0CDD9'),
                    margin: '5px'
                  }}
                >
                  <div>
                    <Grid>
                      <span className="RequiredSymbol ValueName">Capital Called</span>
                    </Grid>
                    <Grid className='valueInput'>
                      <input
                        type="text"
                        className="border-0 w-100"
                        name="totalCCcalled"
                        placeholder="TotalCCcalled"
                        value={formatAsUSACurrency(props?.formData?.totalCCcalled)}
                        disabled={props?.showEdit}
                        onChange={(event) => handleNumericInput('totalCCcalled', event?.target?.value)}
                      />
                    </Grid>
                  </div>
                  <div className='error-message'>{props?.errors?.totalCCcalled}</div>
                </Grid>
              </Grid>
              <Grid container xs={12} className='capitalPricesRow'>
                <Grid className='pricebox'
                  item
                  lg={3.8} md={3.8} sm={6} xs={12}
                  sx={{
                    border: (props?.errors?.fundsRecieved ? '1px solid red' : '1px solid #C0CDD9'),
                    margin: '5px'
                  }}
                >
                  <div>
                    <Grid>
                      <span className='ValueName'>Total Funds Received</span>
                    </Grid>
                    <Grid className='valueInput'>
                      <input
                        type="text"
                        className="border-0 w-100 disablediv "
                        //name="totalCCcalled"
                        //placeholder="TotalCCcalled"
                        value={formatAsUSACurrency(props?.formData?.fundsRecieved)}
                        disabled={props?.showEdit}
                      // onChange={(event) => handleNumericInput('totalCCcalled', event?.target?.value)}
                      />
                    </Grid>
                  </div>
                </Grid>
                <Grid className='pricebox'
                  item
                  lg={3.8} md={3.8} sm={6} xs={12}
                  sx={{
                    border: '1px solid #C0CDD9',
                    margin: '5px'
                  }}
                >
                  <div>
                    <Grid>
                      <span className='ValueName'>
                        Total Capital Remaining
                      </span>
                    </Grid>
                    <Grid>
                      <div className='valueInput'>
                        {props?.CapitalcallDetails?.capitalCallStatus === "Executed"
                          ? isNaN(parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.fundsRecieved)))
                            ? 0 :
                            formatAsUSACurrency(
                              parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.fundsRecieved))
                            )
                          : isNaN(parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.totalCCcalled) + parseInt(props?.formData?.fundsRecieved)))
                            ? 0 :
                            formatAsUSACurrency(
                              parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.totalCCcalled) + parseInt(props?.formData?.fundsRecieved))
                            )}
                      </div>
                    </Grid>
                  </div>
                </Grid>
                <Grid className='pricebox'
                  item
                  lg={3.8} md={3.8} sm={6} xs={12}
                  sx={{
                    border: '1px solid #C0CDD9',
                    margin: '5px'
                  }}
                >
                  <div>
                    <Grid>
                      <span className='ValueName'>
                        Investors
                      </span>
                    </Grid>
                    <Grid >
                      <div className='valueInput'>
                        {initiallySelectedRows?.length ?? 0}
                      </div>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <div className={props?.showEdit || parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.totalCCcalled) + parseInt(props?.formData?.fundsRecieved)) < 0 ? "disablediv" : "regularDiv"}>
                <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px', marginTop: '20px' }}>
                    <Grid xs={3}>
                      <div className='space-between'>
                        <FirstComponent
                          fieldLabel={<span className="RequiredSymbol">Capital Call Date</span>}
                          fieldName="CCDate"
                          isSaveEnabled={props?.showSave}
                          defaultValue={props?.formData?.CCDate}
                          variant='standard'
                          errorMessage={props?.errors?.CCDate || undefined}
                          onhandleChange={(fieldName, value) => props?.handleInputChange(fieldName, value)} fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid xs={1}>
                    </Grid>
                    <Grid xs={3}>
                      <div className='space-between'>
                        <TextField
                          fullWidth
                          variant='standard'
                          disabled={true}
                          label="Capital Call Number/ID"
                          name="capitalCallNumber"
                          value={props?.formData?.capitalCallNumber}
                          onChange={(ev) => props?.handleInputChange(ev.target.name, ev.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid xs={1}>
                    </Grid>
                    <Grid xs={3}>

                      <TextInput
                        type={"select"}
                        label={<span className="RequiredSymbol">Currency</span>}
                        name="currency"
                        defaultValue={(props?.formData?.currency === '') ? "USD" : (props?.formData?.currency)}
                        options={[
                          { label: 'USD', value: 'USD' }]}
                        onChange={props?.handleInputChange}
                      /*  errormessage={props?.errors?.currency || undefined} */
                      />
                    </Grid>
                    <Grid xs={1}>
                    </Grid>
                  </Grid>
                  <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px', marginTop: '40px' }}>
                    <Grid xs={3}>
                      <div className='space-between'>
                        <TextField
                          fullWidth
                          variant='standard'
                          label={<span className="RequiredSymbol">Purpose of Call</span>}
                          name="purposeOfCall"
                          value={props?.formData?.purposeOfCall}
                          onChange={(ev) => props?.handleInputChange(ev.target.name, ev.target.value)}
                          helperText={props?.errors?.purposeOfCall || undefined}
                          error={props?.errors?.purposeOfCall || undefined}
                          errormessage={props?.errors?.purposeOfCall || undefined}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid xs={1}>
                    </Grid>
                    <Grid xs={3}>
                      <div className='space-between'>

                        <FirstComponent
                          fieldLabel={<span className="RequiredSymbol">Payment Due Date</span>}
                          fieldName="paymentDueDate"
                          isSaveEnabled={props?.showSave}
                          defaultValue={props?.formData?.paymentDueDate}
                          variant='standard'
                          errorMessage={props?.errors?.paymentDueDate || undefined}
                          onhandleChange={(fieldName, value) => props?.handleInputChange(fieldName, value)} fullWidth
                        />
                      </div>
                    </Grid>
                    <Grid xs={1}>
                    </Grid>
                    <Grid xs={3}>
                      <div className='space-between disablediv'>
                        <TextField
                          fullWidth
                          type="text"
                          variant='standard'
                          label={<span className="RequiredSymbol">Status of Capital Call</span>}
                          name="statusOfCapitalCall"
                          tabIndex={1000}
                          value={props?.CapitalcallDetails?.capitalCallStatus}
                          editable={false}
                          onChange={(ev) => props?.handleInputChange(ev.target.name, ev.target.value)}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid xs={1}>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Grid xs={12} style={{ marginTop: '30px', marginLeft: '10px' }}>
                <div className={props?.showEdit || parseInt(props?.totalCommittedCapital) - (parseInt(props?.formData?.totalCCcalled) + parseInt(props?.formData?.fundsRecieved)) < 0 ? "disablediv" : "regularDiv"}>
                  <AIPDataGrid
                    rows={props?.ccgridrows?.filter((row) => row?.subscribed > 0) ?? []}
                    columns={ccInvestorcolumns}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                    isRowSelectable={(params) => ((params.row.contributedAmount - params.row.subscribed) < 0)}
                    onRowsSelectionHandler={props?.onRowsSelectionHandler}
                    initiallySelectedRows={initiallySelectedRows} />
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      }

    </div>

  )
}
export default CapitalCall