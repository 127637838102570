import React from 'react'
import propTypes from 'prop-types';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { FieldVariants } from '../TextInput/appInputenum';

const SelectField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    options,
    variant,
    onBlur,
    required,
    disabled,
    fullWidth,
    error,
    errorMessage,
    helperText,
    readOnly
  } = props;

  //#region change events
  const handleChange = (ev) => {
    const { name, value } = ev.target;
    onChange(name, value)
  }

  return (
    <FormControl variant={variant} sx={{ maxWidth: '100%' }} fullWidth>
      <InputLabel
        id={name}
        sx={variant === FieldVariants.OUTLINED && value === "" ? { marginTop: '-8px' } : {}}
        {...(variant !== FieldVariants.OUTLINED && { shrink: true, focused: !disabled })}
        error={error}
        required={required}>
        {label}
      </InputLabel>
      <Select
        id={name}
        labelId={name}
        name={name}
        value={options?.some(option => option.value === value) ? value : ''}
        onChange={handleChange}
        error={error}
        sx={{ lineHeight: '1.5em !important' }}
        size={variant === FieldVariants.OUTLINED ? 'small' : ''}
        label={label}
        onBlur={!readOnly && onBlur}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              maxWidth: 300, // Set a max width to control dropdown width (adjust as needed)
              overflowY: 'auto', // Enable vertical scrolling for overflowed content
            },
          },
        }}
        inputProps={{ readOnly: readOnly }}>
        {options?.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            disabled={item?.disabled}>
            <ListItemText
              primary={item.label}
              sx={{
                whiteSpace: 'normal',  // Wrap text within the item
                wordBreak: 'break-word', // Break words that are too long
              }}
            />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>
        {error ? errorMessage :
          disabled && helperText ? helperText : ''}
      </FormHelperText>
    </FormControl>
  )
}

SelectField.propTypes = {
  type: propTypes.string,
  onChange: propTypes.func.isRequired,
  variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
  required: propTypes.bool,
  value: propTypes.string,
  placeholder: propTypes.string,
  label: propTypes.string,
  disabled: propTypes.bool,
  options: propTypes.array,
  name: propTypes.string,
  size: propTypes.string,
  errorMessage: propTypes.string,
  helperText: propTypes.string,
  readOnly: propTypes.bool
};

SelectField.defaultProps = {
  type: 'select',
  variant: 'standard',
  size: 'small',
  required: false,
  value: '',
  placeholder: '',
  label: '',
  options: [],
  disabled: false,
  name: '',
  errorMessage: '',
  helperText: '',
  readOnly: false
};

export default SelectField;