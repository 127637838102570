export const validationsConstants = {
  ONLY_ALPHABETS: 'This Field Accepts Only Alphabets',
  ONLY_NUMBERS: 'Only Numbers allowed',
  INVALID_FORMAT: "Please enter valid format",
  US_PHONE_NUMBER: 'Please enter valid Phone Number',
  MIN_CHARACTERS: 'Please provide a brief description (minimum 50 characters).',
  MAX_CHARACTERS: 'Exceeded Minimum Characters',
  BETWEEN_CHARACTERS: 'Characters should be between 5 to 10',
  PERCENTAGE: 'Please enter valid Percentage',
  EMAIL_ADDRESS: 'please enter valid email address',
  REQUIRED: 'This Field is required',
  URL: 'Please enter valid url',
  AGE_VALIDATION: 'The Age should be Above 18',
  ONLY_PDF_FILE: 'Please upload a PDF file',
  ONLY_CSV_FILE: 'Please upload a csv or excel file',
  NON_NEGATIVE: 'value should be greater than 0',
  GREATER_THAN_ZERO: 'Value must be greater than zero',
  TIN: 'Invalid Tax Identification Number(TIN)',
  PASSWORD_MATCH: "The passwords you entered don't match. Please try again.",
  ACCOUNT_NUMBER: "Please enter valid account number",
  FUTURE_DATE: "Date cannot be in the future.",
  DOB: "Please select a valid Date of Birth",
  DateError: "Please enter a valid input",
  PASSWORD: <ul>
    <li>Password must be 12-16 characters long.</li>
    <li>
      Must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.
      (! @ # $ % ^ &amp; * ( ) - _ = + &#123; &#125; [ ] | \ : ; " ' &lt; &gt; , . ? /)
    </li>
    <li>Password must not begin or end with whitespace.</li>
  </ul>,
  USER_NAME: <ul>
    <li>Username must be at least 6 characters long.</li>
    <li>Username cannot exceed 35 characters.</li>
    <li>Username can only contain letters, numbers and one underscore (_)</li>
    <li>Username cannot contain more than 5 consecutive identical numbers</li>
    <li>Username Should contain at least one alphabet.</li>
    <li>Username Should not contain any Reserved or Foul Words</li>
  </ul>,
}

export const subscriptionValidationMessages = {
  COMMITED_CAPITAL_MINIMUM: 'The Commited Capital Must be greater than Minimum Investment Amount',
  COMMITED_CAPITAL_MAXIMUM: 'The Commited Capital Must be less than Target Raise',
}

export const subscriptioToastMessages = {
  SUBSCRIPTION_SUCESSFUL: 'Fund Subscribed Successfully',
  SUBSCRIPTION_FAILED: 'unable to subscribe the fund',
  INVESTOR_SIGN_FAILED: 'unable to navigate to e-sign document',
  STEPS_LOADING_FAILED: 'unable to load the subscription steps data',
  VALIDATION_CHECK: 'please verify mandatory fields',
  STOP_SUBSCRIPTION: 'You cannot proceed with Subscription as you have chosen this option',
  SAVE_CANCEL_WARNING: 'please save or cancel the data',
  STEP_DETAILS_SUCCESS: 'Step details updated successfully',
  STEP_DETAILS_FAILED: 'unable to update the step data'
}

export const userProfileToastMessages = {
  INFORMATION_SUCESSFUL: 'Details Updated Successfully',
  INFORMATION_FAILED: 'error updating details,please try again.',
  ATTEMPTS_REACHED: "You have exceeded your attempts. You can retry changing your email address after 24 hours.",
  EXCISTING_EMIAL: "Email already exists",
  OLD_EMAIL_CANNOT_USE: "This email address is temporarily unavailable as it was recently associated with another account. Please use a different email or try again after 60 days.",
  OTP_ERROR: "The OTP entered is not correct.",
  PROFILE_IMG_ERROR: "Unable to Upload Profile Icon",
  VERIFICATION_SUCCESS: "Status updated successfully",
  VERIFICATION_FAILED: "Unable to update the status",
  KYC_SUCCESSFUL: "Details updated successfully",
  KYC_FAILED: "Unable to update the details",
  PROFILE_COMPLETE_WARNING: "Your profile is incomplete. Please fill in all mandatory fields to proceed and unlock full access to the platform",
}

export const InvestorReportingToastMessages = {
  UPLOAD_DOCUMENT: "Please upload the doucument",
}

export const VirtualDataRoomToastMessages = {
  NOT_FOUND: "No Data Found",
  ACTION_FAILED: 'unable to perform the action',
  FILE_SUCCESS_DOWNLOAD: 'The file has been downloaded successfully.',
  FILE_ALREADY_EXISTS: 'You have selected the node which has the same file you are trying to upload',
  SHARING_FILE_ALREADY_EXISTS: 'You have selected the node which has the same file you are trying to',
  FOLDER_ALREADY_EXISTS: 'Folder name already exists. Please choose a unique name.',
  DOCUMENTTYPES_NOT_FOUND: 'Document types not found',
  NO_USERS_FOUND_TO_SHARE_FOLDER: 'No users found to share',
  FILE_DELETE_SUCCESS: "The file has been deleted successfully",
  FILE_DELETE_FAILED: "unable to delete the file",
  FILE_UPLOADED_SUCCESSFULLY: 'The file has been uploaded successfully',
  FOLDER_SUCCESSFULLY_ADDED: 'The folder has been added successfully',
  FOLDER_DELETED_SUCCESSFULLY: 'The folder has been deleted successfully',
  FILE_RENAMED_SUCCESS: 'The file has been renamed successfully',
  FOLDER_RENAMED_SUCCESS: 'The folder has been renamed successfully',
  FILE_COPY_SUCCESS: 'The file has been copied successfully',
  FILE_MOVED_SUCCESS: 'The file has been moved successfully'
}

export const InvestorCommunicarionToastMessages = {
  NOTIFICATION_SENT: 'Notification sent successfully to selected recipients',
  NOTIFICATION_DRAFT: 'Notification saved to drafts successfully',
  ACTION_FAILED: "Something went wrong,Please Try Again After Sometime",
  NOTIFICATIONS_NOT_FOUND: "No notifications found!",
  NOTIFICATION_DELETED: "Notification deleted successfully!",
  GROUP_DELETED: 'Group Delete Sucessfully',
  GROUP_DELETE_FAILED: 'Unable to Delete the Group'
}

//#region administration tab messages
export const whiteLabellingToastMessages = {
  CONFIG_APPLIED: 'Your configuration has been reset successfully. Please perform a hard refresh to apply the changes.',
  CONFIG_ERROR: 'Something went wrong,please try again'
}

export const changePasswordToastMessages = {
  ENTER_VALID_PW: "Please Enter Valid Password",
  PW_UPDATED: "Password Updated Successfully",
  UNABLE_TO_UPDATE: "Unable to Update Password",
  PWS_DO_NOT_MATCHED: "Password and confirm password doesn't match"
}

export const userTabToastMessages = {
  EMAIL_SENT: "Email sent successfully",
  EMAIL_SENT_FAILED: "Email sent failed"
}

export const rolesAndfeatureKYCTabToastMessages = {
  DATE_FETCHING_ERROR: 'Something went wrong,please try again'
}

export const roleFeaturesTabToastMessages = {
  RESET_FEATURES_SUCCESS: "All role features have been successfully reset",
  RESET_FAILED: "Unable to reset role features. please try again"
}



