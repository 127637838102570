import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid, Input, TextField, Divider, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { questionPatterns, stepsToShowWhile, subscriptionValidateFields } from '../services/apiDataTemplate';
import ALTTextField from '../../common/input-fields/ALTTextField';
import SelectField from '../../common/input-fields/SelectField';
import DateField from '../../common/input-fields/DateField';
import ProgressBar from '../../common/ProgressBar/ProgressBar';
import RadioGroupField from '../../common/input-fields/RadioGroupField';
import FileUploadBtn from '../../common/FileUploadButton/FileUploadBtn';
import { Link } from 'react-router-dom';
import CheckBoxGroupField from '../../common/input-fields/CheckBoxGroupField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { saveSubscriptionAnswers } from '../services/services';
import CrudDataGrid from '../components/CrudDataGrid';
import { convertArrayToString, convertStringToArray, convertStringToArrayWithEmptyArray, trimAndLowercaseArray } from '../../common/Functions/ConvertStringtoArray';
import { VirtualDataRoomToastMessages, subscriptioToastMessages, subscriptionValidationMessages, validationsConstants } from '../../../utils/AppConstants';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';
import DownloadIcon from '@mui/icons-material/Download';
import { questionsTypesEnum } from '../../user-profiles/services/apiDataTemplate';
import { dateValidationTypeValues, textFormatTypeEnum } from '../../common/TextInput/appInputenum';
import CustomValidationSchema from '../../common/input-fields/utlis/CustomValidationSchema';
import { ConvertToUSCurrency } from '../../common/Functions/CommonConvertions';
import PreviewFilePopup from './PreviewFilePopup';
import FileUploadField from '../../common/input-fields/FileUploadField';
import { AppDashboards } from '../../../utils/enum';
import secureLocalStorage from 'react-secure-storage';
import { axiosApiCallForFiles } from '../../Services/axiosApiCallForFiles';
import { GetDocumentTypes } from '../../common/VirtualDataRoom/Services/VDRServices';

//#This popup is to render all the subscription steps
const StepPopup = (props) => {

    //#region props
    const {
        open,
        onClose,
        type,
        steps,
        currStepDetails,
        setCurrStepDetails,
        disableStepsFrom,
        questionsDetails,
        setQuestionsDetails,
        subscriptionId,
        setSbtLoading,
        investorId, getQuestionAnswerDetails, investorSubscriptionDetails, fundDeatils, setValidationErrors } = props;

    //#region variables
    const [loading, setLoading] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [disableNextButton, setDisableNextButton] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);

    //#region functions
    const showThisQuestion = (crntQuestion) => {

        if (disableNextButton === false && (crntQuestion.questionAnswerID === crntQuestion.stopIfAnswerIDIs
            || crntQuestion.multiChoiceQuestionAnswerIDs?.includes(crntQuestion.stopIfAnswerIDIs))) {
            setDisableNextButton(true);
        }

        if (crntQuestion?.parentQuestionID === 0) {
            return true;
        }

        var parentQuestion = questionsDetails.find(u => u.questionBankID === crntQuestion.parentQuestionID);

        if (parentQuestion && crntQuestion.checkParentAnswer) {
            const answersArray = convertStringToArrayWithEmptyArray(crntQuestion?.showIfParentAnswerIDIsIn)

            if (answersArray?.length > 0) {
                return (
                    answersArray.some(answer => parentQuestion.multiChoiceQuestionAnswerIDs?.includes(answer)) ||
                    answersArray.includes(parentQuestion.questionAnswerID)
                );
            }
            else {
                const parentAnswerArray = convertStringToArrayWithEmptyArray(parentQuestion.userProvidedAnswer.trim().toLowerCase())
                const childAnswerArray = convertStringToArrayWithEmptyArray(crntQuestion.showIfParentAnswerIs.trim().toLowerCase());

                const trimmedParentAnswerArray = trimAndLowercaseArray(parentAnswerArray);
                const trimmedChildAnswerArray = trimAndLowercaseArray(childAnswerArray);

                const result = trimmedChildAnswerArray?.includes(trimmedParentAnswerArray[0]);
                return result
            }

        }
        return false;
    }

    const checkIsChildQuestions = (question, selectedAnswer) => {
        const selectedOption = question.questionAnswer?.find((item) => item.questionAnswerID === selectedAnswer)
        if (selectedOption) {
            if (selectedOption.childID > 0) {
                const optionQuestionId = subscriptionDetails.values
                    ?.find(question => question.questionBankID === selectedOption.childID);
                return optionQuestionId ? true : false;
            }
        }
        return false;
    }

    const removeExistingChildQuestionAnswers = (questionIndex, value) => {
        const selectedOption = subscriptionDetails.values[questionIndex]?.questionAnswer
            ?.find((item) => item.questionAnswerID === value)
        if (selectedOption && selectedOption?.childID > 0) {
            const allChildQuestionIds = subscriptionDetails.values[questionIndex]?.questionAnswer
                ?.filter((item) => item.childID > 0)
                ?.filter((item) => item.childID !== selectedOption.childID)
                ?.map((item) => item.childID)
            const allchildQuestions = subscriptionDetails.values
                ?.filter((item) => allChildQuestionIds.includes(item.questionBankID));

            // Check if allchildQuestions is defined and not empty
            if (allchildQuestions && allchildQuestions?.length > 0) {
                for (const question of allchildQuestions) {
                    const findQuestionIndex = subscriptionDetails.values.findIndex((item) => item.questionBankID === question.questionBankID);
                    subscriptionDetails.setFieldValue(`${findQuestionIndex}.questionAnswerID`, 0);
                    subscriptionDetails.setFieldValue(`${findQuestionIndex}.userProvidedAnswer`, '');
                    subscriptionDetails.setFieldValue(`${findQuestionIndex}.multiChoiceQuestionAnswerIDs`, []);
                }
            }
        }
        return;
    }

    //Need to convert the json in to array
    const fetchGridInitialData = (value) => {
        if (value !== "" & !(Array.isArray(value))) {
            return JSON.parse(value !== "" ? value : "") || []
        }
        else if (Array.isArray(value)) {
            return value
        }
        else {
            return []
        }
    }

    //#region change events
    const handleFileUpload = (name, file) => {
        if (file) {
            const formData = new FormData();
            formData.append(name, file, file.name);
            handleTextChange(name, file);
        }
        else {
            handleTextChange(name, '');
        }
    }

    const handleTextChange = (name, value) => {
        /* const questionIndex = subscriptionDetails.values.findIndex((item) => item.questionBankID === parseInt(name)); */
        if (name !== -1) {
            subscriptionDetails.setFieldValue(
                `${name}.userProvidedAnswer`,
                value
            );
        }
    };

    const handleSelectChange = (name, value) => {
        const questionIndex = name /* subscriptionDetails.values.findIndex((item) => item.questionBankID === parseInt(name)); */
        if (questionIndex !== -1) {
            subscriptionDetails.setFieldValue(
                `${questionIndex}.questionAnswerID`,
                parseInt(value)
            );
            subscriptionDetails.setFieldValue(
                `${questionIndex}.userProvidedAnswer`,
                subscriptionDetails.values[questionIndex]
                    ?.questionAnswer?.find((item) => item?.questionAnswerID === parseInt(value))?.answer
            );
        }

        if (subscriptionDetails.values[questionIndex].stopIfAnswerIDIs == parseInt(value)) {
            toast.error(subscriptioToastMessages.STOP_SUBSCRIPTION,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        removeExistingChildQuestionAnswers(questionIndex, parseInt(value))
    };

    const handleMultiSelectChange = (name, value) => {
        const questionIndex = name; /* subscriptionDetails.values.findIndex((item) => item.questionBankID === parseInt(name)); */
        const isStopSubscription = value.includes(subscriptionDetails.values[questionIndex].stopIfAnswerIDIs);
        const isOptionSelectedPreviously = subscriptionDetails.values[questionIndex].multiChoiceQuestionAnswerIDs?.find((item) => subscriptionDetails.values[questionIndex].stopIfAnswerIDIs === item);
        if (questionIndex !== -1) {
            if (isStopSubscription) {
                if (isOptionSelectedPreviously) {
                    const removeExcisitindId = value?.filter((item) => item !== isOptionSelectedPreviously)
                    subscriptionDetails.setFieldValue(
                        `${questionIndex}.multiChoiceQuestionAnswerIDs`,
                        removeExcisitindId
                    );
                } else {
                    subscriptionDetails.setFieldValue(
                        `${questionIndex}.multiChoiceQuestionAnswerIDs`,
                        [subscriptionDetails.values[questionIndex].stopIfAnswerIDIs]
                    );
                    toast.error(subscriptioToastMessages.STOP_SUBSCRIPTION,
                        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
                }
            }
            else {
                subscriptionDetails.setFieldValue(
                    `${questionIndex}.multiChoiceQuestionAnswerIDs`,
                    value
                );
            }
        }
    };

    const handleDocumetTypeChange = (name, value) => {
        if (name !== -1) {
            subscriptionDetails.setFieldValue(
                `${name}.questionAnswerID`,
                value
            );
        }
    }

    //#region click events
    const onNextClick = () => {
        if ((type === stepsToShowWhile.USER_PROFILE_SAVE || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) && isSaveEnabled) {
            toast.warning(subscriptioToastMessages.SAVE_CANCEL_WARNING,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            return;
        }
        const findCurrIndex = steps?.findIndex((item) => item.stepID === currStepDetails?.stepID)
        setCurrStepDetails(steps[findCurrIndex + 1]);
    }

    const onPrevClick = () => {
        const findCurrIndex = steps?.findIndex((item) => item.stepID === currStepDetails?.stepID)
        if (type === stepsToShowWhile.USER_PROFILE_SAVE || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) {
            setIsSaveEnabled(false);
        }
        setCurrStepDetails(steps[findCurrIndex - 1]);
    }

    const onEditClick = () => {
        setIsSaveEnabled(true);
    }

    const onSaveClick = () => {
        const errors = createValidationSchema(questionsDetails, currStepDetails?.stepID, true)(subscriptionDetails.values);
        if (Object.keys(errors)?.length === 0) {
            postSubscriptionAnswers();
        } else {
            toast.warning(subscriptioToastMessages.VALIDATION_CHECK,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    const onCancelClick = () => {
        getQuestionAnswerDetails();
        setIsSaveEnabled(false);
        setLoading(false);
    }

    //#region api calls
    const getSubscriptionDocumentTypes = async () => {
        const data = await GetDocumentTypes("Fund");
        if (data?.responseCode === 200) {
            const filteredDocumentTypes = data.responseData
                ?.filter((item) => item.folderName === "Subscription Documents")
                ?.map((item) => ({ label: item.documentType, value: item.documentTypeID }))
            setDocumentTypes(filteredDocumentTypes);
        }
        else {
            setDocumentTypes([]);
        }
    }

    //#region api downlaod calls
    const downloadFile = async (vdrid, title) => {
        const data = await axiosApiCallForFiles(`/v1/VirtualDataRooms/DownloadVDRItem?vdrID=${vdrid}`, title)
        if (data.responseCode === 200) {
            toast.success(VirtualDataRoomToastMessages.FILE_SUCCESS_DOWNLOAD, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        } else {
            toast.warning(VirtualDataRoomToastMessages.ACTION_FAILED, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    };

    const onDownloadClick = (vdrid, title) => {
        downloadFile(vdrid, title);
    }

    //#region api post calls
    const postSubscriptionAnswers = async () => {
        setLoading(true);
        const requestedData = questionsDetails
            ?.filter((item) => item?.step === currStepDetails?.stepID)
            ?.filter((question) =>
                (question.userProvidedAnswer !== null) ||
                (question.multiChoiceQuestionAnswerIDs !== null) ||
                (question.questionAnswerID !== null))
            ?.map((item) => ({
                "UserProfileID": item?.userProfileID,
                "QuestionBankID": item?.questionBankID,
                "UserID": investorId,
                "fundID": 0,
                "SubscriptionID": (type === stepsToShowWhile.OFFLINE_SUBSCRIPTION ? subscriptionId : (item?.subscriptionID || 0)),
                "QuestionPatternTypeID": item?.questionPatternTypeID,
                "QuestionPatternType": item?.questionPatternType,
                "QuestionAnswerID": item?.questionAnswerID || 0,
                "UserProvidedAnswerinText": item.questionPatternType === questionPatterns.FILE_UPLOAD
                    ? (item?.userProvidedAnswer?.name || "")
                    : item.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION ? convertArrayToString(item?.multiChoiceQuestionAnswerIDs)
                        : item.questionPatternType === questionPatterns.GRID
                            ? (JSON.stringify(item?.userProvidedAnswer) || "")
                            : item?.userProvidedAnswer || "",
                "multiChoiceQuestionAnswerIDs": item?.multiChoiceQuestionAnswerIDs || [],
                /* "UploadedFile": item?.uploadedFile || '', */
            })) || [];

        const formData = new FormData();

        const filteredFileQuestions = questionsDetails
            ?.filter((item) => item?.step === currStepDetails?.stepID)
            ?.filter((question) => question.userProvidedAnswer !== null && question.userProvidedAnswer !== "")
            ?.filter((question) => question.questionPatternType === questionPatterns.FILE_UPLOAD);

        for (const question of filteredFileQuestions) {
            formData.append("subscriptionFiles", question?.userProvidedAnswer, `${question?.questionBankID}/${question?.userProvidedAnswer?.name}`);
        }

        if (filteredFileQuestions?.length === 0) {
            formData.append("subscriptionFiles", null)
        }

        formData.append("userProvidedAnswers", JSON.stringify(requestedData))
        formData.append("questionType", questionsTypesEnum.SUBSCRIPTION)

        const data = await saveSubscriptionAnswers(formData);
        if (data.responseCode === 200) {
            onCancelClick();
            toast.success(subscriptioToastMessages.STEP_DETAILS_SUCCESS,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else {
            onCancelClick();
            toast.error(subscriptioToastMessages.STEP_DETAILS_FAILED,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region formik validations
    const createValidationSchema = (details, currStep, isSubmitting) => {
        return (values) => {
            const errors = {};
            //#Mandatory validations
            details
                ?.filter(question => question?.step === currStep && question?.setValidations && showThisQuestion(question))
                ?.forEach(question => {
                    const currQuestionIndex = question?.questionIndex;
                    if (question?.questionPatternType === questionPatterns.TEXT_BOX) {
                        const value = details[currQuestionIndex]?.userProvidedAnswer;
                        if ((!value || value.trim() === '')) {
                            errors[currQuestionIndex] = validationsConstants.REQUIRED;
                        }
                        //Validations for commited capital
                        if (type === stepsToShowWhile.SUBSCRIBING &&
                            (question?.questionName.trim() === subscriptionValidateFields.COMMITED_CAPITAL ||
                                question?.questionName.trim() === subscriptionValidateFields.SUBSCRIPTION_AMOUNT)) {
                            if (parseInt(value) < fundDeatils?.minimumInvestmentAmount)
                                errors[currQuestionIndex] = subscriptionValidationMessages.COMMITED_CAPITAL_MINIMUM;
                        }
                        /* if (type === stepsToShowWhile.SUBSCRIBING && 
                            (question?.questionName.trim() === subscriptionValidateFields.COMMITED_CAPITAL || 
                              question?.questionName.trim() === subscriptionValidateFields.SUBSCRIPTION_AMOUNT )) {
                            if (parseInt(value) > fundDeatils?.fundSize)
                                errors[currQuestionIndex] = subscriptionValidationMessages.COMMITED_CAPITAL_MAXIMUM;
                        } */
                    }
                    else if (question?.questionPatternType === questionPatterns.DROP_DOWN) {
                        const value = details[currQuestionIndex]?.questionAnswerID;
                        if (parseInt(value) === 0) {
                            errors[currQuestionIndex] = validationsConstants.REQUIRED;
                        }
                    }
                    else if (question?.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION) {
                        const value = values[currQuestionIndex]?.multiChoiceQuestionAnswerIDs;
                        if (!value || value?.length === 0) {
                            errors[currQuestionIndex] = validationsConstants.REQUIRED;
                        }
                    }
                    // Add more custom validations as needed
                });
            //#optional validations
            /* details
                ?.filter(question => question?.step === currStep && question?.gridSchema !== "" && showThisQuestion(question))
                ?.forEach(question => {
                    const currQuestionIndex = question?.questionIndex;
                    const textValue = details[currQuestionIndex]?.userProvidedAnswer;
                    if (question?.gridSchema === textFormatTypeEnum.US_PHONE_NUMBER) {
                        const validationResult = CustomValidationSchema.phoneSchema.isValidSync(textValue);
                        if (!validationResult) {
                            errors[currQuestionIndex] = validationsConstants.INVALID_FORMAT;
                        }
                    }
             }); */
            if (isSubmitting) {
                Object.keys(errors).forEach(field => {
                    subscriptionDetails?.setFieldTouched(field, true, false);
                });
            }
            return errors;
        };
    };

    const subscriptionDetails = useFormik({
        initialValues: questionsDetails,
        validate: (type === stepsToShowWhile.USER_PROFILE_SAVE || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) /* && !isSaveEnabled  */ ? "" : createValidationSchema(questionsDetails, currStepDetails?.stepID, false),
        onSubmit: async (values) => {
            setSbtLoading(true);
        },
    });

    const handleValidationSubmit = async () => {

        if ((type === stepsToShowWhile.USER_PROFILE_SAVE || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) && !isSaveEnabled) {
            onNextClick();
            return
        }

        const errors = createValidationSchema(questionsDetails, currStepDetails?.stepID, true)(subscriptionDetails.values);

        if (Object.keys(errors)?.length === 0) {
            subscriptionDetails.handleSubmit();
        } else {
            // Optionally handle the errors or display them
        }
    };

    //#region useEffect
    useEffect(() => {
        setQuestionsDetails(subscriptionDetails.values);
    }, [subscriptionDetails.values])

    //for cancel btn -> need to change the field values
    useEffect(() => {
        //#multi choice conversion from string to array(,) seperated
        const multiChoiceArrayConversion = questionsDetails?.map((question) => {
            if (question?.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION) {
                return {
                    ...question, // retain other properties
                    multiChoiceQuestionAnswerIDs: convertStringToArray(question?.multiChoiceQuestionAnswerIDs) // convert array to string
                };
            } else {
                return question; // return the question as is
            }
        });
        const questionswithindex = multiChoiceArrayConversion?.map((question, index) => {
            return { questionIndex: index, ...question };
        });
        subscriptionDetails.setValues(questionswithindex);
        /* subscriptionDetails.setValues(questionsDetails); */
    }, [questionsDetails])

    useEffect(() => {
        if (type === stepsToShowWhile.USER_PROFILE_SAVE || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) {
            setIsSaveEnabled(false)
        }
        if (investorSubscriptionDetails?.subscriptionID === 0) {
            setIsSaveEnabled(type === stepsToShowWhile.USER_PROFILE_SAVE ? false : true)
        }
        if (investorSubscriptionDetails?.subscriptionID > 0 && type !== stepsToShowWhile.OFFLINE_SUBSCRIPTION) {
            setIsSaveEnabled(
                (investorSubscriptionDetails?.subscriptionStatusName === fundSubscriptionStatues.INVESTOR_SIGN_PENDING || investorSubscriptionDetails?.canInvestorEdit || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION)
                    ? true : false)
        }
    }, [])

    useEffect(() => {
        setValidationErrors(subscriptionDetails.errors);
    }, [subscriptionDetails.errors])

    useEffect(() => {
        getSubscriptionDocumentTypes();
    }, [])

    //#region render
    const renderChildQuestions = (questionId, isDisabled) => {

        const question = subscriptionDetails.values
            ?.find(question => question.questionBankID === questionId);
        return (
            <>
                {
                    (question?.questionPatternType === questionPatterns.RAIDO) &&
                    <div className={isSaveEnabled ? 'width-98' : 'width-98 disablediv'} key={question?.questionBankID}>
                        <RadioGroupField
                            name={question?.questionIndex}
                            label={question?.doNotDisplayQuestion ? "" : question?.questionName}
                            value={question?.questionAnswerID}
                            onChange={(name, value) => { handleSelectChange(name, value) }}
                            options={question?.questionAnswer
                                ?.filter(soption => soption.childID === 0)
                                .map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                            isBorder={question?.questionPatternType === questionPatterns.RAIDO}
                            readOnly={!isSaveEnabled}
                            disabled={isDisabled} />
                    </div>
                }
            </>
        )
    }

    //#region return
    return (
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        }}>
            <div className='subscription-step-popup'>
                <div className='prev-btn '>
                    <div className='subscription-step-btn float-left'>
                        {
                            steps?.findIndex((item) => item.stepID === currStepDetails?.stepID) === 0 ? "" :
                                <ArrowBackIcon sx={{ width: 32, height: 32, color: 'gray' }} onClick={onPrevClick} />
                        }
                    </div>
                </div>
                <div className='subscriptionStep_pop_WhiteCard'>
                    <DialogTitle className='child-margin-5'>
                        <div className='steps-popclose'>
                            <Tooltip title='Close'>
                                <Button onClick={onClose} className='steps-close-icon' >
                                    <CloseIcon onClick={onClose} />
                                </Button>
                            </Tooltip>
                        </div>
                        <div className='stepspop-header align-item-center'>
                            <h6>{currStepDetails?.stepTitle}</h6>
                            <div className='space-between'>
                                {
                                    currStepDetails?.customStep === true ? "" :
                                        <div className="progress-container">
                                            <div className="fundProgress-pc"><ProgressBar progressValue={(currStepDetails?.stepNumber / steps?.filter(item => item?.customStep !== true)?.length) * 100} />
                                            </div>
                                            <span className="fundstep-pc">{`${currStepDetails?.stepNumber}/${steps?.filter(item => item?.customStep !== true)?.length}`}</span>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            (type === stepsToShowWhile.USER_PROFILE_SAVE || type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) &&
                            <div className='space-between'>
                                <div></div>
                                <div className='child-row-margin-5'>
                                    {
                                        isSaveEnabled ?
                                            <>
                                                <div>
                                                    <LoadingButton
                                                        variant='outlined'
                                                        size="large"
                                                        startIcon={<CancelIcon />}
                                                        onClick={onCancelClick}
                                                        loadingPosition="start">
                                                        <p className={`actionButtonLabel`}>CANCEL</p>
                                                    </LoadingButton>
                                                </div>
                                                <div>
                                                    <LoadingButton
                                                        variant='contained'
                                                        size="large"
                                                        loading={loading}
                                                        startIcon={<SaveIcon />}
                                                        onClick={onSaveClick}
                                                        loadingPosition="start">
                                                        <p className={`actionButtonLabel`}>SAVE</p>
                                                    </LoadingButton>
                                                </div>
                                            </>
                                            :
                                            <div>
                                                <LoadingButton
                                                    variant='outlined'
                                                    size="large"
                                                    startIcon={<EditIcon />}
                                                    onClick={onEditClick}
                                                    loadingPosition="start">
                                                    <p className={`actionButtonLabel`}>EDIT</p>
                                                </LoadingButton>
                                            </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (currStepDetails?.stepHeader !== "" || currStepDetails?.stepDescription !== "") &&
                            <div className='child-margin-5 margin-top-15'>
                                <b>{currStepDetails?.stepHeader}</b>
                                <div>
                                    {currStepDetails?.stepDescription}
                                </div>
                            </div>
                        }
                    </DialogTitle>
                    <DialogContent className='scrollable-dialog-content'>
                        <form className='display-row-items-flex'>
                            {
                                currStepDetails?.customStep === true ?
                                    <div className="width-98">
                                        <PreviewFilePopup
                                            investorId={investorId}
                                            questionsDetails={subscriptionDetails?.values}
                                            investorSubscriptionDetails={investorSubscriptionDetails} />
                                    </div> :
                                    subscriptionDetails?.values
                                        ?.filter((item) => item?.step === currStepDetails?.stepID)
                                        ?.filter((item) => item?.hasChildQuestions === false)
                                        ?.sort((a, b) => a?.questionNo - b?.questionNo)
                                        ?.map((question) => {
                                            return (
                                                (!showThisQuestion(question)) ?
                                                    <></> :
                                                    <>
                                                        {
                                                            (/^[a-zA-Z]/.test(question?.questionHeader)) && //to check whether header contains alphabets or not
                                                            <div className="width-98">
                                                                {question?.questionHeader}
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.TEXT_BOX &&
                                                            <div className=
                                                                {(question?.doNotDisplayQuestion || question?.isFullWidth) ?
                                                                    "width-98 child-margin-0" : "width-45 child-margin-0"}
                                                                key={question?.questionBankID}>
                                                                <div className='margin-top-5'>
                                                                    <ALTTextField
                                                                        textFormatType={question?.gridSchema}
                                                                        name={question?.questionIndex}
                                                                        label={question?.doNotDisplayQuestion ? "" :
                                                                            question?.questionName}
                                                                        value={question?.userProvidedAnswer}
                                                                        multiline={question?.isText}
                                                                        rows={question?.isText ? 4 : 1}
                                                                        readOnly={!isSaveEnabled}
                                                                        onChange={(name, value) => { handleTextChange(name, value) }}
                                                                        required={question?.setValidations}
                                                                        charactersMaxLength={question?.characterLength}
                                                                        onBlur={subscriptionDetails.handleBlur}
                                                                        error={(subscriptionDetails?.touched[question?.questionIndex] || question?.userProvidedAnswer !== "") && Boolean(subscriptionDetails.errors[question.questionIndex])}
                                                                        errorMessage={(subscriptionDetails?.touched[question?.questionIndex] || question?.userProvidedAnswer !== "") && subscriptionDetails.errors[question.questionIndex]} />
                                                                </div>
                                                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                                {(question?.questionName.trim() === subscriptionValidateFields.COMMITED_CAPITAL ||
                                                                    question?.questionName.trim() === subscriptionValidateFields.SUBSCRIPTION_AMOUNT) &&
                                                                    <div className='color-gray'>
                                                                        Note: The minimum subscription amount of the fund is {ConvertToUSCurrency(fundDeatils?.minimumInvestmentAmount)}
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.DROP_DOWN &&
                                                            <div className={question?.isFullWidth ? "width-98" : "width-45"} key={question?.questionBankID}>

                                                                <div className='margin-top-5'>
                                                                    <SelectField
                                                                        name={question?.questionIndex}
                                                                        label={question?.questionName}
                                                                        value={question?.questionAnswerID}
                                                                        fullWidth={question?.isFullWidth}
                                                                        readOnly={!isSaveEnabled}
                                                                        onChange={(name, value) => { handleSelectChange(name, value) }}
                                                                        options={question?.questionAnswer?.map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                                                                        required={question?.setValidations}
                                                                        onBlur={subscriptionDetails.handleBlur}
                                                                        error={subscriptionDetails?.touched[question?.questionIndex] && Boolean(subscriptionDetails.errors[question.questionIndex])}
                                                                        errorMessage={subscriptionDetails?.touched[question?.questionIndex] && subscriptionDetails.errors[question.questionIndex]} />
                                                                </div>
                                                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.DATE_TIME &&
                                                            <div
                                                                className={question?.doNotDisplayQuestion ?
                                                                    "width-98 child-margin-0" : "width-45 child-margin-0"}
                                                                key={question?.questionBankID}>

                                                                <div className='margin-top-5'>
                                                                    <DateField
                                                                        name={question?.questionIndex}
                                                                        label={question?.questionName}
                                                                        value={question?.userProvidedAnswer}
                                                                        readOnly={!isSaveEnabled}
                                                                        onChange={(name, value) => { handleTextChange(name, value) }}
                                                                        required={question?.setValidations}
                                                                        onBlur={subscriptionDetails.handleBlur}
                                                                        disableFuture={question?.gridSchema === dateValidationTypeValues.ALLOW_FUTURE_DATES ? false : true}
                                                                        error={subscriptionDetails?.touched[question?.questionIndex] && Boolean(subscriptionDetails.errors[question.questionIndex])}
                                                                        errorMessage={subscriptionDetails?.touched[question?.questionIndex] && subscriptionDetails.errors[question.questionIndex]} />
                                                                </div>
                                                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.MULTI_CHOICE_QUESTION &&
                                                            <div className='width-98' key={question?.questionBankID}>
                                                                <div className={isSaveEnabled ? 'margin-top-5' : 'disabled-div'}>
                                                                    <CheckBoxGroupField
                                                                        name={question?.questionIndex}
                                                                        label={question?.doNotDisplayQuestion ? "" : question?.questionName}
                                                                        value={question?.multiChoiceQuestionAnswerIDs}
                                                                        onChange={(name, value) => { handleMultiSelectChange(name, value) }}
                                                                        readOnly={!isSaveEnabled}
                                                                        options={question?.questionAnswer?.filter(soption => soption.childID === 0).map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                                                                        required={question?.setValidations}
                                                                        onBlur={subscriptionDetails.handleBlur}
                                                                        error={subscriptionDetails?.touched[question?.questionIndex] && Boolean(subscriptionDetails.errors[question.questionIndex])}
                                                                        errorMessage={subscriptionDetails?.touched[question?.questionIndex] && subscriptionDetails.errors[question.questionIndex]} />
                                                                </div>
                                                                <a className='margin-top-15' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            (question?.questionPatternType === questionPatterns.RAIDO ||
                                                                question?.questionPatternType === questionPatterns.RAIDO_HORIZONTAL) &&
                                                            <div className='width-98' key={question?.questionBankID}>

                                                                <div className={isSaveEnabled ? 'margin-top-5' : 'disabled-div'}>
                                                                    <RadioGroupField
                                                                        name={question?.questionIndex}
                                                                        label={question?.doNotDisplayQuestion ? "" : question?.questionName}
                                                                        value={question?.questionAnswerID}
                                                                        onChange={(name, value) => { handleSelectChange(name, value) }}
                                                                        options={question?.questionAnswer
                                                                            ?.filter(soption => soption.childID === 0)
                                                                            .map(option => ({ label: option.answer, value: option.questionAnswerID }))}
                                                                        isBorder={question?.questionPatternType === questionPatterns.RAIDO}
                                                                        readOnly={!isSaveEnabled}
                                                                        required={question?.setValidations}
                                                                        onBlur={subscriptionDetails.handleBlur}
                                                                        error={subscriptionDetails?.touched[question?.questionIndex] && Boolean(subscriptionDetails.errors[question.questionIndex])}
                                                                        errorMessage={subscriptionDetails?.touched[question?.questionIndex] && subscriptionDetails.errors[question.questionIndex]} />
                                                                </div>
                                                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.RADIO_WITH_RADIO &&
                                                            <div className='width-98' key={question?.questionBankID}>

                                                                <div className={isSaveEnabled ? 'margin-top-5' : 'disabled-div'}>
                                                                    <RadioGroupField
                                                                        name={question?.questionIndex}
                                                                        label={question?.doNotDisplayQuestion ? "" : question?.questionName}
                                                                        value={question?.questionAnswerID}
                                                                        onChange={(name, value) => { handleSelectChange(name, value) }}
                                                                        options={question?.questionAnswer
                                                                            .map(option => ({ label: option.answer, value: option.questionAnswerID, childQuestionID: option.childID }))}
                                                                        isBorder={true}
                                                                        readOnly={!isSaveEnabled}
                                                                        hasChildQuestions={checkIsChildQuestions(question, question?.questionAnswerID)}
                                                                        renderSelectedQuestion={renderChildQuestions}
                                                                        required={question?.setValidations}
                                                                        onBlur={subscriptionDetails.handleBlur}
                                                                        error={subscriptionDetails?.touched[question?.questionIndex] && Boolean(subscriptionDetails.errors[question.questionIndex])}
                                                                        errorMessage={subscriptionDetails?.touched[question?.questionIndex] && subscriptionDetails.errors[question.questionIndex]} />
                                                                </div>
                                                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.FILE_UPLOAD &&
                                                            <div className='width-98'>
                                                                <div className="legalFieldWrapper ">
                                                                    <div className='space-between'>
                                                                        <div className='child-margin-5'>
                                                                            <div>{question?.questionName}</div>
                                                                            <a href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                                {question?.description}
                                                                            </a>

                                                                        </div>
                                                                        <div className='child-margin-5'>
                                                                            <div>
                                                                                {
                                                                                    (question?.userProvidedAnswer === "" || question?.userProvidedAnswer instanceof Blob || question?.userProvidedAnswer instanceof File) ?
                                                                                        <>
                                                                                            <div className=''>
                                                                                                <SelectField
                                                                                                    label="Document Type"
                                                                                                    name={`documentType${question?.questionIndex}`}
                                                                                                    options={documentTypes}
                                                                                                    value={question?.questionAnswerID}
                                                                                                    onChange={(name, value) => { handleDocumetTypeChange(question?.questionIndex, value) }} />
                                                                                            </div>
                                                                                            <div className='float-right'>
                                                                                                <FileUploadField
                                                                                                    name={question?.questionIndex}
                                                                                                    label="UPLOAD DOCUMENT"
                                                                                                    value={question?.userProvidedAnswer}
                                                                                                    readOnly={!isSaveEnabled}
                                                                                                    onChange={(name, value) => handleFileUpload(name, value)} />
                                                                                                {/* <FileUploadBtn
                                                                                                name={question?.questionIndex}
                                                                                                defaultFile={question?.userProvidedAnswer}
                                                                                                label="UPLOAD DOCUMENT"
                                                                                                onChange={(name, value) => handleFileUpload(name, value)} /> */}
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <div className='child-row-margin-5 align-items-center'>
                                                                                            <div className='kycattachedfile'>{question?.userProvidedAnswer?.split("/")?.[question?.userProvidedAnswer?.split("/")?.length - 1]}</div>
                                                                                            <div className='child-row-margin'>
                                                                                                <Tooltip title="Download File">
                                                                                                    <Button
                                                                                                        className=''
                                                                                                        onClick={() => { onDownloadClick(question?.userProvidedAnswer?.split("_")?.[0], question?.userProvidedAnswer?.split("/")?.[question?.userProvidedAnswer?.split("/")?.length - 1]) }}>
                                                                                                        <DownloadIcon />
                                                                                                    </Button>
                                                                                                </Tooltip>
                                                                                                {
                                                                                                    secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD &&
                                                                                                    <Button disabled={!isSaveEnabled}><CloseIcon onClick={() => { handleFileUpload(question?.questionIndex, "") }} /* onClick={()=>{onDeleteClick(question?.userProvidedAnswer?.split("_")?.[0])}} */ /></Button>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <Divider /> */}
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            question?.questionPatternType === questionPatterns.GRID &&
                                                            <>
                                                                <div className='width-98'>
                                                                    <CrudDataGrid
                                                                        name={question?.questionIndex}
                                                                        initialColumns={fetchGridInitialData(question?.gridSchema)}
                                                                        disabled={!isSaveEnabled}
                                                                        initialRows={fetchGridInitialData(question?.userProvidedAnswer)}
                                                                        maxLimit={question?.characterLength}
                                                                        onRowChange={(name, value) => handleTextChange(name, value)} />
                                                                </div>
                                                                <a className='margin-top-10' href={question?.description} target="_blank" rel="noopener noreferrer">
                                                                    {question?.description}
                                                                </a>
                                                            </>
                                                        }
                                                    </>
                                            )
                                        })
                            }
                        </form>
                    </DialogContent>
                </div>
                <div className='next-btn'>
                    <div className='subscription-step-btn float-right'>
                        {
                            (disableStepsFrom ? disableStepsFrom <= currStepDetails?.stepID ?
                                <ArrowForwardIcon sx={{ width: 32, height: 32, color: 'gray' }} onClick={onNextClick} /> : ""
                                : steps?.findIndex((item) => item.stepNumber === currStepDetails?.stepNumber) === steps?.length - 1) ? "" :
                                <ArrowForwardIcon sx={{ width: 32, height: 32, color: 'gray' }} onClick={onNextClick} />
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default StepPopup;