import React from 'react'
import { useCallback, useState, useEffect, useContext } from "react";
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import jsonData from '../UserDetails.json'
import Button from '@mui/material/Button';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import AddRole from '../popups/AddRole';
import secureLocalStorage from 'react-secure-storage';
import { getTenantDashboards } from '../Services/TabsData';
import { checkFeaturePermissions } from '../../../utils/common';
import { FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { InputAdornment, Radio, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { addNewRoleData } from "../apiDataTemplate";
import ActionButton from '../../common/ActionButton/ActionButton';
import EditIcon from '@mui/icons-material/Edit';
// import { ToastContainer, toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { rolesAndfeatureKYCTabToastMessages, rolesAndfeatureTabToastMessages } from '../../../utils/AppConstants';
import AltDataGridPro from '../../common/DataGridPro/AltDataGridPro';

const Roles = (props) => {

  //#region columns
  const [selectedRole, setSelectedRole] = useState({});
  const [newRoleData, setNewRoleData] = useState(addNewRoleData);
  const [rolePopUpTitle, setRolePopUpTitle] = useState('');

  //#region change events
  const handleRowSelection = (row) => {
    setSelectedRole(row);
    setNewRoleData({
      ...addNewRoleData,
      primaryRoleID: row.id || addNewRoleData.primaryRoleID,
      description: row.description || addNewRoleData.description,
      applicationDashboardId: row.applicationDashboardId || addNewRoleData.applicationDashboardId,
      updatedBy: secureLocalStorage.getItem("userId"),
      updatedDate: new Date()
    })
  };

  const editRowSelection = (row) => {
    setRolePopUpTitle('Edit Custom Role');
    setNewRoleData({
      ...addNewRoleData,
      roleId: row.id || addNewRoleData.roleId,
      primaryRoleID: row.primaryRoleID || addNewRoleData.primaryRoleID,
      dashboard: row.dashboard || addNewRoleData.dashboard,
      description: row.description || addNewRoleData.description,
      roleName: row.roleName || addNewRoleData.roleName,
      isPrimaryRole: row.isPrimaryRole || addNewRoleData.isPrimaryRole,
      applicationDashboardId: row.applicationDashboardId || addNewRoleData.applicationDashboardId,
      updatedBy: secureLocalStorage.getItem("userId"),
      updatedDate: new Date()
    })
    setClone(true);
    // console.log()
  };

  //#region variables
  const [dashboards, setDashboards] = useState(null);
  const [open, setOpen] = useState(false);
  const [clone, setClone] = useState(false);
  const [UsersRowsData, setUsersRowsData] = useState([]);
  const columns = [
    { "id": 2, field: 'roleName', headerName: 'Role Name', width: 300, },
    { "id": 3, field: 'description', headerName: 'Description', width: 300 },
    { "id": 4, field: 'dashboard', headerName: 'Dashboard', width: 300, },
  ]

  const systemColumns = [
    {
      "id": 1, "field": "", "headerName": "", width: 20,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Radio
            checked={params.row.id === selectedRole?.id}
            onChange={() => handleRowSelection(params.row)} />
        </div>
      )
    }, ...columns
  ]

  const customColumns = [
    { "id": 5, field: 'primaryRoleName', headerName: 'Parent Role', width: 300, },
    ...columns,
    {
      "id": 6, field: 'action', headerName: 'Action', width: 300,
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => editRowSelection(params.row)}
          />
        );
      },
    },
    { "id": 7, field: 'primaryRoleID', headerName: '', width: 0, },
  ]

  //#region click events
  const AddRoleButtonClick = () => {
    setRolePopUpTitle('Add New Custom Role');
    setOpen(true);
  }
  //#region click events
  const CloneRoleButtonClick = () => {
    setRolePopUpTitle('Add New Custom Role');
    setClone(true);
  }

  const closeButtonClick = () => {
    setOpen(false);
    setClone(false);
  }

  //#region api get calls
  const getDashboardData = async () => {
    const data = await getTenantDashboards();
    if (data.responseCode === 200) {
      setDashboards(data.responseData?.filter(role => role.title?.toLowerCase() != "investor" || "Portfolio Company"));
    }
    else {
      toast.warning(rolesAndfeatureKYCTabToastMessages.DATE_FETCHING_ERROR,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region useeffect
  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    console.log(dashboards)
    if (props.appRoles) {
      debugger;
      const rowsData = props.appRoles ? props.appRoles.map(user => ({
        id: user.roleId,
        "roleName": user.roleName,
        "description": user.description,
        "dashboard": dashboards ? dashboards?.find((item) => item.applicationDashboardId === user.applicationDashboardId)?.title : '',
        "isPrimaryRole": user.isPrimaryRole,
        "primaryRoleName": user.primaryRoleName,
        "primaryRoleID": user.primaryRoleID,
        "applicationDashboardId": user.applicationDashboardId
      })) : [];
      // debugger
      setUsersRowsData(rowsData);
    }
  }, [props.appRoles, dashboards])

  //#region return
  return (
    <div>
      <div className='flexspacebetween'>
        <div className='space-between'>
          <div> <h4>Roles</h4> </div>
        </div>
        <div className='white-card margin-top-5 height-400-scroll'>
          <div className='space-between'>
            <h6>
              System Roles
              <Tooltip title={'System roles refer to different types of access or responsibilities assigned to users within a system or application and common for all firm.'}>
                <InfoIcon className='info-i' />
              </Tooltip>
            </h6>
            <div>
              {
                checkFeaturePermissions(RolesAndPermissions.SITE_ADMINISTRATION, FeatureAccess.CREATE) &&
                <ActionButton
                  variant='outlined'
                  size="large"
                  onClick={CloneRoleButtonClick}
                  disabled={selectedRole?.id > 0 ? false : true}
                  icon={<ContentCopyIcon />}
                  label="Copy"
                  toolTip={selectedRole?.id > 0 ? '' : 'Select one role to enable the copy function.'}
                  loadingPosition="start">
                </ActionButton>
              }
            </div>
          </div>
          <AltDataGridPro
            columns={systemColumns}
            rows={UsersRowsData.filter(urd => urd.isPrimaryRole == true)}
            hideFooter
          />
        </div>
        {clone &&
          <AddRole
            open={clone}
            setOpen={setClone}
            excistedRoles={props.appRoles}
            onClose={closeButtonClick}
            setRoles={props.refreshRoles}
            dashboardDropdown={dashboards}
            roleInitialData={newRoleData}
            clone={true}
            title={rolePopUpTitle} />
        }
      </div>
      <div className='flexspacebetween margin-top-10'>
        <div className='white-card margin-top-5 height-400-scroll'>
          <div className='space-between'>
            <h6>Custom Roles
              <Tooltip title={'Custom roles are tailored user roles created to meet specific needs within a system or application.'}>
                <InfoIcon className='info-i' />
              </Tooltip>
            </h6>
            <div>
              {
                checkFeaturePermissions(RolesAndPermissions.SITE_ADMINISTRATION, FeatureAccess.CREATE) &&
                <Button
                  variant="outlined"
                  className='btn-primary'
                  onClick={AddRoleButtonClick}>
                  <AddIcon />
                  ADD ROLE
                </Button>
              }
            </div>
          </div>
          <AltDataGridPro
            columns={customColumns}
            rows={UsersRowsData.filter(urd => urd.isPrimaryRole == false)}
            hideFooter
            columnVisibilityModel={{ primaryRoleID: false }} />
        </div>
        {open &&
          <AddRole
            open={open}
            setOpen={setOpen}
            excistedRoles={props.appRoles}
            onClose={closeButtonClick}
            setRoles={props.refreshRoles}
            dashboardDropdown={dashboards}
            roleInitialData={addNewRoleData}
            clone={false}
            title={rolePopUpTitle} />}
      </div>
      <ToastContainer />
    </div>
  )
}

export default Roles
