
import InvestorDashboardOld from "./components/dashboards/InvestorDashboardOld";
import CapitalCallDetailSaved from "./components/dashboards/CapitalCallDetailSaved";
import FirmWideDashboardAI from "./components/dashboards/FirmWideDashboardAI";
import FirmWideDashboard from "./components/firm-dashboard/components/FirmWideDashboard";
import InvestorOverviewPage from "./components/dashboards/InvestorDetailDashboard/InvestorOverView";
import UserProfile from "./components/user-profile/components/UserProfile";
import RichTextEditor from "./components/common/RichText/RichTextModal";
import FundManagerUserProfile from "./components/user-profile/components/FundManagerUserProfile";
import FundDetailServices2 from "./components/dashboards/FundDetailDashboards/FundDetailServices2";
import ReportingQuarterlyReport from "./components/dashboards/FundDetailDashboards/ReportingQuarterlyReport";
import PartnersCapital from "./components/dashboards/FundDetailDashboards/PartnersCapital";
import ComplianceOfficerDashboard from "./components/ComplianceDashboard/ComplianceOfficerDashboard";
import ComplianceOfficer2 from "./components/ComplianceDashboard/ComplianceOfficer2";
import Manualsubscription from "./components/ComplianceDashboard/Manualofflinesubscriptions/Manualsubscription";
import InvestorCommunication from "./components/InvestorRelations/Components/InvestorCommunications/InvestorCommunication";
import Notifications from "./components/InvestorRelations/Components/Notifications/Notifications";
import Subscriptionstatus from "./components/ComplianceDashboard/Manualofflinesubscriptions/Subscriptionstatus";
import Marketplaceprogresspage from "./components/ProgressPages/Marketplaceprogresspage";
import Howitworkspage from "./components/ProgressPages/Howitworkspage";
import Privatemarketacademypage from "./components/ProgressPages/Privatemarketacademypage";
import Aboutuspage from "./components/ProgressPages/Aboutuspage";
import VDRoom from "./components/common/VirtualDataRoom/VDRoom";
import EditFund from "./components/marketplace/components/EditFund/EditFund";
import PortfolioDetailPage from "./components/PortfolioCompanies/components/PortfolioDetailPage";
import FundManagerDashboard from "./components/FundManagerDashboard/Components/FundManagerDashboard";
import Compare from "./components/marketplace/components/Compare";
import Favouritepage from "./components/marketplace/components/Favourite";
import Marketplace from "./components/marketplace/components/MarketPlaceMain";
import AppDiscussionComponent from "./components/common/AppDiscussion/AppDiscussionComponent";

import FundDetails from "./components/marketplace/components/FundDetails";
import PortifolioOnboarding from "./components/PortfolioCompanies/components/PortifolioOnboarding";
import K1Report from "./components/Servicing/K-1";
import Administration from "./components/administration/components/AdministrationMain";
import User from "./components/administration/components/Users";
import { FundDetailsWrapper } from "./components/dashboards/FundDetailDashboards/FundDetailsWrapper";
import ValidationPopup from "./components/FundNews/Components/ValidationPopup";
import ServicingAccordion from "./components/Servicing/FundServicing";
import CustomCaptcha from "./components/common/Custom Captcha/Custom Captcha";
import CapitalCall from "./components/Servicing/AddingCapitalCall";
import CapitalCallActivity from "./components/Servicing/CapitalCallActivity";
import DistributionActivity from "./components/Servicing/Distributions/DistributionActivity";
import BalanceSheetReports from "./components/Servicing/InvestorReporting/BalanceSheetReports";
import PartnersCapitalAccountReports from "./components/Servicing/InvestorReporting/PartnersCapitalAccountReports";
import QuarterlyReports from "./components/Servicing/InvestorReporting/QuarterlyReports";
import ExecutiveSummaryReports from "./components/Servicing/InvestorReporting/ExecutiveSummaryReports";
import UserProfileModel from "./components/user-profile/InvestorUserProfile/UserProfileModel";
import UserDashboard from "./components/AppAuthentication/UserDashboard";
import UnderConstruction from "./components/common/page-not-found/underConstruction";
import Editor from "./components/common/RichText/RichTextModal";
import FMInvestorRelations from "./components/InvestorRelations/Components/FMInvestorRelations";
import AddGroups from "./components/InvestorRelations/Components/AddGroups";
import KYCDashboard from "./components/KYCVerification/components/KYCDashboard";
import KYCVerification from "./components/KYCVerification/components/KYCVerification";

import DocumentsDasboard from "./components/InvestorReporting/FirmEmployees/Components/DocumentsDasboard";

import FirmUserProfileModal from "./components/user-profile/FirmUserProfile/Components/FirmUserProfileModal";
import InvestorDocuments from "./components/InvestorReporting/Investors/Components/InvestorDocuments";
import FirmDocuments from "./components/InvestorReporting/FirmEmployees/Components/FirmDocuments";
import AppCalendar from "./components/Calendar/Components/AppCalendar";
import Display from "./UIUX/component/display";
import AzureChatBot from "./components/chatbot/AzureChatBot";

import AuditingDashboard from "./components/auditing/components/AuditingDashboard";
import SubscriptionAudit from "./components/auditing/components/SubscriptionAudit";
import FundAudit from "./components/auditing/components/FundAudit";
import PCAudit from "./components/auditing/components/PCAudit";

import MarketPlaceMain from "./components/marketplace-dashboard/components/MarketPlaceMain";
import CompareFunds from "./components/marketplace-dashboard/components/compare/CompareFunds";
import FavouriteFunds from "./components/marketplace-dashboard/components/compare/FavouriteFunds";
import InvestorProfileAudit from "./components/auditing/components/InvestorProfileAudit";
import PortfolioMain from "./components/portfolio-companies/components/companyoverview/PortfolioMain";

import InvestorDashboard from "./components/investor-dashboard/components/InvestorDashboard";

import SuggestedReadingMain from "./components/suggested-readings/components/SuggestedReadingMain";
import AdministrationMain from "./components/administration/components/AdministrationMain";
import AddDistributionMain from "./components/fund-services/components/distributions/AddDistributionMain";
import DistibutionsModel from "./components/fund-services/components/distributions/DistibutionsModel";
import SharedVdrFile from "./components/common/VirtualDataRoom/SharedVdrFile";
import SubscriptionMain from "./components/investor-fund-subscription/components/SubscriptionMain";
import ApprovedInvestorOverview from "./components/dashboards/InvestorDetailDashboard/ApprovedInvestorOverview";
import SubscriptionDetails from "./components/CODashboard/components/SubscriptionDetails";
import CODashboard from "./components/CODashboard/components/CODashboard";
import InvestorUserProfileMain from "./components/user-profiles/components/investor-user-profile/InvestorUserProfileMain";
import FirmUserProfileMain from "./components/user-profiles/components/fm-user-profile/FirmUserProfileMain";
import SubsciberMain from "./components/fm-subscribers/components/SubsciberMain";
import ApprovedInvOverview from "./components/investor-dashboard/components/ApprovedInvestorMain";
import ApprovedInvestorMain from "./components/investor-dashboard/components/ApprovedInvestorMain";
import FmOfflineSubscriptionPageMain from "./components/investor-fund-subscription/components/offline-subscription/FmOfflineSubscriptionPageMain";
import { FundOverviewMain } from "./components/fund-overview/components/FundOverviewMain";
import EditFundModel from "./components/create-edit-fund/components/edit-fund/EditFundModel";

export const RouteConfig = [
    {
        path: "/marketplace",
        component: <MarketPlaceMain />
    },
    {
        path: "/market-place",
        component: <Marketplace />
    },
    {
        path: "/fund-compare",
        component: <CompareFunds />
    },
    {
        path: "/favourite-funds",
        component: <FavouriteFunds />
    },
    {
        path: "/addgroups",
        component: <AddGroups />
    },
    {
        path: "/CODashboard",
        component: <CODashboard />
    },
    {
        path: "/quarterly-reports",
        component: <QuarterlyReports />
    },
    {
        path: "/executive-summary-reports",
        component: <ExecutiveSummaryReports />
    },
    {
        path: "/fund-details",
        component: <FundDetails />
    },
    {
        path: "/compare",
        component: <Compare />
    },
    {
        path: "/user-profile-oldone",
        component: <UserProfile />
    },
    {
        path: '/AppDisco',
        component: <AppDiscussionComponent />
    },
    {
        path: "/Capitalcall",
        component: <CapitalCallDetailSaved />
    },
    {
        path: "/balance-sheet-reports",
        component: <BalanceSheetReports />
    },
    {
        path: "/partners-capital-reports",
        component: <PartnersCapitalAccountReports />
    },
    {
        path: "/Investor-Dashboard",
        component: <InvestorDashboard />
    },
    {
        path: "/FundManager-Dashboard",
        component: <FundManagerDashboard />
    },
    {
        path: "/firm-wide-dashboard-ai",
        component: <FirmWideDashboardAI />
    },
    {
        path: "/firm-wide-dashboard",
        component: <FirmWideDashboard />
    },
    {
        path: "/Investor-Overview-Page",
        component: <ApprovedInvestorMain />
    },
    {
        path: "/fund-detail-services-2",
        component: <FundDetailServices2 />
    },
    {
        path: "/Reporting-Quarterly",
        component: <ReportingQuarterlyReport />
    },
    {
        path: "/Partners-Capital",
        component: <PartnersCapital />
    },
    {
        path: "/portfoliodetails",
        component: <PortfolioDetailPage />
    },
    {
        path: "/PortfolioOnboarding",
        component: <PortifolioOnboarding />
    },
    {
        path: "/VDR",
        component: <VDRoom />
    },
    {
        path: "/ComplianceDashBoard",
        component: <ComplianceOfficer2 /> 
    },
    {
        path: "/subscription-details",
        component: <SubscriptionDetails />
    },
    {
        path: "/offline-subscription",
        component: <Manualsubscription />
    },
    {
        path: "/investor-communication",
        component: <InvestorCommunication />
    },
    {
        path: "/notifications",
        component: <Notifications />
    },
    {
        path: "/offline-subscription-info",
        component: <FmOfflineSubscriptionPageMain />
    },
    {
        path: "/market-progresspage",
        component: <Marketplaceprogresspage />
    },
    {
        path: "/how-itworks",
        component: <Howitworkspage />
    },
    {
        path: "/pmacademy-page",
        component: <Privatemarketacademypage />
    },
    {
        path: "/aboutus-page",
        component: <Aboutuspage />
    },
    {
        path: "/fm-user-profile/:detail",
        component: <FundManagerUserProfile />
    },
    {
        path: "/investor-relations/:detail",
        component: <FMInvestorRelations />
    },
    {
        path: "/favourite-page",
        component: <Favouritepage />
    },
    /* {
        path: "/edit-fund-page",
        component: <EditFund />
    }, */
    {
        path: "/edit-fund-page",
        component: <EditFundModel />
    },
    {
        path: "/suggested-readings",
        component: <SuggestedReadingMain />
    },
    {
        path: "/administration",
        component: <AdministrationMain />
    },
    {
        path: "/userdata",
        component: <User />
    },
    {
        path: "/marketplace/:detail",
        component: <FundOverviewMain />
    },
    // {
    //     path: "/distribution-pages",
    //     component: <DistributionPage/>
    // },
    {
        path: "/serviceAcc",
        component: <ServicingAccordion />
    },
    {
        path: "/customcaptcha",
        component: <CustomCaptcha />
    },
    {
        path: "/validationpopup",
        component: <ValidationPopup />
    },
    {
        path: "/capitalActivity",
        component: <CapitalCallActivity />
    },
    {
        path: "/muicapitalcall",
        component: <CapitalCall />
    },
    {
        path: "/distribution-activity",
        component: <DistributionActivity />
    },
   
    // {
    //     path:'/UserDashboard',
    //     component:<UserDashboard/>
    // },
    {
        path: '/UserDetails',
        component: <FirmUserProfileModal />
    },
    {
        path: '/under-construction',
        component: <UnderConstruction />
    },
    {
        path: '/richtext-editor',
        component: <Editor />
    },
    {
        path: '/kyc-aml-dashboard',
        component: <KYCDashboard />
    },
    {
        path: '/kyc-aml-verification/:detail',
        component: <KYCVerification />
    },
    {
        path: '/vdr-shared-file/:detail',
        component: <SharedVdrFile />
    },
    {
        path: '/investor-reporting-dashboard',
        component: <FirmDocuments />
    },
    {
        path: '/investor-reports-view',
        component: <InvestorDocuments />
    },
    {
        path: '/ui-display',
        component: <Display />
    },
    {
        path: "/all-notifications",
        component: <InvestorCommunication />
    },
    {
        path: "/k1-report",
        component: <K1Report />
    },
    {
        path: "/Calendar",
        component: <AppCalendar />
    },
    {
        path: "/azure-chat-bot",
        component: <AzureChatBot />
    },
    {
        path: "/auditing-dashboard",
        component: <AuditingDashboard />
    },
    {
        path: "/auditing-overview-page",
        component: <InvestorProfileAudit />
    },
    {
        path: '/subscription-auditing-overview',
        component: <SubscriptionAudit />
    },
    {
        path: '/fund-auditing-overview',
        component: <FundAudit />
    },
    {
        path: '/pc-auditing-overview',
        component: <PCAudit />
    },
    {
        path: '/portfolio-company-overview',
        component: <PortfolioMain />
    },
    {
        path: '/distributions-page',
        component: <DistibutionsModel />
    },
    {
        path: '/FundSubscriptionPage',
        component: <SubscriptionMain />
    },
  /*   {
        path: "/user-profile",
        component: <UserProfileModel />
    }, */
    {
        path: '/user-profile',
        component: <InvestorUserProfileMain />
    },
    {
        path: '/firm-user-profile',
        component: <FirmUserProfileMain />
    },
    {
        path: '/subscriber-profile',
        component: <SubsciberMain />
    },
];