import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectField from '../../../common/input-fields/SelectField';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import ActionButton from '../../../common/ActionButton/ActionButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, FormControlLabel, IconButton, MenuItem, Radio, Select, Tooltip } from '@mui/material';
import Text from '../../../common/Text/Text';
import { toast } from 'react-toastify';
import SwitchButtonField from '../../../common/input-fields/SwitchButtonField';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { FieldVariants } from '../../../common/TextInput/appInputenum';
import { FundCreationModes } from '../../services/apiDataDemplate';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { convertStringToArray } from '../../../common/Functions/ConvertStringtoArray';

const ManagementTeam = forwardRef((props, ref) => {

  //#region props
  const { type,fundDetails, setFundDetails, managementStaffDetails,firmDetails } = props;

  //#region intialData
  const initialData = {
    ...fundDetails,
    fundStaff: type === FundCreationModes.EDIT 
                        ? convertStringToArray(fundDetails?.fundStaff) || []
                        : Array.isArray(fundDetails?.fundStaff) 
                           ? fundDetails?.fundStaff 
                           : [-1],
    managementDetails: type === FundCreationModes.EDIT 
                        ? fundDetails?.fundManagementTeams?.map((item) => item?.managementTeamStaff)
                        : Array.isArray(fundDetails?.managementDetails) ? fundDetails?.managementDetails : [-1],
  };

  
  //#region variables
  const [loading,setLoading] = useState(false);
  const [isSaveEnabled,setIsSaveEnabled] = useState(type === FundCreationModes.EDIT ? false : true);

  //#region change events
  const authorizedSignatoryChange = (value) => {
    if(value === -1){
      toast.warning("Please select Member", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    }
    else{
      managementTeamDetails.setFieldValue('authorizedSignatory', value);
    }
  }
  
  const handleMemberChange = (field, value) => {
    const selectedMembers = managementTeamDetails.values?.managementDetails;
    selectedMembers[field] = value;
    handleChange("managementDetails", selectedMembers)
  }

  const handleFundManagerChange = (field, value) => {
    const selectedFms = managementTeamDetails?.values?.fundStaff;
    selectedFms[field] = value;
    handleChange("fundStaff", selectedFms)
  }

  const handleChange = (field, value) => {
    if(field === "isFirmAsGP" && value === true){
      managementTeamDetails.setFieldValue("fundStaff", [-1]);
    }
    managementTeamDetails.setFieldValue(field, value);
  }

  //#region click events
  const addFundManager = () => {
    const newFm = [...managementTeamDetails.values.fundStaff,-1]
    handleChange("fundStaff", newFm)
  }

  const deleteFundManager = (value) => {
    const deletedFm = managementTeamDetails.values.fundStaff?.filter((item,index) => index !== value);
    handleChange("fundStaff", deletedFm)
  }

  const addNewMember = () => {
    const newMemeber = [...managementTeamDetails.values?.managementDetails,-1]
    handleChange("managementDetails", newMemeber)
  }

  const deleteMember = (value) => {
    const deletedMemeber = managementTeamDetails.values?.managementDetails?.filter((item,index) => index !== value);
    handleChange("managementDetails", deletedMemeber)
  }

  const onEditClick = () => {
    setIsSaveEnabled(true);
  }

  const onSaveClick = async() => {
    const errors = await managementTeamDetails.validateForm();
    if (Object.keys(errors).length === 0) {
      /* updateFundDetails(); */
      alert("ok");
    }
    else {
      managementTeamDetails?.handleSubmit()
    }
  }

  const onCancelClick = () => {
    setIsSaveEnabled(false);
    managementTeamDetails?.resetForm();
  }

  //#region formik validations
  const validationSchema = yup.object().shape({
    fundTypeID: yup.number()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    fundName: yup
      .string()
      .required(validationsConstants.REQUIRED),
  });

  const managementTeamDetails = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return true;
    },
  });

  //#region imperative handle
  useImperativeHandle(ref, () => ({
    validateFields: async () => {
      const errors = await managementTeamDetails.validateForm();
      if (Object.keys(errors).length === 0) {
        //need to update main set of values
        setFundDetails((prevValues) => {
          return { ...prevValues, ...managementTeamDetails?.values };
        });
        return true;
      }
      else {
        managementTeamDetails?.handleSubmit()
      }
    },
    updateFields: async () => {
      setFundDetails((prevValues) => {
        return { ...prevValues, ...managementTeamDetails?.values };
      });
    }
  }));

  //#region return
  return (
    <>
      {
      type === FundCreationModes.EDIT &&
      <div className='space-between '>
          <div></div>
          <div className='edit-save-sec child-row-margin-5 margin-right-10'>
              {isSaveEnabled ?
                <>
                  <div>
                    <ActionButton 
                        label="Cancel"
                        variant={FieldVariants.OUTLINED}
                        disabled={loading}
                        icon={<CancelIcon />}
                        onClick={() => onCancelClick()}/>
                  </div>
                  <div>
                    <ActionButton
                      label="SAVE"
                      icon={<SaveIcon />}
                      loading={loading}
                      onClick={() => onSaveClick()} />
                  </div>
                </> 
                :
                <div>
                  <ActionButton 
                      label="Edit"
                      icon={<EditIcon />}
                      onClick={() => onEditClick()}/>
                </div>}
            
            <div></div>
          </div>
      </div>
      }
      <div className='fields-sec display-row-items-flex'>
        <div className='header-sec width-98 internalText margin-top-15'>
            Fund manager / General Partner (GP)
        </div>
        <div className='fund-manager-sec width-98 child-margin-10'>
            <div className='space-between'>
              <div>
                <SwitchButtonField
                  name="isFirmAsGP"
                  label="Firm as a fund manager"
                  value={managementTeamDetails.values?.isFirmAsGP}
                  onChange={(name, value) => handleChange(name, value)}
                  disabled={!isSaveEnabled} />
              </div>
              <div>
                {
                  !managementTeamDetails.values?.isFirmAsGP &&
                  <ActionButton
                      label="ADD FUND MANAGER"
                      icon={<AddIcon />}
                      className="btn-primary"
                      disabled={!isSaveEnabled}
                      variant="outlined"
                      onClick={() => addFundManager()} />
                }
              </div>
            </div>
            {
              managementTeamDetails.values?.isFirmAsGP 
              ? <div className='width-98'>
                  <div className='margin-top-10'>
                    <ALTTextField 
                        name="description"
                        placeholder="Description"
                        value={firmDetails?.firmDescription}
                        multiline={true}
                        variant={FieldVariants.OUTLINED}
                        rows={2}
                        readOnly={true}
                        onChange={()=>{}}/>
                  </div>
                </div>
              : <div className='width-98'>
                  <div className='child-margin-10'>
                    {
                    Array.isArray(managementTeamDetails.values?.fundStaff) &&
                      managementTeamDetails.values?.fundStaff
                      ?.map((managerId, index) => {
                        return (
                          <div key={index} className='fundManagerRow'>
                            <div className='fundManager-name'>
                                <SelectField 
                                    name={index}
                                    label="Fund Manager"
                                    value={managerId}
                                    options={managementStaffDetails?.map((option) => ({
                                      label: option.label,
                                      value: option.value,
                                      disabled: 
                                        (Array.isArray(managementTeamDetails.values?.fundStaff) && 
                                            managementTeamDetails.values?.fundStaff?.some((item) => item === option?.value)) ||
                                        (Array.isArray(managementTeamDetails.values?.managementDetails) && 
                                              managementTeamDetails.values?.managementDetails?.includes(option?.value))
                                      }))}   
                                    readOnly={!isSaveEnabled}                       
                                    onChange={(name,value)=>handleFundManagerChange(name,value)} />
                            </div>
                            <div className='fundManager-Description'>
                                <ALTTextField 
                                    name="description"
                                    placeholder="Description"
                                    value={managementStaffDetails?.find((item) => item?.value === managerId)?.briefDescription}
                                    multiline={true}
                                    variant={FieldVariants.OUTLINED}
                                    rows={1}
                                    readOnly={true}
                                    onChange={()=>{}}/>
                            </div>
                            <div className="fundManager-delete">
                                    <Tooltip
                                      className='deleteicon'
                                      onClick={() => deleteFundManager(index)}
                                      disabled={index === 0 || managementTeamDetails.values?.isFirmAsGP}
                                      title={index === 0 ? "Please add another team member to delete this member" : "Remove Member"}
                                      arrow>
                                      <IconButton>
                                        <ClearIcon />
                                      </IconButton>
                                    </Tooltip>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
            }
        </div>
        <div className='divider-sec width-98 margin-top-20'>
          <Divider />
        </div>
        <div className='management-team-sec width-98 space-between margin-top-20'>
          <div><Text label="Management Team" /></div>
            <div>
              <ActionButton
                variant="outlined"
                label="ADD TEAM MEMBER"
                startIconName="ControlPointOutlined"
                disabled={!isSaveEnabled}
                onClick={addNewMember} />
            </div>
        </div>
        {
          Array.isArray(managementTeamDetails.values.managementDetails) &&
          managementTeamDetails.values.managementDetails
          ?.map((memberId,index) => {
            return(
              <div className='width-98 child-margin-5'>
                  <div className='space-between'>
                    <div>Member {index+1}</div>
                    <div>
                      {
                        managementTeamDetails.values?.isFirmAsGP &&
                        <FormControlLabel 
                            control={<Radio 
                              checked={memberId === managementTeamDetails?.values?.authorizedSignatory} 
                              onChange={(e) => authorizedSignatoryChange(memberId)} />}
                            label={<>Authorized Signatory<span style={{ color: 'red', marginLeft: '5px' }}>*</span></>}
                            onChange={(e) => authorizedSignatoryChange(memberId)}
                            disabled={!isSaveEnabled} />
                      }
                    </div>
                  </div>
                  <div className='display-row-items-2'>
                    <div>
                    <SelectField 
                        name={index}
                        label="Name"
                        value={memberId}
                        options={managementStaffDetails?.map((option) => ({
                          label: option.label,
                          value: option.value,
                          disabled: 
                            (Array.isArray(fundDetails?.fundStaff) && 
                            fundDetails?.fundStaff?.some((item) => item === option?.value)) ||
                            (Array.isArray(managementTeamDetails?.values?.managementDetails) && 
                            managementTeamDetails?.values?.managementDetails?.includes(option?.value))
                        }))}
                        readOnly={!isSaveEnabled}
                        onChange={(name, value) => handleMemberChange(name, value)} />
                    </div>
                    <div className='child-row-margin-5 align-items-center'>
                        <ALTTextField
                            value={managementStaffDetails?.find((item) => item?.value === memberId)?.title}
                            name='title'
                            label="Title"
                            editable={false}
                            className="InputFieldClass-FundNews"
                            onChange={() => { }}
                            disabled={true}/>
                        <div>
                          <Tooltip
                            className='deleteicon'
                            onClick={() => deleteMember(index)}
                            disabled={index === 0}
                            title={index === 0 ? "Please add another team member to delete this member" : "Remove Member"}
                            arrow>
                            <IconButton>
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                    </div>
                  </div>
                  <div>
                      <ALTTextField
                          variant="outlined"
                          multiline={true}
                          rows={4}
                          name={"desc"}
                          value={managementStaffDetails?.find((item) => item?.value === memberId)?.briefDescription}
                          disabled={true}
                          placeholder="Brief description their role, responsibility and fund history"
                          onChange={() => {}} />
                  </div>
              </div>
            )
          })
        }
      </div>
    </>
  );
});

export default ManagementTeam;

//#region commented code
{/* <CrudDataGrid
          name="managementDetails"
          addLabel="ADD TEAM MEMBER"
          initialColumns={optionsGridColumns}
          initialRows={fetchGridInitialData(managementTeamDetails.values?.managementDetails)}
          onRowChange={(name, value) => handleChange(name, value)}
          height={200} /> 
          
    const optionsGridColumns = [
    {
      field: 'answer', headerName: 'Name', editable: true, flex: 1, type: 'singleSelect',
      valueOptions: managementStaffDetails,
      renderEditCell: (params) => (
        <Select
          value={params.value || ''}
          onChange={(event) => params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value })}
          fullWidth>
          {managementStaffDetails.map((option) => (
            <MenuItem key={option.value} value={option.value}
              disabled={(Array.isArray(fundDetails?.fundStaff) && fundDetails?.fundStaff?.find((item) => item?.answer === option?.value)) ||
                (Array.isArray(managementTeamDetails?.values?.managementDetails) && managementTeamDetails?.values?.managementDetails?.find((item) => item?.answer === option?.value))}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'Title', headerName: 'Title', editable: false, flex: 1,
      renderCell: (params) => {
        const title = managementStaffDetails?.find((item) => item?.value === params.row?.answer)?.title;
        return <div>{title}</div>;
      }
    },
    {
      field: 'description', headerName: 'Description', editable: false, flex: 1,
      renderCell: (params) => {
        const description = managementStaffDetails?.find((item) => item?.value === params.row?.answer)?.briefDescription;
        return <div>{description}</div>;
      }
    },
  ];

  if (fundDetails?.isFirmAsGP) {
    optionsGridColumns.push({
      field: 'AuthorizedSignatory',
      headerName: 'Authorized Signatory',
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Radio checked={params.row.answer === managementTeamDetails?.values?.authorizedSignatory} onChange={(e) => authorizedSignatoryChange(params.row.answer)} />
        </div>
      )
    });
  }*/}