import React, { useEffect, useState } from 'react'
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader'
import secureLocalStorage from 'react-secure-storage'
import { validationsConstants } from '../../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { currencyDropdownDetails, distributionDropdownDetails, distributionMethodEnum, distributionPagesEnum, distributionStatusEnum, initialAddDistributionValues, tireDummyRows } from '../apiDataTemplate';
import { Button, debounce, TextField } from '@mui/material';
import ALTTextField from '../../../common/input-fields/ALTTextField';
import SelectField from '../../../common/input-fields/SelectField';
import DateField from '../../../common/input-fields/DateField';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';
import { createNewDistributionTier, deleteDistribution, getFundDistributions, getFundDistributionsbyID, updateDistributionTierDetails } from '../../services/services';
import { toast, ToastContainer } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import { FieldVariants, textFormatTypeEnum } from '../../../common/TextInput/appInputenum';
import { formatDate } from '../../../common/Functions/TimeConvestion';
import { ConvertToPercentage, ConvertToUSCurrency } from '../../../common/Functions/CommonConvertions';
import ExecuteDistributionPopup from '../../popups/ExecuteDistributionPopup';
import DeleteDistributionPopup from '../../popups/DeleteDistributionPopup';
import { MUIInputsMarginTop } from '../../../CommonCss';
import AltDataGridPro from '../../../common/DataGridPro/AltDataGridPro';

//#this component is to edit and add the tiers in distribution
const AddDistributionMain = (props) => {

    //#region props
    const { setCurrPage, selectedDistribution, previousDistributions, distributionsDetails, setPreviousDistributions, getPreviosDistributions } = props;


    //#region varibales
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [savebtnloading, setSavebtnLoading] = useState(false);
    const [dtTypeIDsDetails, setDistributionsTypesDetails] = useState([]);
    const [distibutionSourceDetails, setdtSourceIDDetails] = useState([]);
    const [allocationsDetails, setAllocationsDetails] = useState([]);
    const [paymentMethodDetails, setPaymentMethodDetails] = useState([]);
    const [selectedInvestors, setSelectedInvetsors] = useState([]);
    const [deletebtnLoading, setDeleteBtnLoading] = useState(false);
    const [executebtnLoading, setExecuteBtnLoading] = useState(false);
    const [tierStatues, setTierStatues] = useState();
    const [isExecutePopup, setIsExecutePopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const columns = [
        {
            field: 'tierID', headerName: 'Tier',
            renderCell: (params) => {
                const tierID = distributionsDetails.values.tierID || selectedDistribution.tierID;
                const tierName = tierStatues?.find((tier) => tier.listItemID === tierID)?.listItemValue;
                return tierName;
            }
        },
        {
            field: 'dtStatus', headerName: 'TIER STATUS',
            renderCell: (params) => {
                return distributionDetails?.values?.distributionStatus
            }
        },
        {
            field: 'allocatedamount', headerName: 'Allocated Amount',
            renderCell: (params) => {
                return ConvertToUSCurrency(distributionDetails?.values?.dtAllocationAmount)
            },
        },
        { field: 'investor', headerName: 'Investor Name', },
        { field: 'investorType', headerName: 'Investor Type', },
        { field: 'ytdDistributionAmount', headerName: 'YTD DISTRIBUTION AMOUNT', },
        {
            field: 'subscribed', headerName: 'SUBSCRIBED', width: 150,
            renderCell: (params) => {
                return ConvertToUSCurrency(params.row.subscribed)
            },
        },
        {
            field: 'contribution', headerName: 'CONTRIBUTION',
            renderCell: (params) => {
                return ConvertToUSCurrency(params.row.contribution)
            },
        },
        {
            field: 'fdOwnership', headerName: 'OWNERSHIP', width: 150,
            renderCell: (params) => {
                return ConvertToPercentage(params.row.fdOwnership, 3)
            },
        },
        {
            field: 'currentDistributionAmount', headerName: 'CURRENT DISTRIBUTION AMOUNT',
            renderCell: (params) => {
                return ConvertToUSCurrency(params.row.currentDistributionAmount, 3)
            },
        },
    ];

    //#region functions
    const calculateAllocationAmount = (percentage) => {
        const caluculatedAmount = (distributionDetails.values.distributionAmount * parseInt(percentage)) / 100;
        return caluculatedAmount;
    }

    const calculatePercentage = (amount) => {
        const caluculatedAmount = (distributionDetails.values.distributionAmount / parseInt(amount)) * 100;
        distributionDetails.setFieldValue("dtAllocationPercentage", caluculatedAmount)
    }



    const caluculateInvestorGridDetails = (selectedids) => {
        const selectedRows = distributionDetails.values.distributionInvestors?.filter(row =>
            selectedids.includes(row?.investorID)
        );
        if (!selectedRows || selectedRows.length === 0) return;
        const totalSelected = selectedRows.reduce((total, row) => total + row.subscribed, 0);
        distributionDetails.values.distributionInvestors?.forEach(row => {
            const isSelected = selectedids.includes(row?.investorID)
            //const contribution = isSelected ? ( totalSelected !== 0 ? totalSelected * ((row.subscribed / totalSelected) * 100).toFixed(3) : 0 ) : 0 ;
            row.fdOwnership = isSelected ? (totalSelected !== 0 ? ((row.subscribed / totalSelected) * 100) : 0) : 0;
            const fdOwnership = isSelected ? (totalSelected !== 0 ? ((row.subscribed / totalSelected) * 100) : 0) : 0;
            row.contribution = isSelected ? (totalSelected !== 0 ? (fdOwnership * distributionDetails?.values?.dtAllocationAmount).toFixed(3) : 0) : 0;
            row.distributionID = distributionDetails.values.distributionID
            row.currentDistributionAmount = isSelected ? (totalSelected !== 0 ? ((distributionDetails?.values?.dtAllocationAmount)) * ((row.contribution / totalSelected)) : 0) : 0;
        });

        distributionDetails.setFieldValue("distributionInvestors", distributionDetails.values.distributionInvestors);
    }

    const selectedMethod = () => {
        if (allocationsDetails.length > 0) {
            const method = allocationsDetails.find((item) => item.listItemID === distributionDetails?.values?.dtAllocationType)
            if (method) {
                return method.listItemValue
            }
            else {
                return ""
            }
        } else {
            return ""
        }
    }

    //#region change events
    const handleChange = (name, value) => {
        if (name === "dtAllocationPercentage") {
            const returnValue = calculateAllocationAmount(value);
            distributionDetails.setFieldValue("dtAllocationAmount", returnValue)
        }

        if (name === "dtAllocationAmount") {
            caluculateInvestorGridDetails(selectedInvestors)
        }
        if (name === "dtAllocationType") {
            distributionDetails.setFieldValue('dtAllocationAmount', '')
            distributionDetails.setFieldValue('dtAllocationPercentage', 0)
            //#reseting the field
            distributionDetails.setFieldTouched('dtAllocationPercentage', false);
            distributionDetails.setFieldTouched('dtAllocationAmount', false);
        }
        distributionDetails.setFieldValue(name, value);
    }

    //#region click events
    const onCancelClick = () => {
        distributionDetails.resetForm();
        setSavebtnLoading(false);
        setExecuteBtnLoading(false);
        setDeleteBtnLoading(false);
        setIsSaveEnabled(false);
        setDeletePopup(false);
        setCurrPage(distributionPagesEnum.DISTRIBUTION_ACTIVITY);
        /* getTierDetailsbyID(distributionDetails.values.distributionID, distributionDetails.values.distributionTierID) */
    }

    const onEditClick = () => {
        setIsSaveEnabled(true);
    }

    const onExecuteClick = () => {
        distributionDetails.values.distributionStatus = distributionStatusEnum.EXECUTED;
        setExecuteBtnLoading(true);
        updateTierDetails();

    }

    const onDeleteClick = () => {
        setDeletePopup(true);
        /* deleteExcistingDistribution(); */
    };

    const onCloseDeletePopup = () => {
        setDeletePopup(false);
    }

    const onInvetsorClick = (selectedids) => {
        setSelectedInvetsors(selectedids);
        caluculateInvestorGridDetails(selectedids)

        if (selectedids.length === 0) {
            distributionDetails.values.distributionInvestors.forEach(row => {
                row.fdOwnership = 0;
                row.currentDistributionAmount = 0;
                // row.contribution = isSelected ? ( totalSelected !== 0 ? totalSelected * ((row.subscribed / totalSelected) * 100).toFixed(3) : 0 ) : 0 ;
            });
            distributionDetails.setFieldValue("distributionInvestors", distributionDetails.values.distributionInvestors);
        }
    };

    const onExecutePopOpen = () => {
        if (selectedInvestors.length > 0) {
            setIsExecutePopup(true);
        } else {
            toast.error("please select atleast one investor",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    };


    const onExecutePopuClose = () => {
        setIsExecutePopup(false);
    };

    const onSaveClick = () => {
        console.log(distributionDetails.values.distributionID)
        if (selectedInvestors?.length > 0) {
            if (distributionDetails.values.distributionID === 0 || distributionDetails.values.distributionTierID === 0) {
                createTier();
            }
            else {
                setSavebtnLoading(true);
                updateTierDetails();
            }
        }
        else {
            toast.error("please select atleast one investor",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region api get calls
    const getDistrubutionStatues = async () => {
        const data = await getKeyValuePairs("DistributionType");
        if (data.responseCode === 200) {
            setDistributionsTypesDetails(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    const getDistrubutionSorces = async () => {
        const data = await getKeyValuePairs("DistributionSource");
        if (data.responseCode === 200) {
            setdtSourceIDDetails(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    const getAllocationDetails = async () => {
        const data = await getKeyValuePairs("AllocationTypes");
        if (data.responseCode === 200) {
            setAllocationsDetails(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    const getPaymentMethodDetails = async () => {
        const data = await getKeyValuePairs("PaymentMethod");
        if (data.responseCode === 200) {
            setPaymentMethodDetails(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    const getTierStatues = async () => {
        const data = await getKeyValuePairs("Tiers");
        if (data.responseCode === 200) {
            setTierStatues(data.responseData);
        }
        else {
            //console.log('unable to fetch data)
        }
    }

    const getTierDetailsbyID = async (distributionID, tierID) => {
        const fundId = secureLocalStorage.getItem("FundId");
        const data = await getFundDistributionsbyID(fundId, distributionID, tierID);
        if (data.responseCode === 200) {
            distributionDetails.setValues(data.responseData);
            const excistingInvestors = data.responseData?.distributionInvestors
                ?.filter(item => item.distributionID > 0)
                ?.map(item => item.investorID)
            setSelectedInvetsors(excistingInvestors);
        }
        else {
            //console.log('unable to fetch data)
        }
    }


    //#region api post calls
    const createTier = async () => {
        setSavebtnLoading(true);
        distributionDetails.values.distributionStatus = distributionStatusEnum.ACTIVE;
        distributionDetails.values.dtAllocationAmount = parseInt(distributionDetails.values.dtAllocationAmount);
        const data = await createNewDistributionTier(distributionDetails?.values);
        if (data.responseCode === 200) {
            /* if(distributionDetails.values.distributionID === 0) */
            distributionDetails.values.distributionTierID = data.responseData.distributionTierID;
            distributionDetails.values.distributionID = data.responseData.distributionID;
            toast.success("Tier Created Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onCancelClick();
            getPreviosDistributions();
        } else {
            onCancelClick();
            toast.error("unable to create tier. please try again",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region api update calls
    const updateTierDetails = async () => {
        distributionDetails.values.dtAllocationAmount = parseInt(distributionDetails.values.dtAllocationAmount);
        const data = await updateDistributionTierDetails(distributionDetails?.values);
        if (data.responseCode === 200) {
            toast.success("Tier Details Updated Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            onCancelClick();
            getPreviosDistributions();
        } else {
            onCancelClick();
            toast.error("error updating tier details",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region api delete calls
    const deleteExcistingDistribution = async () => {
        const fundId = secureLocalStorage.getItem("FundId");
        setDeleteBtnLoading(true);
        const data = await deleteDistribution(fundId, distributionDetails?.values.distributionID);
        if (data.responseCode === 200) {
            setDeleteBtnLoading(false);
            onCancelClick();
            getPreviosDistributions();
            toast.success("Deleted Successfully",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else {
            setDeleteBtnLoading(false);
            onCancelClick();
            toast.error("unable to delete the distribution",
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
    }

    //#region useeffect
    useEffect(() => {
        getDistrubutionStatues();
        getDistrubutionSorces();
        getAllocationDetails();
        getPaymentMethodDetails();
        getTierStatues();
    }, [])

    useEffect(() => {
        distributionDetails.setValues(selectedDistribution);
        const excistingInvestors = selectedDistribution?.distributionInvestors
            ?.filter(item => item.distributionID > 0)
            ?.map(item => item.investorID)
        setSelectedInvetsors(excistingInvestors);
        setIsSaveEnabled(selectedDistribution.distributionID === 0 || selectedDistribution?.distributionTierID === 0 ? true : false)
    }, [selectedDistribution])

    //#region formik validations
    const distributionValidationSchema = yup.object().shape({
        distributionAmount: yup.number()
            .typeError('Must be a number')
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.GREATER_THAN_ZERO),
        nav: yup.number()
            .typeError('Must be a number')
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.GREATER_THAN_ZERO),
        dtAllocationType: yup.number()
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.REQUIRED),
        dtSourceID: yup.number()
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.REQUIRED),
        dtPaymentMethodID: yup.number()
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.REQUIRED),
        dtTypeID: yup.number()
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.REQUIRED),
        dtCurrency: yup.mixed()
            .required(validationsConstants.REQUIRED)
            .test('not-empty', validationsConstants.REQUIRED, value => value !== null && value !== undefined && value !== ''),
        dtAllocationAmount: yup.number().typeError(validationsConstants.REQUIRED)
            .required(validationsConstants.REQUIRED)
            .moreThan(0, validationsConstants.REQUIRED)
            .test('is-less-than-distributionAmount', 'Allocation Amount must be less than the available distribution amount', function (value) {
                const sumOfAllTiersAmount = distributionsDetails
                    .find(distribution => distribution.distributionID === selectedDistribution.distributionID)
                    ?.allTiers
                    ?.filter(tier => tier.distributionStatus !== distributionStatusEnum.ACTIVE) // Exclude active status
                    ?.reduce((sum, tier) => sum + tier.dtAllocationAmount, 0) || 0;
                return parseInt(value) <= parseInt(distributionDetails.values.distributionAmount - sumOfAllTiersAmount);
            }),
        dtAllocationPercentage: yup.string().typeError(validationsConstants.REQUIRED).when([], (percentageallocation, schema) => {
            if (selectedMethod() === distributionMethodEnum.PERCENTAGE_ALLOCATION) {
                return schema
                    .required(validationsConstants.REQUIRED)
                    .test('is-valid-percentage', 'Percentage cannot exceed 100', function (value) {
                        return parseFloat(value) <= 100;
                    });
                /* .test('is-less-than-distributionAmount', 'Allocation Amount must be less than the available distribution amount', function (value) {
                    const sumOfAllTiersAmount = distributionsDetails
                            .find(distribution => distribution.distributionID === selectedDistribution.distributionID)
                            ?.allTiers
                            ?.reduce((sum, tier) => sum + tier.dtAllocationAmount, 0) || 0;
                    return calculateAllocationAmount(value) <= distributionDetails.values.distributionAmount - sumOfAllTiersAmount;
                }) */
            }
            return schema;
        }),
    });

    const distributionDetails = useFormik({
        initialValues: initialAddDistributionValues,
        validationSchema: distributionValidationSchema,
        onSubmit: (values) => {
            onSaveClick();
        },
    });

    useEffect(() => {
        caluculateInvestorGridDetails(selectedInvestors)
    }, [distributionDetails?.values?.dtAllocationAmount])

    //#region return
    return (
        <div>
            <div className='wrapper mt2-page child-margin-15'>
                <div className='space-between'>
                    <h3>
                        Distribution Activity
                    </h3>
                    <div className='child-row-margin-5'>
                        {
                            isSaveEnabled &&
                            <div>
                                <LoadingButton
                                    variant='outlined'
                                    size="large"
                                    onClick={onDeleteClick}
                                    startIcon={<Delete />}
                                    loading={deletebtnLoading}
                                    loadingPosition="start"
                                    disabled={
                                        distributionsDetails.find((distribution) => distribution.distributionID === selectedDistribution.distributionID)?.allTiers?.some((tier) => {
                                            return tier.distributionStatus === distributionStatusEnum.EXECUTED;
                                        })
                                        || selectedDistribution.distributionTierID === 0
                                    }>
                                    <p className={`actionButtonLabel`}>
                                        DELETE DISTRIBUTION
                                    </p>
                                </LoadingButton>
                            </div>
                        }
                        <LoadingButton
                            variant="contained"
                            className='btn-primary'
                            startIcon={<SendIcon />}
                            loading={executebtnLoading}
                            disabled={
                                !isSaveEnabled ||
                                distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED ||
                                selectedDistribution.distributionTierID === 0
                            }
                            onClick={onExecutePopOpen}
                            loadingPosition="start">
                            <p className={`actionButtonLabel`}>
                                EXECUTE TIER {distributionDetails?.values?.tierID}
                            </p>
                        </LoadingButton>
                        {/*  <Button 
                            variant="outlined" 
                            className='btn-primary'
                            startIcon={<AddIcon />}
                            onClick={()=>{}}>
                            ADD DISTRIBUTION 
                        </Button> */}
                    </div>
                </div>
                <div className='white-card child-margin-15'>
                    <form onSubmit={distributionDetails.handleSubmit} noValidate="novalidate">
                        <div className='space-between'>
                            <div className='distibution-status-label align-items-center'>
                                {distributionDetails.values.distributionStatus}
                            </div>
                            <div className='child-row-margin-5'>
                                {
                                    isSaveEnabled ?
                                        <div className='child-row-margin-5'>
                                            <div>
                                                <LoadingButton
                                                    variant='outlined'
                                                    className='btn-primary'
                                                    onClick={onCancelClick}
                                                    startIcon={<CancelIcon />}
                                                    loadingPosition="start">
                                                    <p className={`actionButtonLabel`}>
                                                        CANCEL
                                                    </p>
                                                </LoadingButton>
                                            </div>
                                            <div>
                                                <LoadingButton
                                                    variant='contained'
                                                    className='btn-primary'
                                                    loading={savebtnloading}
                                                    onClick={distributionDetails.handleSubmit}
                                                    startIcon={<SaveIcon />}
                                                    loadingPosition="start">
                                                    <p className={`actionButtonLabel`}>
                                                        SAVE
                                                    </p>
                                                </LoadingButton>
                                            </div>
                                        </div> :
                                        <Button
                                            variant="outlined"
                                            className='btn-primary'
                                            startIcon={<EditIcon />}
                                            disabled={distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                            onClick={onEditClick}>
                                            EDIT
                                        </Button>
                                }
                            </div>
                        </div>
                        <div className='distribution-row'>
                            <div className='distributionbox'>
                                <ALTTextField
                                    textFormatType={textFormatTypeEnum.US_CURRENCY}
                                    name="distributionAmount"
                                    label="Total Distribution Amount"
                                    value={distributionDetails?.values?.distributionAmount || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED || selectedDistribution.distributionID !== 0}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.distributionAmount && Boolean(distributionDetails.errors.distributionAmount)}
                                    errorMessage={distributionDetails.touched.distributionAmount && distributionDetails.errors.distributionAmount} />
                            </div>
                            <div className='distributionbox'>
                                <ALTTextField
                                    textFormatType={textFormatTypeEnum.US_CURRENCY}
                                    name="nav"
                                    label="NAV Distribution Amount"
                                    value={distributionDetails?.values?.nav || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED /* || selectedDistribution.distributionID !== 0 */}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.nav && Boolean(distributionDetails.errors.nav)}
                                    errorMessage={distributionDetails.touched.nav && distributionDetails.errors.nav} />
                            </div>
                        </div>
                        <div className='display-row-items-3'>
                            <div>
                                <SelectField
                                    name="dtAllocationType"
                                    label="Distribution Method"
                                    value={distributionDetails?.values?.dtAllocationType || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    options={allocationsDetails?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.dtAllocationType && Boolean(distributionDetails.errors.dtAllocationType)}
                                    errorMessage={distributionDetails.touched.dtAllocationType && distributionDetails.errors.dtAllocationType} />
                            </div>
                            {
                                distributionDetails?.values?.dtAllocationType === 0 &&
                                <>
                                    <div></div>
                                    <div></div>
                                </>
                            }
                            {
                                selectedMethod() === distributionMethodEnum.PERCENTAGE_ALLOCATION &&
                                <>
                                    <div>
                                        <ALTTextField
                                            textFormatType={textFormatTypeEnum.PERCENTAGE}
                                            name="dtAllocationPercentage"
                                            label="Percentage Allocation"
                                            value={distributionDetails?.values?.dtAllocationPercentage || 0}
                                            onChange={(name, value) => handleChange(name, value)}
                                            required={true}
                                            readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                            onBlur={distributionDetails.handleBlur}
                                            error={distributionDetails.touched.dtAllocationPercentage && Boolean(distributionDetails.errors.dtAllocationPercentage)}
                                            errorMessage={distributionDetails.touched.dtAllocationPercentage && distributionDetails.errors.dtAllocationPercentage} />
                                    </div>
                                    <div className='datefeild'>
                                        <ALTTextField
                                            textFormatType={textFormatTypeEnum.US_CURRENCY}
                                            name="dtAllocationAmount"
                                            label="Calculated Percentage Amount"
                                            value={distributionDetails?.values?.dtAllocationAmount || []}
                                            onChange={(name, value) => handleChange(name, value)}
                                            required={true}
                                            readOnly={true}
                                            onBlur={distributionDetails.handleBlur}
                                            error={distributionDetails.touched.dtAllocationAmount && Boolean(distributionDetails.errors.dtAllocationAmount)}
                                            errorMessage={distributionDetails.touched.dtAllocationAmount && distributionDetails.errors.dtAllocationAmount} />
                                    </div>
                                </>
                            }
                            {
                                selectedMethod() === distributionMethodEnum.FIXED_ALLOCATION &&
                                <>
                                    <div>
                                        <ALTTextField
                                            textFormatType={textFormatTypeEnum.US_CURRENCY}
                                            name="dtAllocationAmount"
                                            label="Fixed Allocation"
                                            value={distributionDetails?.values?.dtAllocationAmount || []}
                                            onChange={(name, value) => handleChange(name, value)}
                                            required={true}
                                            readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                            onBlur={distributionDetails.handleBlur}
                                            error={distributionDetails.touched.dtAllocationAmount && Boolean(distributionDetails.errors.dtAllocationAmount)}
                                            errorMessage={distributionDetails.touched.dtAllocationAmount && distributionDetails.errors.dtAllocationAmount} />
                                    </div>
                                    <div></div>
                                </>
                            }
                            <div>
                                <SelectField
                                    name="tierID"
                                    label="Tier Name"
                                    value={distributionDetails?.values?.tierID || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    readOnly={true}
                                    options={tierStatues?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.tierID && Boolean(distributionDetails.errors.tierID)}
                                    errorMessage={distributionDetails.touched.tierID && distributionDetails.errors.tierID} />
                            </div>
                            <div className='datefeild'>
                                <DateField
                                    name="dtDate"
                                    label="Distribution Date"
                                    value={distributionDetails?.values?.dtDate || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    disableFuture={false}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED} />
                            </div>
                            <div>
                                <TextField
                                    name="distributionID"
                                    label="Distribution Number/ID"
                                    value={distributionDetails?.values?.distributionID}
                                    onChange={(name, value) => handleChange(name, value)}
                                    disabled={true}
                                    variant={FieldVariants.STANDARD}
                                    fullWidth
                                    InputProps={{
                                        readOnly: !isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        focused: true,
                                    }} />
                            </div>
                            <div>
                                <SelectField
                                    name="dtCurrency"
                                    label="Currency"
                                    value={distributionDetails?.values?.dtCurrency || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    options={currencyDropdownDetails}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.dtCurrency && Boolean(distributionDetails.errors.dtCurrency)}
                                    errorMessage={distributionDetails.touched.dtCurrency && distributionDetails.errors.dtCurrency} />
                            </div>
                            <div className='datefeild'>
                                <DateField
                                    name="dtPaymentDate"
                                    label="Payment Date"
                                    value={distributionDetails?.values?.dtPaymentDate || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    disableFuture={false}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED} />
                            </div>
                            <div>
                                <SelectField
                                    name="dtSourceID"
                                    label="Distribution Source"
                                    value={distributionDetails?.values?.dtSourceID || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    options={distibutionSourceDetails?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.dtSourceID && Boolean(distributionDetails.errors.dtSourceID)}
                                    errorMessage={distributionDetails.touched.dtSourceID && distributionDetails.errors.dtSourceID} />
                            </div>
                            <div>
                                <SelectField
                                    name="distributionStatus"
                                    label="Status of the Distribution"
                                    value={distributionDetails?.values?.distributionStatus || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    options={distributionDropdownDetails}
                                    readOnly={true}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.distributionStatus && Boolean(distributionDetails.errors.distributionStatus)}
                                    errorMessage={distributionDetails.touched.distributionStatus && distributionDetails.errors.distributionStatus} />
                            </div>
                            <div>
                                <SelectField
                                    name="dtPaymentMethodID"
                                    label="Payment Method"
                                    value={distributionDetails?.values?.dtPaymentMethodID || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                    options={paymentMethodDetails?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.dtPaymentMethodID && Boolean(distributionDetails.errors.dtPaymentMethodID)}
                                    errorMessage={distributionDetails.touched.dtPaymentMethodID && distributionDetails.errors.dtPaymentMethodID} />
                            </div>
                            <div>
                                <SelectField
                                    name="dtTypeID"
                                    label="Distribution Type"
                                    value={distributionDetails?.values?.dtTypeID || []}
                                    onChange={(name, value) => handleChange(name, value)}
                                    required={true}
                                    readOnly={!isSaveEnabled || distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED}
                                    options={dtTypeIDsDetails?.map(option => ({ label: option?.listItemValue, value: option?.listItemID }))}
                                    onBlur={distributionDetails.handleBlur}
                                    error={distributionDetails.touched.dtTypeID && Boolean(distributionDetails.errors.dtTypeID)}
                                    errorMessage={distributionDetails.touched.dtTypeID && distributionDetails.errors.dtTypeID} />
                            </div>
                        </div>
                    </form>
                    <div className={'height-500'}>
                        <AltDataGridPro
                            columns={columns}
                            rows={
                                distributionDetails.values?.distributionInvestors?.length > 0 ?
                                    distributionDetails.values.distributionInvestors.map((item) => {
                                        return {
                                            id: item.investorID,
                                            ...item
                                        };
                                    }) : []
                            }
                            onRowSelectionModelChange={onInvetsorClick}
                            checkboxSelection
                            isRowSelectable={(params) => isSaveEnabled && !(distributionDetails.values.distributionStatus === distributionStatusEnum.EXECUTED)}
                            rowSelectionModel={selectedInvestors}
                            hideFooter />
                    </div>
                </div>
            </div>
            {
                isExecutePopup &&
                <ExecuteDistributionPopup
                    open={isExecutePopup}
                    onClose={onExecutePopuClose}
                    loading={executebtnLoading}
                    distributionDetails={distributionDetails?.values}
                    onExecuteClick={onExecuteClick}
                    selectedInvestors={selectedInvestors}
                    gridColumns={columns} />
            }
            {
                deletePopup &&
                <DeleteDistributionPopup
                    open={deletePopup}
                    onClose={onCloseDeletePopup}
                    deletebtnLoading={deletebtnLoading}
                    deleteExcistingDistribution={deleteExcistingDistribution} />
            }
            <ToastContainer />
        </div>
    )
}

export default AddDistributionMain;