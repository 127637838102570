import React, { useState, useEffect } from 'react';
import { Checkbox, Chip, Grid, Button } from '@mui/material';
import Text from "../../common/Text/Text"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AIPDataGrid from '../../common/DataGrid/AIPDataGrid';
import { justifyContentAllignItem } from '../../CommonCss';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ActionButton from '../../common/ActionButton/ActionButton';
import SearchIcon from '@mui/icons-material/Search';
import { CommunicationType } from '../../../utils/enum';
import { getInvestorsandGroupsList } from '../Services/GroupsServices';
import { PostNewNotifications } from '../Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { getAllFunds } from '../Services/IRServices';
import AttachFile from './AttachFile';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import UploadMultiplefiles from '../../KYCVerification/Popups/UploadMultiplefiles';
import FileUploadButton from '../../common/FileUploadButton/FileUploadButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import ALTTextField from '../../common/input-fields/ALTTextField';
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from '@mui/icons-material/Person';
import RowRadioButtonsField from '../../common/RadioButtonGroup/RadiobuttonsField';
import { textFormatTypeEnum } from '../../common/TextInput/appInputenum';
import { width } from '@mui/system';
import { InvestorCommunicarionToastMessages } from '../../../utils/AppConstants';


const CustomEmail = (props) => {

  const { DraftNotification,
    SelectedSentNotificationfromParent,
    selectedSentItemFromParent
    , selectedParentDraftNotification,
    DraftNotificationsDetails, selectedInvestorsFromParent,
    selectedActionItem, getDraft, renderComponent, setRenderComponent, notificationFiles,
    InvestorsItems, selectedTabFromParent, selectedSubjectFromParent, selectedContentFromParent,
    SentNotificationsDetails, selectedGroup, getNotificationsCount } = props;

  //## --- Variables --- ##//
  const [openDialog, setOpenDialog] = useState(false);
  const [investorsList, setInvestorsList] = useState(InvestorsItems || []);
  const [selectedInvestors, setSelectedInvestors] = useState(selectedInvestorsFromParent || []);
  const [submittedContent, setSubmittedContent] = useState(selectedContentFromParent || '');
  const [emailSubject, setEmailSubject] = useState(selectedSubjectFromParent || '');
  const [loading, setLoading] = useState(false);
  const [saveLoader, setsaveLoader] = useState(false)
  const [DraftNotifications, setDraftNotifications] = useState(DraftNotificationsDetails || []);
  const [SentNotifications, setSentNotifications] = useState(SentNotificationsDetails || []);
  const [fundsDropdown, setfundsDropdown] = useState(null);
  const [actionItemFromParent, setActionItemFromParent] = useState(selectedActionItem || '')
  const [selectedDraftNotification, setSelectedDraftNotification] = useState(DraftNotification);
  const [selectedSentNotification, setSelectedSentNotification] = useState(SelectedSentNotificationfromParent);
  const [NotificationType, setNotificationType] = useState('Internal Custom Notification');
  const [showpopup, setShowpopup] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});



  const [selectedTab, setSelectedTab] = useState(selectedTabFromParent || 1);

  const radibuttonLabels = [
    { label: "Redirect", value: 1 },
    { label: "Message", value: 2 },
  ]

  const handleRadioChange = (value) => {
    const intValue = parseInt(value, 10);
    setSelectedTab(intValue);
  }

  const Columns = [
    {
      "id": 1,
      "field": 'id',
      "headerName": '_',
      "width": 10,
      renderCell: (params) => (
        <Checkbox
          checked={(selectedInvestors?.filter(u => u.id === params.id)?.length > 0)} />
      )
    },
    {
      "id": 2,
      "field": 'displayName',
      "headerName": 'Name',
      "width": 250,
    },
    {
      "id": 3,
      "field": 'emailAddress',
      "headerName": 'Email Address',
      "width": 250
    },
    {
      "id": 4,
      "field": 'linkedFunds',
      "headerName": 'Funds invested in',
      "width": 250
    },
    {
      id: 5,
      field: "isGroup",
      headerName: '-',
      width: 20,
      renderCell: (params) => (
        params.row.isGroup ? <GroupIcon /> : <PersonIcon />
      )
    }

  ];

  const initalFilesData = [
    {
      id: 1,
      file: "",
    }
  ]
  const [files, setFiles] = useState(initalFilesData);

  //## --- End of Variables --- ##//

  //## -- Events --- ##//

  const [inputValue, setInputValue] = useState('');

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return options;
    }
    return options.filter(option =>
      option.displayName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const onClose = () => {
    setShowpopup(false);
    setFiles([
      {
        id: 1,
        file: "",
        path: ""
      }
    ]);
  }
  const onSubmit = () => {
    const errors = {}
    if (files[0].file === "") {
      errors.file = "This field is required"
    } else {
      setErrorMessages({ ...errorMessages, file: null });
    }
    setShowpopup(false);
  }
  const onOpen = () => {
    setShowpopup(true);
  }
  const handleChange = (field, value) => {
    const errors = {}
    if (field === 'drafts') {
      setSelectedDraftNotification(value);
    }
    if (field === 'Sent Items') {
      setSelectedSentNotification(value);
    }
    if (field === 'actionItem') {
      debugger
      if (value === "Internal Custom Notification") {
        errors.notificationType = "This field is required";
      }
      else {
        setNotificationType(value);
        setErrorMessages({ ...errorMessages, notificationType: null });
        if (props.CommunicationType === CommunicationType.Drafts) {
          setActionItemFromParent(value)
        }
      }
    }
  }

  const handleLinkClick = () => {
    setOpenDialog(true);
  };

  const InvestorSelectedClick = (params) => {
    const isSelected = selectedInvestors.some(investor => investor.id === params.id);

    if (isSelected) {
      setSelectedInvestors(prevSelectedInvestors =>
        prevSelectedInvestors.filter(investor => investor.id !== params.id)
      );
    } else {
      setSelectedInvestors(prevSelectedInvestors => [
        ...prevSelectedInvestors,
        ...investorsList?.filter(u => params.id === u.id)
      ]);
    }
  };

  const onAutoCompleteChange = (e, value) => {
    const latestSelection = value?.map(u => u.id);

    const newItems = investorsList.filter(u => latestSelection?.includes(u.id));
    setSelectedInvestors(investorsList.filter(u => latestSelection?.includes(u.id)));
  }

  const EmailReciepients = selectedInvestors.reduce((acc, item) => {
    const idPrefix = item.id.charAt(0);
    if (idPrefix === "G") {
      acc.groups.push(item.id);
    } else if (idPrefix === "U") {
      acc.users.push(item.id);
    }
    return acc;
  }, { groups: [], users: [] });

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedInvestors([])
  };

  const handleCancelClick = () => {
    if (props.CommunicationType !== CommunicationType.Drafts) {
      setSelectedInvestors([]);
      setSubmittedContent("");
      setEmailSubject('');
      setNotificationType('');
      setFiles(initalFilesData)
      setLoading(false)
      setsaveLoader(false)
    } else if (props.CommunicationType === CommunicationType.Drafts) {
      setActionItemFromParent(selectedActionItem)
      const crntSelectedNotification = DraftNotifications?.find(u => u.notificationSubject === selectedDraftNotification)
      const oldSelectedUsersAndGroups = crntSelectedNotification?.notificationToUserIDs + "," + crntSelectedNotification?.notificationToGroupIDs;
      setSelectedInvestors(investorsList.filter(u => oldSelectedUsersAndGroups?.includes(u.id)));
      setEmailSubject(crntSelectedNotification?.notificationSubject ? crntSelectedNotification?.notificationSubject : '');
      setSubmittedContent(crntSelectedNotification?.notificationBody ? crntSelectedNotification?.notificationBody : " ");
      setNotificationType(fundsDropdown?.filter(u => u.label === crntSelectedNotification?.notificationType)[0]?.value || 'Internal Custom Notification');
    }
  };

  const handleSaveDialog = () => {
    setOpenDialog(false);
  };

  const handleSendEmailClick = (content) => {
    setSubmittedContent(content);

  }
  const handleSubjectChange = (name, value) => {
    const errors = {};
    if (name === 'Subject') {
      if (value?.trim() === "") {
        errors.emailSubject = "This field is required";
        setEmailSubject(value);
      } else {
        setEmailSubject(value);
        setErrorMessages({ ...errorMessages, emailSubject: null });
      }
    }
    if (name === 'Enter your discussion') {
      if (value?.trim() === "") {
        errors.content = "This field is required";
        setSubmittedContent(value)
      } else {
        setSubmittedContent(value)
        setErrorMessages({ ...errorMessages, content: null });
      }
    }
  };

  const handleFileUpload = (name, file) => {
    const findIndex = files.findIndex((item) => item.id === parseInt(name));
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Calculate file size in MB

      if (fileSizeInMB > 1) {
        toast.warning("Something went wrong,Please Try Again After Sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      } else {
        const formData = new FormData();
        formData.append(name, file, file.name);
        setFiles((prevItems) => {
          return prevItems.map((item) => {
            if (item.id === parseInt(name)) {
              item.file = file;
            }
            return item;
          });
        });
      }
    } else {
      setFiles((prevItems) => {
        return prevItems.map((item) => {
          if (item.id === parseInt(name)) {
            item.file = "";
          }
          return item;
        });
      });
    }
  };

  // useEffect(() => {
  //   const crntSelectedNotification = (props.CommunicationType === CommunicationType.Drafts || props.CommunicationType === CommunicationType.Deleted)
  //     ? DraftNotifications?.find(u => u.notificationSubject === selectedDraftNotification)
  //     : SentNotifications?.find(u => u.notificationSubject === selectedSentNotification);
  //   const oldSelectedUsersAndGroups = crntSelectedNotification?.notificationToUserIDs + "," + crntSelectedNotification?.notificationToGroupIDs;
  //   setSelectedInvestors(investorsList.filter(u => oldSelectedUsersAndGroups?.includes(u.id)));
  //   setEmailSubject(crntSelectedNotification?.notificationSubject ? crntSelectedNotification?.notificationSubject : '');
  //   setSubmittedContent(crntSelectedNotification?.notificationBody ? crntSelectedNotification?.notificationBody : " ");
  //   setNotificationType(fundsDropdown?.filter(u => u.label === crntSelectedNotification?.notificationType)[0]?.value || 'Internal Custom Notification');
  // }, []);




  //### -- End of Events --- ##//

  //## --- Service Calls --- ##//

  const getInvestors = async () => {
    const data = await getInvestorsandGroupsList();
    if (data?.responseCode === 200) {
      const sortedInvestorsList = data.responseData.sort((a, b) => a.displayName.localeCompare(b.displayName));
      setInvestorsList(sortedInvestorsList);
      if (selectedGroup && selectedGroup["id"] !== null) {
        const currSelectedGInvestor = data?.responseData.find(u => u.itemID === selectedGroup["id"]);
        setSelectedInvestors([currSelectedGInvestor]);
        selectedGroup["id"] = null;
      }

    } else {
      // Handle error if needed
    }
  }

  const getFundNames = async () => {
    const data = await getAllFunds();
    if (data?.responseCode == 200) {
      const fundNameOptions =

        [
          { label: "Select", value: "Internal Custom Notification", redirectURL: "" },
          { label: "Marketplace", value: "Marketplace", redirectURL: "/marketplace" },
          { label: "Personal information", value: "Personal information", redirectURL: "/user-profile/?goto=0" },
          { label: "Banking information", value: "Banking information", redirectURL: "/user-profile/?goto=1" },
          { label: "Investment profile", value: "Investment profile", redirectURL: "/user-profile/?goto=2" },
          { label: "Subscription details", value: "Subscription details", redirectURL: "/user-profile/?goto=3" },
          ...(data?.responseData?.map(fund => ({ label: fund.fundName, value: fund.fundID, redirectURL: "/fund-details/" + fund.fundID })))
        ]
      setfundsDropdown(fundNameOptions);
    } else {
      //toast.warning(data?.responseData,
      //{ position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  useEffect(() => {
    getInvestors();
    getFundNames();
  }, []);

  const groupIdsString = EmailReciepients.groups.join(",");
  const userIdsString = EmailReciepients.users.join(",");

  const handleSendEmail = async (sendEmail) => {

    const errors = {};

    if (selectedTab === 1) {
      // Validate selected investors
      if (selectedInvestors.length === 0) {
        errors.selectedInvestors = "This field is required";
      }


      // Validate email subject
      if (emailSubject?.trim() === "") {
        errors.emailSubject = "This field is required";
      } else if (emailSubject.length > 40) {
        errors.emailSubject = "Please keep the content within the allowed character limit";
      }

      // Validate content
      if (submittedContent?.trim() === "") {
        errors.content = "This field is required";
      } else if (submittedContent.length > 140) {
        errors.content = "Please keep the content within the allowed character limit"
      }

      if (NotificationType === "Internal Custom Notification") {
        errors.notificationType = "This field is required";
      }
    } else {
      if (selectedInvestors.length === 0) {
        errors.selectedInvestors = "This field is required";
      }

      // Validate email subject
      if (emailSubject?.trim() === "") {
        errors.emailSubject = "This field is required";
      } else if (submittedContent.length > 5000) {
        errors.content = "Please keep the content within the allowed character limit"
      }

      // Validate content
      if (submittedContent?.trim() === "") {
        errors.content = "This field is required";
      } else if (submittedContent.length > 5000) {
        errors.content = "Please keep the content within the allowed character limit"
      }

      if (files[0].file === "") {
        errors.file = "This field is required"
      }
    }


    // Display error messages
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(sendEmail === true ? true : false)
      setsaveLoader(sendEmail === false ? true : false)

      const requestBody = {
        notificationDetailsID: props.CommunicationType === CommunicationType.Drafts
          ? DraftNotifications.find(u => u.notificationSubject === selectedDraftNotification)?.notificationDetailsID
          : 0,
        notificationTypeID: 0,
        notificationType: NotificationType ? isNaN(parseInt(NotificationType)) ? NotificationType : "All funds from marketplace" : "Internal Custom Notification",
        notificationToUserIDs: userIdsString,
        notificationToGroupIDs: groupIdsString,
        notificationByUserID: secureLocalStorage.getItem("userId"),
        notificationSubject: emailSubject,
        notificationBody: submittedContent,
        notificationHasAction: NotificationType === 'Internal Custom Notification' ? false : true,
        notificationOnID: isNaN(parseInt(NotificationType)) ? 0 : fundsDropdown?.find(u => u.value.toString() === NotificationType)?.value,
        notificationURL: fundsDropdown?.find(u => u.value === parseInt(NotificationType))?.redirectURL || "",
        createdBy: 1,
        createdDate: "2024-02-12T12:34:43.807Z",
        updatedBy: 1,
        updatedDate: "2024-02-12T12:34:43.807Z",
        saveAsDraft: !sendEmail,
        notificationSentOn: "2024-02-12T12:34:43.807Z"
      }
      const formData = new FormData();
      if (files.length > 0) {
        files.forEach((file) => {
          if (file.file !== "") {
            formData.append('fileList', file.file, `${file.file.name}`);
          }
        });
      }
      formData?.append("appNotificationDetails", JSON.stringify(requestBody));
      const response = await PostNewNotifications(formData);
      if (response?.responseCode === 200) {
        if (sendEmail) {
          toast.success(InvestorCommunicarionToastMessages.NOTIFICATION_SENT, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
          });
          setLoading(false)
          setsaveLoader(false)
        } else {
          toast.success(InvestorCommunicarionToastMessages.NOTIFICATION_DRAFT, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
          });
          setLoading(false)
          setsaveLoader(false)
        }
        if (props.CommunicationType !== CommunicationType.Drafts) {
          handleCancelClick();
        }
        if (getNotificationsCount) {
          getNotificationsCount();
        }
      }
      else {
        toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      }
      // You can perform additional actions or API calls here
    }
  }
  //## --- End of Service Calls --- ##//

  useEffect(() => {
    if (props.CommunicationType === CommunicationType.Drafts) {
      getDraft()
    } else {
      // setSubmittedContent('');
      // setEmailSubject('');
      // setSelectedInvestors([])
      setNotificationType('Internal Custom Notification')
      //setErrorMessages({})
    }
  }, [selectedTab])

  //#region subject and content character limit
  useEffect(() => {
    const errors = {}
    if (selectedTab === 1) {
      if (submittedContent.length > 140) {
        errors.content = "Please keep the content within the allowed character limit"
      }
      if (emailSubject.length > 40) {
        errors.emailSubject = "Please keep the content within the allowed character limit";
      }
    }

    if (selectedTab === 2) {
      if (submittedContent.length > 5000) {
        errors.content = "Please keep the content within the allowed character limit"
      }
      if (emailSubject.length > 40) {
        errors.emailSubject = "Please keep the content within the allowed character limit";
      }
    }
    setErrorMessages(errors)
  }, [submittedContent, emailSubject, selectedTab])

  return (
    <>{renderComponent && <>
      <Grid container>

        <Grid item xs={12} >
          <h4>
            {props.CommunicationType === CommunicationType.NewNotification ? ""
              : props.CommunicationType === CommunicationType.Drafts ? ""
                : props.CommunicationType === CommunicationType.SentNotifications ? ""
                  : "Groups"}
          </h4>
        </Grid>
      </Grid>

      <Grid container xs={12} marginBottom={2} className='whiteCard'>
        <Grid container xs={12} marginBottom={3}>
          <Grid item xs={2.5} sx={{ marginTop: '7px' }}>
            Choose Notification Type
          </Grid>
          <Grid item xs={9}>
            <RowRadioButtonsField
              label={"select type"}
              value={selectedTab}
              disabled={props.CommunicationType === CommunicationType.SentNotifications}
              options={radibuttonLabels}
              onChange={handleRadioChange} />
          </Grid>
        </Grid>
        <Grid item container xs={12} marginBottom={3}>
          <Grid item xs={11} >
            <Autocomplete
              loading
              multiple
              id="emailTo"
              size="small"
              sx={{ width: "100%" }}
              options={investorsList}
              inputValue={inputValue}
              filterOptions={filterOptions}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              getOptionLabel={investorsList ?
                (option => (
                  <React.Fragment>
                    {option.isGroup ? <GroupIcon style={{ marginRight: '5px' }} /> : <PersonIcon style={{ marginRight: '5px' }} />}
                    {option.displayName}
                  </React.Fragment>
                )) : "Loading..."
              }
              disabled={props.CommunicationType === CommunicationType.SentNotifications || props.CommunicationType === CommunicationType.Deleted}
              value={selectedInvestors}
              onChange={onAutoCompleteChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option?.displayName}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="Recipients"
                  placeholder="Recipients"
                  error={errorMessages.selectedInvestors ? true : false}
                  helperText={errorMessages.selectedInvestors}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} marginTop={0.5}>
            <div
              className={`IC-searchbtn ${props.CommunicationType === CommunicationType.SentNotifications || props.CommunicationType === CommunicationType.Deleted ? 'disablediv' : ''}`}
              onClick={handleLinkClick}
            >
              <SearchIcon
                sx={{}}
                fontSize='large'
              />
            </div>
          </Grid>
          <Grid item xs={0.5}>
            <></>
          </Grid>
        </Grid>
        <Grid item container xs={12} marginBottom={3}>
          <Grid item xs={12} >
            <ALTTextField
              multiline={true}
              rows={1}
              disabled={props.CommunicationType === CommunicationType.SentNotifications || props.CommunicationType === CommunicationType.Deleted}
              name="Subject"
              label="Subject"
              //textFormatType={textFormatTypeEnum.ONLY_ALPHABETS}
              value={emailSubject}
              sx={{ width: '100%' }}
              charactersMaxLength={40}
              characterLengthRemaining={emailSubject.length}
              textFormatType={textFormatTypeEnum.characterLength}
              error={errorMessages.emailSubject ? true : false}
              errorMessage={errorMessages.emailSubject}
              onChange={(name, value) => handleSubjectChange(name, value)} />
          </Grid>
          <Grid item xs={0.5} >
            <></>
          </Grid>
          <Grid item xs={12} marginTop={2}>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <AttachFile
              submittedContent={submittedContent}
              setSubmittedContent={setSubmittedContent}
              onChange={handleSubjectChange}
              onAttachClick={onOpen}
              selectedTab={selectedTab}
              NoAttachementRequired={true}
              errorMessages={errorMessages}
              disabled={props.CommunicationType === CommunicationType.SentNotifications || props.CommunicationType === CommunicationType.Deleted}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} marginBottom={3}>
          {
            selectedTab !== 1 && <Grid container xs={12}>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  className="btn-primary3"
                  onClick={onOpen}>
                  < AttachFileIcon />
                  Attach Document
                </Button>
              </Grid>
              {errorMessages.file && (
                <span className='errorMessage'>{errorMessages.file}</span>
              )}
              <Grid item xs={9.5}>
                {
                  files.some((file) => file.file !== "") ? (
                    <div>
                      {files.map((file) => (
                        file.file !== "" ? (
                          <div className="fundRow" key={file.id}>
                            <div className="">
                              <FileUploadButton
                                name={file.id}
                                defaultFile={file.file}
                                label="UPLOAD DOCUMENT"
                                onChange={(name, value) => handleFileUpload(name, value)}
                              />
                            </div>
                          </div>
                        ) : null
                      ))}
                    </div>
                  ) : null
                }
              </Grid>
              <Grid item >
                &nbsp;
              </Grid>
              <Grid item container xs={12} marginBottom={3}>
                {props.CommunicationType !== CommunicationType.NewNotification
                  &&
                  notificationFiles?.map((crntfilename) => (
                    <div className="fundRow" key={crntfilename.id}>
                      <div className="margin-left-10"  >
                        <a href={crntfilename.path}>
                          {crntfilename.file}
                        </a>
                      </div>
                    </div>
                  ))
                }

              </Grid>
            </Grid>
          }

        </Grid>

        <Dialog open={openDialog} onClose={handleClose} fullWidth maxWidth="md" >
          <DialogTitle sx={justifyContentAllignItem}>
            <h6>Investors</h6>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <AIPDataGrid
              columns={Columns}
              rows={investorsList?.map((u) => {
                return {
                  id: u.id, ...u
                }
              }) || []}
              handleRowClick={InvestorSelectedClick}
              onRowsSelectionHandler={() => { }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined' className='btn-primary'>
              <CancelIcon />
              Cancel
            </Button>
            <Button onClick={handleSaveDialog} variant='contained' className='btn-primary2'>
              <AddIcon />
              ADD
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <>
            </>
          </Grid>
          {
            selectedTab === 1 &&
            <Grid item xs={7} marginBottom={2}>
              <label style={{ color: errorMessages.notificationType ? "d32f2f" : '' }}>Action Item</label>
              <div className='fundRow'>
                <select
                  name={"actionItem"}
                  value={(props.CommunicationType === CommunicationType.SentNotifications || props.CommunicationType === CommunicationType.Drafts || props.CommunicationType === CommunicationType.Deleted) ? actionItemFromParent : NotificationType}
                  disabled={props.CommunicationType === CommunicationType.SentNotifications || props.CommunicationType === CommunicationType.Deleted}
                  onChange={(e) => handleChange('actionItem', e.target.value)}
                  style={{
                    width: '400px',
                    marginRight: '10px',
                    border: 'none',
                    borderBottom: '1px solid black',
                    backgroundColor: 'transparent',

                  }}
                >
                  <option value={''} disabled hidden>
                    Select an option
                  </option>
                  {fundsDropdown?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              {errorMessages.notificationType && (
                <span className='errorMessage'>{errorMessages.notificationType}</span>
              )}
            </Grid>
          }
          <Grid item xs={12}>
            {((props.CommunicationType !== CommunicationType.SentNotifications) && (props.CommunicationType !== CommunicationType.Deleted)) &&
              <div className='btnsRight' >
                <ActionButton styleProps={{ margin: '5px' }} variant="outlined" label={'Cancel'} onClick={handleCancelClick} icon={< CloseOutlinedIcon />} disabled={loading === true || saveLoader === true}  > </ActionButton>
                <ActionButton variant="outlined" styleProps={{ margin: '5px' }} label={'Save'} className="btn-primary" loading={saveLoader} onClick={() => handleSendEmail(false)} disabled={selectedInvestors.length === 0 || loading === true} icon={<SaveIcon />}> </ActionButton>
                <ActionButton variant="contained" label={'Send'} styleProps={{ margin: '5px' }} className="btn-primary2" loading={loading} onClick={() => handleSendEmail(true)} disabled={selectedInvestors.length === 0 || saveLoader === true} icon={<SendIcon />}> </ActionButton>
              </div>
            }

          </Grid>
        </Grid>
      </Grid>

      {
        showpopup && <UploadMultiplefiles open={showpopup}
          onClose={onClose} files={files}
          onSubmit={onSubmit}
          investorCommunications={true}
          foldernotrequired={true}
          setFiles={setFiles} />
      }

      <ToastContainer />
    </>}</>
  )
}

export default CustomEmail;