import * as React from 'react';
import Box from '@mui/material/Box';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
import {
  // ToastContainer
  toast
} from 'react-toastify';
import Accordian from '../../../common/Accordian/Accordian';
import { useState, useEffect } from 'react';
import { getSentNotifications } from '../../Services/GroupsServices';
import CustomEmail from '../CustomEmail';
import './styles.css';
import { CommunicationType } from '../../../../utils/enum';
import { getInvestorsandGroupsList } from '../../Services/GroupsServices';
import { LinearProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import {
  //  CircularProgress, 
  TablePagination
} from '@mui/material';
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteSentNotifications } from '../../Services/IRServices';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import secureLocalStorage from 'react-secure-storage';
import CustomNotificationFilters from './CustomNotificationFilters';
import { InvestorCommunicarionToastMessages } from '../../../../utils/AppConstants';


const SentNotifications = (props) => {

  const { renderComponent, setRenderComponent } = props;

  const [sentNotifications, setSentNotifications] = useState(null);
  const [investorsList, setInvestorsList] = useState(null);
  //const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [filteredNotifications, setFilteredNotifications] = useState([]); // For filtered results
  const [page, setPage] = useState(0); // for pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // for pagination

  const getSentItems = async () => {
    const data = await getSentNotifications(secureLocalStorage.getItem('userId'), 'Sent');
    if (data?.responseCode === 200) {
      setSentNotifications(data?.responseData.filter(u =>
        u.notificationType === "Internal Custom Notification" ||
        u.notificationType === "Marketplace" ||
        u.notificationType === "All funds from marketplace" ||
        u.notificationType === "Personal information" ||
        u.notificationType === "Banking information" ||
        u.notificationType === "Investment profile" ||
        u.notificationType === "Subscription details"
      ));
      setFilteredNotifications(data?.responseData.filter(u =>
        u.notificationType === "Internal Custom Notification" ||
        u.notificationType === "Marketplace" ||
        u.notificationType === "All funds from marketplace" ||
        u.notificationType === "Personal information" ||
        u.notificationType === "Banking information" ||
        u.notificationType === "Investment profile" ||
        u.notificationType === "Subscription details"));
      //setLoading(false);
    } else if (data?.responseCode === 404) {
      setSentNotifications([])
      setFilteredNotifications([]);
    }
    else {
      setSentNotifications([])
      setFilteredNotifications([]);
      toast.warning(data?.responseData, { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      //setLoading(false);
      toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  }

  const getInvestors = async () => {
    const data = await getInvestorsandGroupsList();
    if (data?.responseCode === 200) {
      setInvestorsList(data?.responseData);
    } else {
      // Handle error if needed
      toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    //setPage(0);
  };

  useEffect(() => {
    getSentItems();
    getInvestors();
  }, []);

  const selectedInvestors = (crntSelectedNotification) => {
    const oldSelectedUsersAndGroups = [
      ...(crntSelectedNotification?.notificationToUserIDs?.split(",") || []),
      ...(crntSelectedNotification?.notificationToGroupIDs?.split(",") || []),
    ];
     return (investorsList?.filter((u) =>
      oldSelectedUsersAndGroups?.some((id) => id.trim() === u.id.toString())
    ));
  }

  const notificationFiles = (crntSelectedNotification) => {
    if (!crntSelectedNotification?.notificationHasAction) {
      const fileNamesArray = crntSelectedNotification?.fileNames
        ? crntSelectedNotification.fileNames.split(",").map((fileName) => fileName.trim())
        : [];
      const filesData = fileNamesArray.map((fileName, index) => ({
        id: index + 1,
        file: fileName,
        path: `${crntSelectedNotification.containerPath}/${crntSelectedNotification.notificationDetailsID}/${crntSelectedNotification.fileNames}`
      }));
      return (filesData);
    } else {
      return (null);
    }
  }

  const renderBody = (item) => (
    <div>
      <CustomEmail
        CommunicationType={CommunicationType.SentNotifications}
        SelectedSentNotificationfromParent={item?.notificationSubject}
        selectedSentItemFromParent={selectedSentItemFromParent}
        selectedActionItem={item?.notificationOnID === 0 ? item?.notificationType : item?.notificationOnID}
        InvestorsItems={investorsList}
        selectedTabFromParent = {item?.notificationHasAction === true ? 1 : 2}
        renderComponent={renderComponent} setRenderComponent={setRenderComponent}
        selectedSubjectFromParent = {item?.notificationSubject}
        selectedInvestorsFromParent = {selectedInvestors(item)}
        selectedContentFromParent = {item?.notificationBody}
        SentNotificationsDetails={sentNotifications}
        setSentNotifications={setSentNotifications}
        notificationFiles={notificationFiles(item)}
      />
    </div>
  );

  const [selectedSentItemFromParent, setSelectedSentItemFromParent] = useState(null);

  const handleChangeData = (item) => {
    setSelectedSentItemFromParent(item?.notificationSubject);
  }

  const [selectedPeriod, setSelectedPeriod] = useState('5');

  const handleChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const Options = [
    { value: '5', label: 'Any Time' },
    { value: '1', label: 'Older than a Week' },
    { value: '2', label: 'Older than a Month' },
    { value: '4', label: 'Older than 6 Months' },
    { value: '3', label: 'Older than a Year' },
  ];

  //#region Context Menu Items
  const MenuItemsTop = [
    {
      id: 1,
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: (item) => {
        setNotificationToDelete(item);
        setOpenDialog(true);
      },
    }
  ];

  const handleDelete = async () => {
    const data = await deleteSentNotifications(notificationToDelete.notificationDetailsID, secureLocalStorage.getItem('userId'), 'Sent')
    if (data.responseCode === 200) {
      setOpenDialog(false);
      getSentItems();
      props.getNotificationsCount()
      toast.success(InvestorCommunicarionToastMessages.NOTIFICATION_DELETED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
    else if (data.responseCode === 404) {
      setOpenDialog(false);
      getSentItems();
      props.getNotificationsCount()
      toast.success(InvestorCommunicarionToastMessages.NOTIFICATION_DELETED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
    else {
      toast.warning(InvestorCommunicarionToastMessages.ACTION_FAILED, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }

  };

  const handleApplyFilter = ({ filterType, startDate, endDate }) => {
    let filtered = [];

    // Convert startDate and endDate to Date objects if they are strings
    if (filterType === 'Custom Range' && startDate && endDate) {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      // Ensure that the end date is inclusive by setting it to the end of the day
      endDate.setHours(23, 59, 59, 999);
    }

    if (filterType === 'Today') {
      filtered = sentNotifications.filter(item => new Date(item.updatedDate).toDateString() === new Date().toDateString());
    } else if (filterType === 'Last 7 Days') {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      filtered = sentNotifications.filter(item => new Date(item.updatedDate) >= sevenDaysAgo);
    } else if (filterType === 'This Month') {
      const now = new Date();
      filtered = sentNotifications.filter(item => {
        const updatedDate = new Date(item.updatedDate);
        return updatedDate.getMonth() === now.getMonth() && updatedDate.getFullYear() === now.getFullYear();
      });
    } else if (filterType === 'Custom Range' && startDate && endDate) {
      filtered = sentNotifications.filter(item => {
        const updatedDate = new Date(item.updatedDate);
        return updatedDate >= startDate && updatedDate <= endDate;
      });
    }

    setFilteredNotifications(filtered);
    setPage(0); // Reset to first page on filter
  };

  const handleResetFilter = () => {
    setFilteredNotifications(sentNotifications);
    setPage(0);
  };

  //#region timestamp function ( eg. 1 hour ago , 2hrs ago)
  // const createdTimestamp = (time) => {
  //   const updatedDate = new Date(time);
  //   const now = new Date();

  //   // Calculate the difference in milliseconds
  //   const diffMs = now - updatedDate;

  //   // Convert milliseconds to hours and days
  //   const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  //   const diffDays = Math.floor(diffHours / 24);

  //   if (diffDays > 0) {
  //     return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  //   } else {
  //     return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
  //   }
  // } ##TODO : need to find better solution for time conversion

  return (
    <div className='notifications-accordian-wrapper'>
      <Box>
        <CustomNotificationFilters
          onApplyFilter={handleApplyFilter}
          onResetFilter={handleResetFilter}
        />

      </Box>
      {sentNotifications && investorsList ? (
        filteredNotifications?.length === 0 ? (
          'No Data Available'
        ) : (
          filteredNotifications?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
            <div key={item.notificationDetailsID} className='space-between'>
              <div className='groupview-accordian'>
                <Accordian
                  title={item?.notificationSubject}
                  body={renderBody(item)}
                  showtimestamp={true}
                  timestamp={(item?.updatedDate)}
                  onChange={() => handleChangeData(item)}
                />
              </div>
              <div className='margin-top-9'>
                <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={item} />
              </div>
            </div>
          ))
        )
      ) : (
        <div>
          <LinearProgress />
        </div>
      )}

      <div className='margin-top-10'>
        <TablePagination
          component="div"
          count={filteredNotifications.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>

          <h6> <InfoIcon className='info-i' /> Confirmation</h6>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this sent notification?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='btn-primary2' variant='outlined' onClick={() => setOpenDialog(false)}> <CancelIcon /> Cancel</Button>
          <Button className='btn-primary' variant='contained' onClick={handleDelete} autoFocus>
            <DeleteIcon /> Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SentNotifications;
